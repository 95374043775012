import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LocationIcon } from "@assets/icons/Location.svg";
import { ReactComponent as ObstacleIcon } from "@assets/icons/Obstacles.svg";
import { ReactComponent as ResourceIcon } from "@assets/icons/Resources.svg";
import { ReactComponent as SupportiveIcon } from "@assets/icons/Supportive.svg";
import { ReactComponent as TriggerIcon } from "@assets/icons/Trigger.svg";

import { globalTextColors } from "@constants/colors";

import { Box, Stack, Typography } from "@mui/material";

const BehaviorText = ({
  data,
}: {
  data: {
    location: string;
    resources: string;
    trigger: string;
    strategies: string;
    supportive: string;
  };
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        borderRadius: "10px",
        padding: "20px",
        boxSizing: "border-box",
        background: "white",
      }}
    >
      <TextSection data={data} />
    </Box>
  );
};

const TextSection = ({
  data,
}: {
  data: {
    location: string;
    resources: string;
    trigger: string;
    strategies: string;
    supportive: string;
  };
}) => {
  const { t } = useTranslation();

  const sections: {
    key: "location" | "resources" | "trigger" | "strategies" | "supportive";
    headline: string;
    icon: React.ReactNode;
  }[] = [
    {
      key: "location",
      headline: "Location",
      icon: <LocationIcon style={{ width: "41px", height: "28px" }} />,
    },
    {
      key: "resources",
      headline: "Resources",
      icon: <ResourceIcon style={{ width: "41px", height: "28px" }} />,
    },
    {
      key: "trigger",
      headline: "Behavior Trigger",
      icon: <TriggerIcon style={{ width: "41px", height: "28px" }} />,
    },
    {
      key: "strategies",
      headline: "Obstacles",
      icon: <ObstacleIcon style={{ width: "41px", height: "28px" }} />,
    },
    {
      key: "supportive",
      headline: "Supportive People",
      icon: <SupportiveIcon style={{ width: "41px", height: "28px" }} />,
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {sections.map(({ key, headline, icon }, i) => (
        <Stack key={i} flexDirection="row" alignItems="center">
          {icon}
          <Box sx={{ marginLeft: "1rem" }}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                color: globalTextColors.PRIMARY,
              }}
            >
              {t(headline)}
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                marginTop: "-5px",
                color: globalTextColors.PRIMARY,
              }}
            >
              {t(data[key])}
            </Typography>
          </Box>
        </Stack>
      ))}
    </Box>
  );
};

export default BehaviorText;
