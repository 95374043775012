import React from "react";

import { ReactComponent as ZeedsUserManualTreeIcon } from "@assets/icons/ZeedsUserManualTree.svg";
import sessionManual from "@assets/pdfs/Bilaga 1.pdf";
import sessionManual2 from "@assets/pdfs/Bilaga 2.pdf";
import sessionManual3 from "@assets/pdfs/Bilaga 3.pdf";
import sessionManual4 from "@assets/pdfs/Bilaga 4.pdf";

import PdfFormsModal from "@components/PdfFormsModal";

import { Box, Typography } from "@mui/material";

export function Introduction() {
  return (
    <>
      <h2>Zeeds Användarmanual</h2>
      <Box
        sx={{
          display: "flex",
          columnGap: "29px",
        }}
      >
        <Box>
          <ZeedsUserManualTreeIcon />
        </Box>
        <Box>
          <Typography sx={{ margin: "0" }}>Zeeds App AB</Typography>
          <Typography sx={{ margin: "0" }}>Skriven - December 2023</Typography>
          <Typography sx={{ margin: "0" }}>Upplaga - 1.3</Typography>
        </Box>
      </Box>
      <p>
        Denna manual definierar din administration. Använd menyn i det övre
        vänstra hörnet för att snabbt komma åt ett specifikt kapitel, eller
        klicka på pilarna för att gå igenom varje sida..{" "}
      </p>
      <br />
      <p
        style={{
          border: "3px solid #C25435",
          borderRadius: "5px",
          padding: "1rem",
          width: "60%",
          marginBottom: "5rem",
        }}
      >
        Du har tillgång till den här manualen i din portal om du vill läsa den
        igen.
      </p>
    </>
  );
}

export function IntendedUse() {
  return (
    <>
      <h2>Avsedd Användning</h2>
      <p>
        Zeeds är avsedd att användas för att öka effektiviteten av psykologisk
        behandling utifrån Focused Acceptance and Commitment Therapy (FACT)
        (Strosahl et al., 2012) utförd av en licensierad vårdgivare. Lösningen
        består av en behandlarportal som har två funktioner:
      </p>
      <br />
      <ul style={{ paddingLeft: "1rem" }}>
        <li>
          Att administrera en användarvänlig patientapplikation, som används av
          patienten för att registrera och kommunicera aktivitet mellan
          sessionerna.
        </li>
        <br />
        <li>
          Att aggregera och visualisera patientaktivitet mellan sessionerna.
        </li>
      </ul>
      <br />
      <p>
        Zeeds ska endast användas i samband med behandlardriven psykoterapi, och
        i enlighet med denna användarmanual. Data som visas inom Zeeds ska inte
        likställas med data som kan användas på egen hand för att utföra
        behandling, och måste alltid följas upp av en licensierad vårdgivare
        innan något beslut fattas angående patientens behandling. <br />
        Denna manual kräver att användaren är PTP-psykolog eller legitimerad
        psykolog med klinisk erfarenhet av Acceptance and Commitment Therapy
        (ACT).
      </p>
    </>
  );
}

export function PatientProfile() {
  return (
    <>
      <h2>Patientprofil</h2>
      <p>
        Zeeds är ett transdiagnostiskt verktyg som huvudsakligen används för
        patienter med mildriga till måttliga depressions- eller ångestbesvär i
        samband med en FACT-behandling. Zeeds kan även användas för subkliniska
        med ångest- eller depressionssymtom.
      </p>
      <br />
      <p>
        Evidens för effekten av Acceptance and Commitment Therapy (ACT) som en
        transdiagnostisk behandling, finns för många DSM-5 diagnoser (American
        Psychiatric Association, 2013). Det finns evidens för liten till
        medelstor effekt på depressiva syndrom, och medelstora till stora
        effekter på ångestsyndrom (Ferreira et al.,2022). Det finns även
        preliminära fynd på liten till medelstor effekt för substansbrukssyndrom
        enligt DSM-5 (Lee et al.,2015). Forskningen utvecklas ständigt och bör
        bedömas kritiskt och självständigt.
      </p>
      <br />
      <p>
        För FACT som en transdiagnostisk behandling finns bland annat evidens
        för effekt på generaliserat ångestsyndrom och depression (Ruiz et
        al.,2020), måttliga ångestsyndrom (Ruiz et al.,2018), samt för
        transdiagnostiska gruppbehandlingar på primärvårdsnivå (Levin et
        al.,2012). Komponentanalyser på ACT-moduler i samband med andra
        behandlingar eller själva, har visat på en medelstor effekt (Levin et
        al.,2012).
      </p>
      <br />
      <p>Zeeds ska inte användas i samband med psykodynamisk terapi.</p>
      <br />
      <p>Zeeds ska inte användas för patienter under 16 år.</p>
      <br />
      <p>
        Zeeds ska inte användas för patienter med låg verbal intelligens. Detta
        då applikationen kräver normal läs-och skrivförmåga för att
        tillhandahålla materialet.
      </p>
      <br />
      <p>
        Vårdgivaren bör genomföra en nödvändig fallkonceptualisering samt
        utesluta fysiologiska diagnoser, för att kunna besluta vilka patienter
        som hade haft nytta av behandlingen . Beslut för administration bör
        ultimat tas i beaktande med klinisk erfarenhet och evidens.
      </p>
    </>
  );
}

export function TreatmentPrinciples() {
  return (
    <>
      <h2>Behandlingsprinciper</h2>
      <p>
        Denna manual utgår från ACT och i synnerhet FACT. Manualen är skriven i
        syfte att få en redan tränad kliniker att administrera Zeeds i linje med
        FACT-behandling. För mer djuplodande böcker om behandlingsformen
        rekommenderas Brief Behavioral Interventions for Radical Change
        (Strosahl et al.,2012), ACT Made Simple (Harris, 2019) och Acceptance
        and commitment therapy: The process and practice of mindful change, 2nd
        ed (Harris et al.,2012).
      </p>
      <br />
      <p>
        Nedan följer en kort sammanfattning om de behandlingsprinciper som
        underligger en FACT-behandling.
      </p>

      <ClinicalBasis />
      <Symptoms />
      <ChangeProcess />
      <Intervention />
      <Alliance />
      <TherapyLength />
    </>
  );
}

export function ClinicalBasis() {
  return (
    <>
      <h2>Klinisk utgångspunkt</h2>
      <p>
        Behandlingen utgår från Relational Frame Theory (Hayes, 2004). En
        grundkomponent i behandlingen är att mänsklig inlärning är symboliskt
        organiserad genom enkla relationella ramar (om-då, Mig-jag..), såväl som
        kontingensstyrd inlärning. Symboliskt organiserad inlärning ger
        möjlighet att utveckla inlärda mönster som enbart är symboliskt
        uttryckta i språkliga regler, utan att ha upplevt som direkt
        kontingensstyrd inlärning. Detta kan ge upphov till rigida
        copingmekanismer som inte är flexibla nog för att funktionellt svara på
        omgivningens stressorer. Alla beteenden är i grunden funktionella
        utifrån de kontexter där de inlärdes. FACT-behandlarens centrala roll är
        att analysera i vilken grad patientens nuvarande copingmekanismer är
        funktionella för att få patienten att leva det liv som eftersträvas.
      </p>
    </>
  );
}

export function PatientApplication() {
  return (
    <>
      <h2>Zeeds Patient Application</h2>
      <p>
        Zeeds patient application enables activity scheduling and activity
        monitoring (Kanter et al,.2012) for the patient to conduct between
        sessions, as well as the Acceptance and Commitment Therapy exercises
        Life Compass and The Birthday Party (Harris,.2019) and the Patient
        Health Questionnaire 2 (Kroenke et al.,2003). The patient input is
        guided by socratic methodology (Carey et al,.2014) as text, and
        illustrations are optimised for a diverse patient profile. The user flow
        of the application has been developed in relation to modern user
        experience-optimized principles (Grant, 2018).
      </p>
      <p>
        The primary section of the application contains the modules relating to
        values. The secondary section of the application contains the modules
        relating to activity scheduling and activity monitoring. Patients can
        enable notification to be sent out as reminders.
      </p>
    </>
  );
}

export function Symptoms() {
  return (
    <>
      <h2>Symtom</h2>
      <p>
        Symtom kan ses som ett resultat av en diskrepans mellan de stressorer
        patienten upplever, och funktionaliteten av patientens nuvarande
        copingmekanismer. Målet med behandlingen är att uppdatera
        copingmekanismerna, snarare än att minska symtomen.
      </p>
    </>
  );
}

export function ChangeProcess() {
  return (
    <>
      <h2>Förändringprocess</h2>
      <p>
        Klinisk förändring uppnås när patienten ökar sin psykologiska
        flexibilitet. Den psykologiska flexibiliteten ökar när patienten erfar
        att nuvarande strategier för att kontrollera emotionell smärta må vara
        logiska utifrån sin ursprungliga kontext, men är kontraproduktiva i sin
        nuvarande kontext. Detta märks tydligast när patienter undviker
        värderade aktiviteter, för att kontrollera sina symptom. Detta leder
        till ett ökat fokus på symtomkontroll, beteendemässig rigiditet och
        minskad värderad aktivitet.
      </p>
      <br />
      <p>
        Psykologisk flexibilitet har tre grundpelare. Öppenhet, medvetenhet och
        engagemang. Dessa färdigheter tränas på olika sätt i sessionerna. Det
        finns ett uppslag av sådana övningar i olika behandlingsböcker. Exempel
        på övningar finns i bilagorna till denna manual.
      </p>
      <br />
      <p>
        Behandlaren bör sträva efter att ge patienten den fundamentala insikten
        att emotionskontrollerande strategier som excessivt begränsar tidigare
        värderade aktiviteter bidrar till problematiken, snarare än att lindra
        densamma.
      </p>
    </>
  );
}

export function Intervention() {
  return (
    <>
      <h2>Interventioner</h2>
      <p>
        Patienter kan enbart omvärdera sina copingmekanismer och dess funktion
        genom direkt erfarenhet. Därmed ska alla sessioner ha som utgångspunkt
        att implementera konkreta och observerbara beteenden i naturalistisk
        miljö efter sessionens slut, inför nästa session.
      </p>
      <br />
      <p>
        Regelbaserade copingmekanismer, om än logiska på verbal nivå, bör
        utmanas experimentellt med hjälp med beteenden i patientens miljö. Målet
        är att patient ska få kontingensstyrd information om sina
        copingmekanismer.
      </p>
      <br />
      <p>
        En central komponent i behandlingen är att patienten kontinuerligt
        inriktar sig på att experimentera flexibelt med beteenden som är i linje
        med patientens värderingar. Beteendena vara positivt formulerade snarare
        än formulerade för att undvika symtom. Värderingar formuleras enklast
        som de former av kontinuerliga beteendemönster som patienten hade velat
        demonstrera i sitt liv om problemen inte fanns.
      </p>
      <br />
      <p>
        Behandlaren hjälper patienten med att testa nya beteenden, eller
        assimilera naturlig feedback på existerande beteenden.
      </p>
    </>
  );
}

export function Alliance() {
  return (
    <>
      <h2>Allians</h2>
      <p>
        Skapa en allians fokuserat på kollaborativt explorerande av olika
        handlingsmönster. Försök tala i positiv valens, för att öka patientens
        tankar om att beteendeförändring är möjlig.
      </p>
    </>
  );
}

export function TherapyLength() {
  return (
    <>
      <h2>Behandlingslängd</h2>
      <p>
        Det finns inget satt kriterium för antal sessioner i behandling, men det
        huvudsakliga målet med behandlingen är att få patienten att uppleva den
        fundamentala insikter under Förändringprocessen, och uppleva
        beteendemässig feedback på validiteten av nya strategier.
      </p>
      <br />
      <p>
        Utgångspunkten bör vara att göra detta på en session, men om patienten
        upplevs vara för psykologiskt rigid, bör flera sessioner planeras in.
      </p>
      <br />
      <p>
        Ofta kan denna process utföras mellan 1-4 sessioner. Det är möjligt att
        avsluta behandlingen efter enbart en session. När behandlaren erfar att
        patienten är självgående i förändringsprocessen kan behandlingen
        avslutas.
      </p>
      <br />
      <p>
        Det finns ingen specifikation över sessionslängd eller inom vilket
        tidsintervall patient och psykolog bör ses. Behandlaren lägger upp den
        struktur som passar bäst för den specifika patienten.
      </p>
    </>
  );
}

// Technical requirements

export function MinimalSoftwareRequirements() {
  return (
    <>
      <h2>Minimala Mjukvarukrav</h2>
    </>
  );
}

export function ZeedsPatientApplication() {
  return (
    <>
      <h2>Zeeds Patientapplikation</h2>
      <p>
        Zeeds patientapplikation möjliggör aktivitetsschemaläggning och
        aktivitetsplanering (Kanter et al,.2012) för patienten att genomföra
        mellan sessioner, såväl som Acceptance and Commitment Therapy-övningarna
        Life Compass och The Birthday Party (Harris,.2019). Patienter svarar
        även veckovis på en skala från 0-10 som mäter allvarlighetsgrad av
        symtom, baserat på FACT-principer (Strosahl et al.,2012). Frågorna i
        applikationen formuleras efter sokratisk metod, (Carey et al,.2014) och
        texter och illustrationer är anpassade för en mångsidig patientprofil.
        Användarflödet i applikationen har utvecklats i relation till moderna UX
        principer (Grant, 2018).
      </p>
      <br />
      <p>
        Den första delen av applikationen innehåller moduler som fokuserar på
        värderingar. Den andra delen av applikationen innehåller moduler som rör
        aktivitetsschemaläggning och aktivitetsplanering. Patienter kan
        möjliggöra att skicka ut påminnelser för specifika beteenden via
        notiser.
      </p>
    </>
  );
}

export function ZeedsPortal() {
  return (
    <>
      <h2>Zeeds Portal</h2>
      <ol>
        <li>
          Portalen innehåller dina patienters profiler. Varje profil innehåller
          sammanfattad information om patientens aktivitet.
        </li>
        <li>
          Du skapar en patientprofil genom att ange namn och e-postadress samt
          svenskt personnummer på aktuell patient. Detta leder till utskick av
          ett e-postmeddelande till patienten, med information om att ladda ner
          Zeeds via Google Play eller App Store. Patienter laddar ner appen och
          verifierar sig via Bank ID för att få tillgång till applikationen.
        </li>
        <li>Om du klickar på en patientprofil ser du dess tillhörande data.</li>
        <li>
          Genom att klicka på en profil öppnas detaljerad live-data i de
          relevanta områdena.
        </li>
        <li>
          På informationssidan i Portalen hittar du svar på vanliga frågor, samt
          kontaktinformation.
        </li>
      </ol>
    </>
  );
}

// Administration

export function FirstSession() {
  return (
    <>
      <h2>Nyckelelement:</h2>
      <ul>
        <li>
          <p>Sökorsak</p>
        </li>
        <li>
          <p>Nuvarande copingstrategier</p>
        </li>
        <li>
          <p>Funktion av copingstrategier</p>
        </li>
        <li>
          <p>Kostnad av copingstrategier</p>
        </li>
        <li>
          <p>Värderingar och nästa aktion</p>
        </li>
      </ul>
      <Goal />
      <SeekingHelpReason />
      <CurrentCopingStrategies />
      <CopingStrategyReasoning />
      <CostOfCopingStrategies />
      <ValuesAndNextAction />
      <Zeeds />
    </>
  );
}

export function Goal() {
  return (
    <>
      <h2>Syfte</h2>
      <p>
        Den första sessionen bör ses som aktivt behandlande. Syftet med den
        första sessionen är att uppnå en villighet hos patienten att ändra sina
        copingstrategier. Genom fem strukturerade moment, kan behandlaren nå
        denna punkt redan under den första sessionen, men det kan också ta
        längre tid om patienten identifierar sig starkt med sina nuvarande
        emotionskontrollerande strategier.
      </p>
      <br />
      <p>
        En metod för att konceptualisera psykologisk flexibilitet hos klient är
        att dela in det i tre komponenter, och att därefter skatta patientens
        förmåga från 0-10 inom vardera av dessa tre axlar. Se bilaga 1 för en
        sådan skattning.
      </p>
    </>
  );
}

export function SeekingHelpReason() {
  return (
    <>
      <h2>Sökorsak</h2>
      <p>
        Den första att utreda är vad patienten söker hjälp för. Detta bör
        formuleras i syfte att förstå hur framgång skulle se ut för klienten, i
        terapi. Detta är ingen plats att ingripa på, utan fungerar för att
        vägleda syftet med interventionen. För att få ett svar kan man fråga:
      </p>
      <br />
      <p>{`"Om det här fungerade, hur skulle framgång se ut för dig?"`}</p>
      <br />
      <p>
        Utred även hur obehagen uttrycker sig, i form av symtom. Ett snabbt sätt
        att rikta in fokusområden är att fråga hur patient upplever att ens liv
        ter sig från 0-10 inom:
      </p>
      <b>Arbete-utbildning</b>
      <p>1-10</p>
      <b>Vänner</b>
      <p>1-10</p>
      <b>Kärlek</b>
      <p>1-10</p>
      <b>Familj</b>
      <p>1-10</p>
      <b>Fritidsaktiviteter</b>
      <p>1-10</p>
      <p>Få även en överblick över livsvanor inom följande teman:</p>
      <ul>
        <li>
          <p>Träning</p>
        </li>
        <li>
          <p>Rökning</p>
        </li>
        <li>
          <p>Sömn</p>
        </li>
        <li>
          <p>Droger</p>
        </li>
        <li>
          <p>Alkohol</p>
        </li>
        <li>
          <p>Spel</p>
        </li>
        <li>
          <p>Våld</p>
        </li>
        <li>
          <p>Engagemang i gruppaktiviteter</p>
        </li>
        <li>
          <p>Andlighet</p>
        </li>
      </ul>
      <p>
        Fysiologiska orsaker bör uteslutas. Efter denna genomgång bör det finnas
        en övergripande förståelse över patientens livssituation.
      </p>
    </>
  );
}

export function CurrentCopingStrategies() {
  return (
    <>
      <h2>Nuvarande copingstrategier</h2>
      <p>
        Därefter undersöks vad patienten nu gör för att lindra de obehag som
        upplevs. Syftet är att fokusera på de copingstrategier som klienten
        presenterar och de smärtor som strategierna dämpar kortsiktigt. Fokus
        bör vara att stanna vid denna punkt tills det finns en tillräckligt bra
        känsla för de återkommande copingstrategierna i klientens liv.
      </p>
      <br />
      <p>
        Var uppmärksam på icke-verbala copingstrategier som stammande,
        frustration eller undvikande av ögonkontakt vid anslutning till tal om
        själva strategierna. Var även uppmärksam på verbala uttryck i form av
        regler såsom “Om X sker måste jag bete mig enligt Y”.
      </p>
    </>
  );
}

export function CopingStrategyReasoning() {
  return (
    <>
      <h2>Funktionen av copingstrategier</h2>
      <p>
        Efter att ha utvärderat de strategier som används för att kontrollera
        emotionell smärta, led diskussionen mot hur copingstrategierna har
        fungerat för att begränsa besvären. Det kan enklast göras genom att be
        patient repetera de resultat som har uppnåtts med hjälp av
        copingstrategierna. Visa empati och förståelse för att patienten arbetar
        hårt för att hantera sina problem på ett sätt som patienten anser
        funktionellt utifrån kontexten hen befinner sig i.
      </p>
      <br />
      <p>
        En fråga som kan trigga ifrågasättande av funktionen själv vid slutet av
        denna fas, är:
      </p>
      <br />
      <i>
        “Kan det vara så att strategierna din hjärna föreslår för att
        kontrollera dina problem, i själva verket bidrar till dina problem?”
      </i>
      <br />
      <p>
        Om patienten håller med, kan du gå vidare. Om patienten inte håller med,
        var tålmodig och utforska funktionen av patientens nuvarande
        copingstrategier utan att tvinga terapin till nästa del.
      </p>
    </>
  );
}

export function CostOfCopingStrategies() {
  return (
    <>
      <h2>Funktionen av copingstrategier</h2>
      <p>
        Undersök de undvikande beteenden som smärtan har orsakat inom
        livsområden som patienten finner viktigt. Knyt an till diskussionerna
        under Sökorsak. Identifiera konsekvenserna av att klientens liv krymper
        då värderade aktiviteter undviks, det vill säga konsekvensen av att leva
        i enlighet med det regelstyrda systemet.
      </p>
      <br />
      <p>Var empatisk och erkänn den kamp de utsätter sig för.</p>
    </>
  );
}

export function ValuesAndNextAction() {
  return (
    <>
      <h2>Värderingar och nästa aktion</h2>
      <p>
        Frågan att nu undersöka är att be patienten att föreställa sig ett
        scenario där de nuvarande barriärerna inte existerar, och att gräva
        djupare i hur livet skulle yttra sig i form av beteenden. Detta kan
        främjas genom att ställa detaljerade frågor om klientens livsområden, om
        de såg ut som klienten skulle vilja att de skulle vara. Poängen att
        komma fram till är att detta sätt att leva är möjligt, men att det inte
        kan samexistera med emotionellt undvikande.
      </p>
      <br />
      <p>
        En övning för att förbereda för nästa steg är att visualisera klientens
        liv som en linje med kontrollerande strategier på ena sidan och ett
        meningsfullt liv på den andra.
      </p>
      <br />
      <p>Instruktioner:</p>
      <ul>
        <li>Rita en pil var du är på linjen och åt vilket håll du rör dig.</li>
        <li>
          Vilka, om några, är kostnaderna och fördelarna med att bedriva
          kontroll?
        </li>
        <li>
          Vilka beteenden skulle säga dig att du går mot mer mening i livet?
        </li>
        <li>
          När du fastnar, hur kan du hjälpa dig själv att fortsätta gå mot mer
          mening?
        </li>
      </ul>
      <br />
      <p>
        Behandlaren kan här hjälpa till att praktiskt konstruera hur man kan
        komma åt andra hållet, med konkreta beteenden.
      </p>
    </>
  );
}

export function Zeeds() {
  return (
    <>
      <h2>Zeeds</h2>
      <p>
        Om behandlingen når denna fas bör sessionen avslutas. Förklara att
        patienten kommer ges en app för att hålla koll på sina framsteg mot det
        liv som denne vill leva. Applikationen kommer att hjälpa till att
        klargöra patientens värderingar, och även hjälpa patienten att planera
        och registrera beteenden i linje med värderingar. Förklara att du som
        behandlare kommer att se denna aktivitet, och att det kommer hjälpa dig
        att kollaborativt hjälpa patienten i framtida sessioner.
      </p>
      <br />
      <p>
        Lägg emfas på experimentellt testande av beteenden i första hand, och
        att ni kan utvärdera funktionen över sessioner. Det finns inga felaktiga
        beteenden i denna fas.
      </p>
      <br />
      <p>
        Värderingsdelen av Zeeds kan ta några sittningar att gå igenom.
        Rekommendera att lägga ungefär 20 minuter per livsområde, och sprid ut
        det över dagar om det behövs. Identifieringen av beteenden tar ungefär
        5-10 minuter per beteende. Registreringen sker på några sekunder, och
        instruktioner finns i appen.
      </p>
      <br />
      <b>Uppgift:</b>
      <ul>
        <li>
          Identifiera värderingar i Zeeds och börja testa 1 eller 2 beteenden.
        </li>
        <li>
          Om patienten <b>ej</b> är redo att överge copingstrategier -
          färdighetsträning i öppenhet eller acceptans.{" "}
        </li>
      </ul>
    </>
  );
}

export function BeforeSession() {
  return (
    <>
      <h2>Inför sessionen</h2>
      <p>
        Se patientens aktivitet sedan sist i patientprofilen. Fokusera på de
        livsområden som patienten är mest samt minst aktiv vid, och se över
        vilka beteenden som har utförts, samt hur dessa beskrivs. Förbered
        diskussioner kring hjälp med den praktiska implementeringen av
        beteendena i framtiden, samt hur dessa beteenden har påverkat patientens
        värderade riktning. Se även över patientens upplevda allvarlighetsgrad
        av symptom sedan sist, samt den totala aktiveringen.
      </p>
      <PyschologicalFlexibility />
      <Continuation />
      <Task />
    </>
  );
}

export function PyschologicalFlexibility() {
  return (
    <>
      <h2>Psykologisk flexibilitet</h2>
      <p>
        I sessionen kan interventioner sättas in för att öka färdigheter inom de
        tre underkategorierna öppenhet, acceptans och värderad riktning.
        Fokusområdet utgår ifrån bedömningen av vilket område som begränsar
        patienten mest.
      </p>
      <br />
      <p>
        Om patienten har svårigheter med att observera sina tankar, kan
        sessionen fokusera på en interaktiv övning i medveten närvaro. Se bilaga
        2 för exempel på en ACT-baserad mindfullness-övning.
      </p>
      <br />
      <p>
        Om patienten har svårighet att närma sig smärtsamma ämnen och tankar
        utan att förtrycka dessa, kan sessionen fokuseras på en interaktiv
        övning inom öppenhet. Se bilaga 3 för exempel på en ACT-baserad
        öppenhets-övning.
      </p>
      <br />
      <p>
        Om patient har svårigheter med var de vill inrikta sin energi, kan
        övningar i värderad riktning vara lämpliga. Se bilaga 4 för exempel på
        ACT-baserade övningar i värderad riktning.
      </p>
      <br />
      <p>
        Oavsett fokusområde är strukturen fortsatt att hjälpa patienten att
        flexibelt testa nya copingstrategier experimentellt, vid slutet av
        sessionen. Det kan göras genom att planera nya beteenden, eller
        modifiera existerande sådana.
      </p>
    </>
  );
}

export function Continuation() {
  return (
    <>
      <h2>Fortsättning</h2>
      <p>
        Behandlingen kan avslutas när patienten upplever att hen är konsekvent
        engagerad i för patienten viktiga aktiviteter utan att känna sig
        begränsad av sina symtom.
      </p>
    </>
  );
}

export function Task() {
  return (
    <>
      <h2>Uppgift</h2>
      <ul>
        <li>
          Få i uppgift att testa nya beteenden i Zeeds, eller ändra existerande
          beteenden.
        </li>
        <li>
          Få i uppgift att justera grad av engagemang i livsområden, i Zeeds.
        </li>
        <li>Övrig patientspecifik övning i medveten närvaro eller öppenhet.</li>
      </ul>
    </>
  );
}

export function ReferenceList() {
  return (
    <>
      {" "}
      <h2>Referenser</h2>
      <ol>
        <li>
          American Psychiatric Association. (2013). Diagnostic and statistical
          manual of mental disorders (5th ed.).
          https://doi.org/10.1176/appi.books.9780890425596
        </li>

        <li>
          Strosahl, Kirk, 1950-; Robinson, Patricia A. (Patricia Ann), 1947-;
          Gustavsson, Thomas, Oakland, CA : New Harbinger Publications; c2012
        </li>

        <li>
          Ferreira MG, Mariano LI, Rezende JV, Caramelli P, Kishita N. Effects
          of group Acceptance and Commitment Therapy (ACT) on anxiety and
          depressive symptoms in adults: A meta-analysis. J Affect Disord. 2022
          Jul 15;309:297-308. doi: 10.1016/j.jad.2022.04.134. Epub 2022 Apr 27.
          PMID: 35489560.
        </li>
        <li>
          Ruiz FJ, Peña-Vargas A, Ramírez ES, Suárez-Falcón JC, García-Martín
          MB, García-Beltrán DM, Henao ÁM, Monroy-Cifuentes A, Sánchez PD.
          Efficacy of a two-session repetitive negative thinking-focused
          acceptance and commitment therapy (ACT) protocol for depression and
          generalized anxiety disorder: A randomized waitlist control trial.
          Psychotherapy (Chic). 2020 Sep;57(3):444-456. doi: 10.1037/pst0000273.
          Epub 2020 Jan 16. PMID: 31944806.
        </li>

        <li>
          Ruiz, Francisco J. ; Flórez, Cindy L. ; García-Martín, María B. ;
          Monroy-Cifuentes, Andrea ; Barreto-Montero, Katherine ;
          García-Beltrán, Diana M. ; Riaño-Hernández, Diana ; Sierra, Marco A. ;
          Suárez-Falcón, Juan C. ; Cardona-Betancourt, Verónica ; Gil-Luciano,
          Bárbara
        </li>

        <li>Journal of contextual behavioral science, 2018, Vol.9, p.1-14</li>

        <li>
          Levin ME, Hildebrandt MJ, Lillis J, Hayes SC. The impact of treatment
          components suggested by the psychological flexibility model: a
          meta-analysis of laboratory-based component studies. Behav Ther. 2012
          Dec;43(4):741-56. doi: 10.1016/j.beth.2012.05.003. Epub 2012 May 14.
          PMID: 23046777.
        </li>

        <li>
          Harris, R. (2019). ACT made simple: an easy-to-read primer on
          acceptance and commitment therapy (2nd ed.). New Harbinger
          Publications.
        </li>

        <li>
          Hayes, S. C., Strosahl, K. D., & Wilson, K. G. (2012). Acceptance and
          commitment therapy: The process and practice of mindful change (2nd
          ed.). Guilford Press.
        </li>

        <li>
          Steven C. Hayes,Acceptance and commitment therapy, relational frame
          theory, and the third wave of behavioral and cognitive
          therapies,Behavior Therapy,Volume 35, Issue 4,2004, Page639-665,ISSN
          0005-7894, https://doi.org/10.1016/S0005-7894(04)80013-3.
        </li>
      </ol>
    </>
  );
}

export function Appendixes() {
  return (
    <>
      <h2>Bilagor</h2>
      <ol>
        <li>Bilaga 1. Skattning av psykologisk flexibilitet.</li>
        <li>Bilaga 2. Övningar i mindfulness.</li>
        <li>Bilaga 3. Övningar i acceptans.</li>
        <li>Bilaga 4. Skattningar i värderad riktning.</li>
      </ol>

      <div className="flex flex-row gap-8">
        <PdfFormsModal pdfPath={sessionManual} title="Bilaga 1" />
        <PdfFormsModal pdfPath={sessionManual2} title="Bilaga 2" />
        <PdfFormsModal pdfPath={sessionManual3} title="Bilaga 3" />
        <PdfFormsModal pdfPath={sessionManual4} title="Bilaga 4" />
      </div>
    </>
  );
}
