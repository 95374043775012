import React from "react";

// Simple landing page for the Firebase user actions.
function AuthUserActions() {
  return (
    <div>
      <h3>Loading...</h3>
    </div>
  );
}
export default AuthUserActions;
