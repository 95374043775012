import app from "../firebaseSetup";
import { deleteUser, getAuth, updateEmail } from "firebase/auth";
// eslint-disable-next-line no-unused-vars
import { reauthenticateWithCredential, updatePassword } from "firebase/auth";
import React, { useState } from "react";

import DataUpdateModal from "@components/DataUpdateModal";
import DeleteAccountModal from "@components/DeleteAccountModal";
import PasswordUpdateModal from "@components/PasswordUpdateModal";

import { Typography } from "@mui/material";

const LnkButton = ({
  children,
  onClick,
  style,
}: {
  children: React.ReactNode;
  onClick: () => void;
  style?: React.CSSProperties;
}) => (
  <div
    onClick={onClick}
    className="inline-flex items-center px-8 py-2 border-2 border-primary rounded-md text-primary font-semibold text-base transition duration-300 ease-in-out hover:bg-gray-300"
    style={{
      border: "3px solid #42446A",
      cursor: "pointer",
      width: "30%",
      height: "3%",
    }}
  >
    {children}
  </div>
);
const SettingsScreen: React.FC = () => {
  const auth = getAuth(app);
  const [showDataUpdateForm, setShowDataUpdateForm] = useState(false);
  const [showPasswordUpdateForm, setShowPasswordUpdateForm] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword] = useState("");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleChangePassword = async () => {
    const user = auth.currentUser;

    if (!user) {
      alert("Användaren är inte autentiserad.");
      return;
    }
    if (currentPassword === newPassword) {
      alert("Det nya lösenordet kan inte vara detsamma som det gamla.");
      return;
    }

    if (newPassword.length < 6) {
      alert("Nytt lösenord måste vara minst 6 tecken långt.");
      return;
    }
    try {
      await updatePassword(user, newPassword);
      alert("Ditt lösenord har uppdaterats framgångsrikt.");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      setShowPasswordUpdateForm(false);
    } catch (error) {
      alert(
        "Fel vid uppdatering av lösenord. Kontrollera konsolen för detaljer.",
      );
    }
  };
  const handleDataUpdate = () => {
    const user = auth.currentUser;
    if (user) {
      setCurrentEmail(user.email || "");
    }
    setShowDataUpdateForm(true);
    setShowPasswordUpdateForm(false);
  };
  const handlePasswordUpdate = () => {
    setShowDataUpdateForm(false);
    setShowPasswordUpdateForm(true);
  };

  const handleDataUpdateSubmit = async () => {
    const user = auth.currentUser;
    if (newEmail === currentEmail) {
      alert("Nya och gamla e-post-ID är samma.");
    } else if (user) {
      updateEmail(auth.currentUser, newEmail)
        .then(() => {
          alert("Din e-post har uppdaterats framgångsrikt.");
          setNewEmail("");
          setShowDataUpdateForm(false);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const onDeleteAccount = () => {
    setShowDataUpdateForm(false);
    setShowPasswordUpdateForm(false);
    setShowDeleteAccountModal(true);
  };

  const handleConfirmDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await deleteUser(user);

        console.log("Kontot har raderats.");
      }
    } catch (error) {
      console.error("Fel vid borttagning av konto:", error);
    }

    setShowDeleteAccountModal(false);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom style={{ marginTop: "150px" }}>
        Settings
      </Typography>
      <div style={{ marginBottom: "16px", marginTop: "40px" }}>
        <LnkButton onClick={handleDataUpdate}>Uppdatera uppgifter</LnkButton>
      </div>
      <div style={{ marginBottom: "16px", marginTop: "40px" }}>
        <LnkButton onClick={handlePasswordUpdate}>Uppdatera lösenord</LnkButton>
      </div>
      <div style={{ marginBottom: "16px", marginTop: "40px" }}>
        <LnkButton
          onClick={onDeleteAccount}
          style={{ width: "200px", height: "40px" }}
        >
          Ta bort konto
        </LnkButton>
      </div>
      <DataUpdateModal
        isOpen={showDataUpdateForm}
        onClose={() => setShowDataUpdateForm(false)}
        currentEmail={currentEmail}
        newEmail={newEmail}
        onEmailChange={(e) => setNewEmail(e.target.value)}
        onUpdate={handleDataUpdateSubmit}
      />
      <PasswordUpdateModal
        onCurrentPasswordChange={(e) => setCurrentPassword(e.target.value)}
        onNewPasswordChange={(e) => setNewPassword(e.target.value)}
        confirmNewPassword={confirmNewPassword}
        isOpen={showPasswordUpdateForm}
        onClose={() => setShowPasswordUpdateForm(false)}
        onUpdate={handleChangePassword}
      />
      <DeleteAccountModal
        isOpen={showDeleteAccountModal}
        onClose={() => setShowDeleteAccountModal(false)}
        onConfirm={handleConfirmDeleteAccount}
      />
    </div>
  );
};

export default SettingsScreen;
