import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, TextField, Typography } from "@mui/material";

interface PasswordUpdateModalProps {
  onCurrentPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNewPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  confirmNewPassword: string;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}
const PasswordUpdateModal: React.FC<PasswordUpdateModalProps> = ({
  onCurrentPasswordChange,
  onNewPasswordChange,
  isOpen,
  onClose,
  onUpdate,
}) => {
  const [currentPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  return (
    <div
      className="modal"
      style={{
        display: isOpen ? "block" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
    >
      <div
        className="modal-content"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "450px",
          height: "450px",
          padding: "20px",
          background: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose} style={{ marginLeft: "auto" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="h6" gutterBottom>
          Uppdatera lösenord
        </Typography>

        <div style={{ marginBottom: "16px" }}>
          <TextField
            label="Nuvarande lösenord"
            type="password"
            margin="normal"
            value={currentPassword}
            onChange={onCurrentPasswordChange}
            sx={{ width: "100%" }}
          />
        </div>

        <div style={{ marginBottom: "16px" }}>
          <TextField
            label="Nytt lösenord"
            type="password"
            margin="normal"
            onChange={onNewPasswordChange}
            sx={{ width: "100%" }}
          />
        </div>

        <div style={{ marginBottom: "16px" }}>
          <TextField
            label="Bekräfta nytt lösenord"
            type="password"
            margin="normal"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            sx={{ width: "100%" }}
          />
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={onUpdate}
          sx={{
            width: "70%",
            height: "40px",
            fontSize: "14px",
            margin: "auto",
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default PasswordUpdateModal;
