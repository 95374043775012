/* eslint-disable prettier/prettier */
import LinkButton from "./LinkButton";
import React, { useEffect } from "react";

import { ReactComponent as ZeedsUserManualTreeIcon } from "@assets/icons/ZeedsUserManualTree.svg";

import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Box, Typography } from "@mui/material";

export function IntendedUse() {
  return (
    <>
      <h2>Avsedd Användning</h2>
      <p>
        Zeeds är avsedd att användas för att öka effektiviteten av psykologisk
        behandling utifrån Focused Acceptance and Commitment Therapy
        (FACT)(Strosahl et al., 2012) utförd av en licensierad vårdgivare.
        Lösningen består av en behandlarportal som har två funktioner:
      </p>
      <ul style={{ paddingLeft: "1rem" }}>
        <li>
          Att administrera en användarvänlig patientapplikation, som används av
          patienten för att registrera och kommunicera aktivitet mellan
          sessionerna.
        </li>
        <br />
        <li>
          Att aggregera och visualisera patientaktivitet mellan sessionerna.
        </li>
      </ul>
      <p>
        Zeeds ska endast användas i samband med behandlardriven psykoterapi, och
        i enlighet med denna användarmanual. Data som visas inom Zeeds ska inte
        likställas med data som kan användas på egen hand för att utföra
        behandling, och måste alltid följas upp av en licensierad vårdgivare
        innan något beslut fattas angående patientens behandling.
        <ul style={{ paddingLeft: "1rem" }}></ul>
        Denna manual kräver att användaren är PTP-psykolog eller legitimerad
        psykolog med klinisk erfarenhet av Acceptance and Commitment Therapy
        (ACT).
      </p>
    </>
  );
}

export function PatientProfile() {
  return (
    <>
      <h2>Patientprofil</h2>
      <p>
        Zeeds är ett transdiagnostiskt verktyg som huvudsakligen används för
        patienter med mildriga till måttliga depressions- eller ångestbesvär i
        samband med en FACT-behandling. Zeeds kan även användas för subkliniska
        med ångest- eller depressionssymtom.
        <p>
          {" "}
          Evidens för effekten av Acceptance and Commitment Therapy (ACT) som en
          transdiagnostisk behandling, finns för många DSM-5 diagnoser (American
          Psychiatric Association, 2013). Det finns evidens för liten till
          medelstor effekt på depressiva syndrom, och medelstora till stora
          effekter på ångestsyndrom (Ferreira et al.,2022). Det finns även
          preliminära fynd på liten till medelstor effekt för substansbrukssyndrom 
          enligt DSM-5 (Lee et al.,2015). Forskningen
          utvecklas ständigt och bör bedömas kritiskt och självständigt.{" "}
        </p>
      </p>
      <p>Zeeds ska inte användas i samband med psykodynamisk terapi.</p>
      <p>Zeeds ska inte användas för patienter under 16 år.</p>
      <p>
        Zeeds ska inte användas för patienter med låg verbal intelligens. Detta
        då applikationen kräver normal läs-och skrivförmåga för att
        tillhandahålla materialet.
      </p>
      <p>
        Vårdgivaren bör genomföra en nödvändig fallkonceptualisering samt
        utesluta fysiologiska diagnoser, för att kunna besluta vilka patienter
        som hade haft nytta av behandlingen. Beslut för administration bör
        ultimat tas i beaktande med klinisk erfarenhet och evidens.
      </p>
    </>
  );
}

export function PatientApplication() {
  return (
    <>
      <h2>Zeeds Patient Application</h2>
      <p>
        Zeeds patient application enables activity scheduling and activity
        monitoring (Kanter et al,.2012) for the patient to conduct between
        sessions, as well as the Acceptance and Commitment Therapy exercises
        Life Compass and The Birthday Party (Harris,.2019) and the Patient
        Health Questionnaire 2 (Kroenke et al.,2003). The patient input is
        guided by socratic methodology (Carey et al,.2014) as text, and
        illustrations are optimised for a diverse patient profile. The user flow
        of the application has been developed in relation to modern user
        experience-optimized principles (Grant, 2018).
      </p>
      <p>
        The primary section of the application contains the modules relating to
        values. The secondary section of the application contains the modules
        relating to activity scheduling and activity monitoring. Patients can
        enable notification to be sent out as reminders.
      </p>
    </>
  );
}

export function ZeedsInsights() {
  return (
    <>
      <h2>Zeeds Insights</h2>
      <ol style={{ paddingLeft: "1rem" }}>
        <li>
          <p>
            The portal contains your patients profiles. Each profile contains
            summarised information of the patients activities.
          </p>
        </li>
        <li>
          <p>
            You create a patient profile by entering the name and email adress
            and Swedish Social Security Number of the relevant patient. This
            triggers a send out of an email that directs the patient to Google
            Play or App Store. Patients download the app and authenticate
            themselves via Bank ID, prior to gaining access to the application.
          </p>
        </li>
        <li>
          <p>Om du klickar på en patientprofil ser du dess tillhörande data.</p>
        </li>
        <li>
          <p>
            You are able to create an unlimited amount of users at once, via
            bulk-uploading of a specially formatted csv-file. Contact us if you
            want to use this feature.
          </p>
        </li>
        <li>
          <p>
            On the FAQ-page in the Portal you will find added information about
            answers to common questions, as well as contact information and a
            feedback form.
          </p>
        </li>
      </ol>
    </>
  );
}

export function PriorToTreatment() {
  return (
    <>
      {" "}
      <h2>Innan Behandling</h2>
      <p>
        Innan du administrerar Zeeds, utför du din vanliga bedömning av
        patientens problem i linje med din fallkonceptualisering. Denna manual
        använder Behavioral Activation Treatment of Depression-Revised Manual
        (BATD-R):s validerade metodologi, tillsammans med nödvändiga inkluderade
        formulär (Lejeuz et al,.2011). Som komplement till BATD-R används
        följande standardiserade formulär för att komplettera administrationen,
        administrerade digitalt i patientapplikationen:
      </p>
      <ul>
        <li>
          <p>PHQ 2 (Kroenke et al.,2003)</p>
        </li>
        <li>
          <p>The Life Compass (Harris.,2019)</p>
        </li>
        <li>
          <p> The Birthday Party (Harris,.2019)</p>
        </li>
      </ul>
      <p>
        Patientapplikationen använder Sokratisk metod som bas för
        självreflekterande svar inom applikationens moduler (Carey et al,.2014).
        Modulerna i applikationen används för att efterlikna processen för
        analog beteendeaktivering (Lejeuz,.et al). För en jämförelse av Zeeds
        användarhandbok och BATD-R, se den jämförande bilagan “Zeeds BATD-R
        Mapping”.
      </p>
      <p>
        Det är viktigt att notera att Zeeds inte utför behandling, diagnostik
        eller predicerar utfall av terapi. Behandling utförs av vårdgivaren.
      </p>
    </>
  );
}

export function SessionOne({
  boxRef,
}: {
  boxRef?: React.RefObject<HTMLDivElement>;
}) {
  useEffect(() => {
    boxRef?.current?.scrollTo(0, 0);
  }, [boxRef]);

  return (
    <>
      <h2>Session 1</h2>
      <strong>Nyckelelement:</strong>
      <ul>
        <li>
          <p>Psykoedukation om depression</p>
        </li>
        <li>
          <p>Introduktion av BA</p>
        </li>
        <li>
          <p>Diskutera trauma och förlust</p>
        </li>
        <li>
          <p>Viktiga punkter om behandlingens struktur</p>
        </li>
        <li>
          <p>Introducera formulär för Daglig aktivitetsmonitorering</p>
        </li>
      </ul>
      <p>
        Efter att du har identifierat en patient med en relevant patientprofil,
        fokusera din första session på psykoedukation om depression,
        beteendeaktivering, behandlingens process och aktivitetsmonitorering.
        För närvarande har Zeeds inga funktioner för aktivitetsmonitorering i
        applikationen, så applikationen kommer inte att introduceras förrän i
        Session 2.
      </p>
      <strong>
        <p>Psykoedukation om depression</p>
      </strong>
      <p>
        Tala om förekomsten av depression, möjliga orsaker, symtom och
        sjukdomsförlopp.
      </p>
      <strong>
        <p>Introduktion till behandlingsrational för beteendeaktivering</p>
      </strong>
      <p>
        Förklara att målet med BA är att hjälpa patienten (1) att bli medveten
        om de livsområden som är viktigast för dem (2) identifiera de
        värderingar de har inom dessa områden och (3) definiera, planera och
        utföra beteenden som är i lnje med dessa värderingar. Introducera
        begreppet positivt förstärkande beteenden och förklara det dubbelriktade
        sambandet mellan motivation, humör och beteenden och hur sådana
        beteenden kan leda till symtomförbättringar. Prata kort om vikten av att
        välja rätt mängd beteenden och olika typer av beteenden (t.ex. att inte
        känna sig överväldigad och att fokusera på både sig själv och andra)
      </p>
      <strong>
        <p>Diskutera trauma och förlust</p>
      </strong>
      <p>
        Du kan också prata om den potentiella rollen av trauma och förlust i
        utvecklingen av depression och hur dessa kommer att åtgärdas under
        behandlingen. Be patienten prata om dessa upplevelser, deras inverkan på
        deras nuvarande beteende och hur BA kan hjälpa patienter att leva ett
        mer tillfredsställande och meningsfullt liv, samtidigt som de erkänner
        och accepterar dessa svåra händelser i sitt förflutna.
      </p>
      <strong>
        <p>Viktiga punkter om behandlingens struktur</p>
      </strong>
      <p>
        Förklara att BA är en strukturerad process som kräver regelbunden övning
        för att vara effektiv, och att det därför är nödvändigt att patienten
        gör sitt bästa för att komma till varje session under de kommande 6
        (eller fler) veckorna och göra de hemuppgifter de får vid slutet av
        varje session.
      </p>
      <strong>
        <p>Introducera formuläret för Daglig aktivitetsmonitorering</p>
      </strong>
      <p>
        Som en sista del av din första session, introducera patienten till
        formuläret för daglig aktivitetsmonitorering för att mäta sin
        baslinjeaktivitet. Förklara att detta är viktigt, eftersom vi ofta inte
        är medvetna om hur vi spenderar vår tid och att en noggrann undersökning
        av patientens nuvarande aktivitetsnivå kan hjälpa dem att identifiera
        vilka beteenden som bidrar till deras depression och även vilka
        aktiviteter de kan ha tid för och njuta av.
      </p>
      <p>
        Be patienten fylla i detta formulär så detaljerat som möjligt (helst
        timme för timme) och betygsätta varje aktivitet utifrån hur viktigt det
        är för dem att ha denna aktivitet i sitt liv (Viktighet) och hur mycket
        de tycker om att utföra aktiviteten (Njutning). Förklara att olika
        aktiviteter kan ha olika kombinationer av dessa betyg (t.ex. viktigt men
        inte njutbart, eller njutbart men inte viktigt, eller båda). Berätta för
        patienten att de antingen kan fylla i formuläret kontinuerligt under
        dagen, eller registrera alla sina aktiviteter på en gång på kvällen. Men
        be dem att alltid skriva ner sina aktiviteter samma dag och inte flera
        dagar senare. I den aktuella versionen av applikationen ingår inte detta
        formulär i Zeeds. Ge din patient ett pappersformulär för
        aktivitetsmonitorering i slutet av sessionen. Denna finns att ladda ner
        som PDF i bilaga 2, som du kan skriva ut,
      </p>
      <strong>
        <p>Hemuppgift efter Session 1:</p>
      </strong>
      <ul>
        <li>Fyll i formulär för daglig aktivitetsmonitorering (PDF)</li>
      </ul>
    </>
  );
}

export function SessionTwo({
  boxRef,
}: {
  boxRef?: React.RefObject<HTMLDivElement>;
}) {
  useEffect(() => {
    boxRef?.current?.scrollTo(0, 0);
  }, [boxRef]);

  return (
    <>
      {" "}
      <h2>Session 2</h2>
      <strong>
        <p>Nyckelelement:</p>
      </strong>
      <ul>
        <li>Granskning av formuläret för Daglig aktivitetsmonitorering</li>
        <li>Introducera sociala kontrakt: Identifiera stödjande personer</li>
        <li>Introducera värderingar</li>
        <li>Introduktion av Zeeds</li>
      </ul>
      <strong>
        <p>Granskning av formuläret för Daglig aktivitetsmonitorering</p>
      </strong>
      <p>
        Börja Session 2 genom att gå igenom formuläret för daglig
        aktivitetsmonitorering från förra veckan tillsammans med din patient.
        Diskutera hur ofta de gjorde njutbara och/eller viktiga aktiviteter och
        peka på potentiella trender i deras beteende om det finns. Berätta för
        dem att du under de kommande veckorna kommer att fokusera på att
        förändra deras dagliga aktiviteter, men för tillfället ska de inte ändra
        något, annat än att vara uppmärksam på vad de gör i sin vardag.
      </p>
      <strong>
        <p>Introducera sociala kontrakt: Identifiera stödjande personer</p>
      </strong>
      <p>
        Förklara hur stödjande personer kan hjälpa till att övervinna depression
        och hur dessa människor kan hjälpa patienten att öka positivt
        förstärkande beteenden i deras vardag. Be dem identifiera några personer
        i sin omgivning som kan stödja dem på något sätt och att ta med en lista
        över dessa personer till nästa session. (Du kan be dem att skriva en
        lista på den nedre delen eller baksidan av deras dagliga
        övervakningsformulär för nästa vecka.)
      </p>
      <strong>
        <p>Introducera värderingar</p>
      </strong>
      <p>
        Introducera begreppet värderingar och varför det är viktigt att fokusera
        på värderingar när man identifierar positivt förstärkande beteenden.
        Använd The Life Compass (Harris,.2019) eller en tillämplig ACT-baserad
        livskompass, för att vägleda patienter att identifiera viktiga
        värderingar i de fyra huvudsakliga livsdomänerna och deras underområden.
        Välj sedan ett av underområdena och använd födelsedagsövningen (ACT) för
        att hjälpa dem hitta 1-3 värderingar som de anser vara viktiga i det
        området. Du kan också använda baslinjeaktivitetsmätningen för att grunda
        dessa övningar. Efter att ha identifierat 1-3 värderingar, introducera
        begreppet viktighet och linjering och be patienten att betygsätta vart
        och ett av värderingarna utifrån dessa.
      </p>
      <p>
        Syftet med dessa uppgifter är att skapa en vision för patienten, som en
        värderingsstyrd individ som är mindre beroende av att agera på
        omedelbara negativa känslomässiga tillstånd.
      </p>
      <strong>
        <p>Introduktion av Zeeds</p>
      </strong>
      <p>
        Efter att ha identifierat värderingar i ett delområde, berätta för
        patient att deras hemuppgift för den kommande sessionen kommer att vara
        att gå igenom de andra livsdomänerna och delområdena på liknande sätt,
        och att de kommer att använda en applikation för att göra detta.
        Förklara att de kan ladda ner och logga in i applikationen på App Store
        eller Google Play och hur du kommer att använda den i behandlingen. Du
        måste informera patienten om att data från applikationen kommer att ses
        av dig live under hela veckan. Du måste också klargöra att Zeeds inte är
        en självhjälpsapplikation, och att du som terapeut bedriver behandling
        där Zeeds är ett verktyg som du använder för att göra administreringen
        av terapi enklare för dig och patienten. Några ytterligare anmärkningar
        som tips till patienten:
      </p>
      <ul>
        <li>
          Hjälp patienten att schemalägga att reflektera över ett område i
          taget, i 20 minuter. Slutför livsområdena under flera dagar.
        </li>
        <li>
          Ett livsområde tar cirka 20 minuter att utföra. Hjälp patienten att
          planera att genomföra dessa i en ostörd miljö.
        </li>
      </ul>
      <strong>
        <p>Uppgifter efter Session 2:</p>
      </strong>
      <ul>
        <li>Daglig aktivitetsmonitorering. Formulär 2.</li>
      </ul>
    </>
  );
}

export function SessionThree({
  boxRef,
}: {
  boxRef?: React.RefObject<HTMLDivElement>;
}) {
  useEffect(() => {
    boxRef?.current?.scrollTo(0, 0);
  }, [boxRef]);
  return (
    <>
      <h2>Session 3</h2>
      <strong>
        <p>Nyckelelement:</p>
      </strong>
      <ul>
        <li>
          Granska formuläret för Daglig aktivitetsmonitorering och stödjande
          personer
        </li>
        <li>Diskutera värderingar</li>
        <li>Definiera och planera beteenden</li>
        <li> Introduktion av beteendedelen i Zeeds</li>
      </ul>
      <strong>
        <p>
          Granska formuläret för Daglig aktivitetsmonitorering och stödjande
          personer
        </p>
      </strong>
      <p>
        Granska formuläret för daglig aktivitetsmonitorering från förra veckan.
        Ta upp liknande ämnen som i Session 2. Granska listan över stödjande
        personer.
      </p>
      <strong>
        <p>Diskutera värderingar</p>
      </strong>
      <p>
        Diskutera patientens värderingar baserat på Zeeds Portal-data.
        Uppgifterna om värderingar och deras viktighet och linjering är bra
        utgångspunkter för fortsatt reflektion kring viktiga frågor i patientens
        liv. Berätta för dem att du nu ska fortsätta med att identifiera
        aktiviteter som är i linje med dessa värderingar.
      </p>
      <strong>
        <p>Definiera och planera beteenden</p>
      </strong>
      <p>
        Börja med att förklara vad ett beteende är och hur man definierar ett
        beteende för att stödja beteendeförändring: ett beteende ska vara
        observerbart, mätbart, något som du gör för att uppnå något positivt
        (istället för att undvika något negativt) och inte definierat som
        undvikande av en aktivitet, som att inte röka. Se till att de valda
        beteendena alla är relaterade till minst en av patientens värderingar.
        Uppmuntra din patient att välja aktiviteter från olika livsområden, för
        att skapa en blandning av njutbara och viktiga beteenden. Skapa en lista
        över dessa beteenden (cirka 5-10). Rangordna beteenden efter
        svårighetsgrad.
      </p>
      <p>
        När du har identifierat några beteenden, välj ett av dem och skapa en
        beteendeplan tillsammans med din patient. Uppmuntra dem att börja med
        ett beteende som är lätt och relaterat till en värdering där
        diskrepansen mellan viktighet och linjering är relativt stor.
      </p>
      <strong>
        <p>Introduktion av beteendedelen i Zeeds</p>
      </strong>
      <p>
        För att skapa en beteendeplan, gå igenom följande steg. (1) Identifiera
        dagar och tider under dagar för att utföra beteendet (2) Definiera
        antecedent för beteende (3) Definiera var beteendet ska utföras (4)
        Identifiera nödvändiga resurser och potentiella hinder för att utföra
        beteendet (5) Definiera enklare versioner av beteendet, om det
        ursprungliga beteendet inte är möjligt (6) Titta på listan över
        hjälpsamma relationer och fundera på vem som kan hjälpa dig med att
        regelbundet utföra beteendet och hur.
      </p>
      <p>
        Efter att ha skapat beteendeplanen, uppmuntra din patient att prata med
        den stödjande personen de har identifierat för denna aktivitet och be om
        stöd.
      </p>
      <p>
        I slutet av sessionen, kan du be din patient att lägga till
        beteendeplanen som ni just har skapat under sessionen, i applikationen,
        samt ett eller två andra potentiella beteenden, om det anses nödvändigt.
        Uppmana dem att välja aktiviteter som är relativt lätta (kanske något de
        redan gör men som de skulle vilja öka i frekvens) och relatera detta
        till värderingar med hög diskrepans. Be dem börja utföra dessa beteenden
        som planerat och att registrera dem i applikationen.
      </p>
      <p>
        Informera din patient om att när de använder i applikationen kommer ett
        kort frågeformulär med 2 frågor (PHQ-2) att dyka upp under slumpmässiga
        intervall i applikationen, som mäter humör och symtom, som du kommer att
        använda i terapi senare för att visualisera behandlingens framsteg.
        Dessutom kan du också be din patient att tänka på andra hälsosamma
        beteenden de skulle kunna göra och lägga till dem i den beteendelista ni
        startade.
      </p>
      <strong>
        <p>Hemuppgift efter Session 3:</p>
      </strong>
      <ul>
        <li>
          Lägg till beteendeplanen som diskuterades under sessionen i appen och
          planera 1-2 ytterligare beteenden.
        </li>
        <li>Beteenden till aktivitetslistan ni påbörjade i terapin</li>
        <li>
          Lägg till nya beteenden till aktivitetslistan som du påbörjade i
          terapin
        </li>
        <li>
          Ingen daglig aktivitetsmonitorering, då relevanta (positiva) beteenden
          registreras i applikationenn
        </li>
      </ul>
      <strong>
        <p>Therapeut:</p>
      </strong>
      <ul>
        <li>
          Granska beteendeplaner, registrerade beteenden och PHQ-2 i Portalen
          innan nästa session
        </li>
      </ul>
    </>
  );
}

export function SessionFour({
  boxRef,
}: {
  boxRef?: React.RefObject<HTMLDivElement>;
}) {
  useEffect(() => {
    boxRef?.current?.scrollTo(0, 0);
  }, [boxRef]);
  return (
    <>
      <h2>Session 4</h2>
      <strong>
        <p>Nyckelelements:</p>
      </strong>
      <ul>
        <li>Diskutera och iterera beteendeplaner</li>
        <li>Aktiviteter för veckan</li>
        <li>PHQ-2 poäng</li>
        <li>Lista över beteenden</li>
      </ul>
      <strong>
        <p>Diskutera och iterera beteendeplaner</p>
      </strong>
      <p>
        Diskutera de nya beteendeplanerna med din patient. Om du tror att vissa
        delar kan förbättras, förklara varför det skulle vara bra att ändra dem
        och vägled patienten att hitta alternativa planer.
      </p>
      <strong>
        <p>Aktiviteter för veckan</p>
      </strong>
      <p>
        Diskutera de 2-3 beteenden som din patient ville utföra under den
        senaste veckan. Prata om hur de kändes, vad patinten tänkte på innan de
        utförde beteendet, medan de gjorde det och efter att de utförde
        aktiviteten. Fråga patient om de kunde få hjälp av de stödjande personer
        som de identifierade för varje beteende på det sätt de ville. Vägled dem
        i att hitta lösningar om de hade svårt att be om socialt stöd eller om
        den hjälp den stödjande personen gav inte var vad patienten behövde
        (t.ex. att göra något istället för patienten, istället för att hjälpa
        dem att göra det själva).
      </p>
      <p>
        Om de inte lyckades göra (några av) aktiviteterna de planerade, prata om
        varför detta hände. Mötte de oförutsedda hinder? Hur kan de övervinna
        dessa hinder nästa gång? Om vissa aktiviteter verkade för svåra,
        uppmuntra patienten att dela upp dem i mindre steg.
      </p>
      <p>
        Fanns det några beteenden som patienten verkligen inte ville utföra? Om
        så är fallet kan det också vara ett alternativ att ersätta denna
        aktivitet med något annat.
      </p>
      <strong>
        <p>PHQ-2 poäng</p>
      </strong>
      <p>
        Diskutera PHQ-2-poäng om du anser det nödvändigt (t.ex. om du ser en
        betydande minskning eller signifikant eller stadig ökning i symptom).
        Senare i behandlingen kan du använda kopplingen mellan förbättringar i
        PHQ-2-poäng och frekvensen av positiva beteenden för att förstärka
        behandlingsrationalen och öka din patients motivation att utföra dessa
        beteenden.
      </p>
      <strong>
        <p>Lista över beteenden</p>
      </strong>
      <p>
        Titta på listan över beteenden du påbörjade under den senaste sessionen
        och som din patient ombads utöka under den senaste veckan. Diskutera om
        alla beteenden är definierade utifrån de principer du introducerade
        under den senaste sessionen (observerbart, mätbart, syftar till att
        uppnå något positivt och inte beskrivet i undvikande termer) och är
        relaterade till en eller flera värderingar. Om inte, stöd patient att
        hitta en bättre formulering eller annan aktivitet.
      </p>
      <p>
        Efter att du har gått igenom de nya aktiviteter som din patient har
        identifierat, be din patient att rangordna dessa i termer av
        svårighetsgrad och att välja 1-2 ytterligare beteenden som de skulle
        vilja planera och utföra under den kommande veckan (baserat på
        svårighetsgrad och diskrepans mellan viktigthet och linjering).
      </p>
      <strong>
        <p>Hemuppgifter efter Session 4:</p>
      </strong>
      <ul>
        <li>Planera ytterligare 1-2 beteenden i applikationen</li>
        <li>Utför och registrera tidigare och nya beteenden</li>
      </ul>
      <strong>
        <p> Terapeut:</p>
      </strong>
      <ul>
        <li>
          Granska ny beteendeplan, beteenderegistreringar och PHQ-2-poäng innan
          nästa session
        </li>
        <li>
          Se över värderingsfasen igen för att stödja patienten i att redigera
          och iterera dessa under nästa session
        </li>
      </ul>
    </>
  );
}

export function SessionFive({
  boxRef,
}: {
  boxRef?: React.RefObject<HTMLDivElement>;
}) {
  useEffect(() => {
    boxRef?.current?.scrollTo(0, 0);
  }, [boxRef]);
  return (
    <>
      <h2>Session 5</h2>
      <strong>
        <p>Nyckelelement</p>
      </strong>
      <ul>
        <li>
          Diskutera beteendeplaner, aktiviteter för veckan och PHQ 2-poäng
          baserat på patientdata
        </li>
        <li>Redigera och upprepa livsområden och värderingar</li>
      </ul>
      <strong>
        <p>Diskutera beteenden och PHQ-2 poäng</p>
      </strong>
      <p>
        Diskutera nya beteendeplaner, registrerade eller missade beteenden och
        PHQ-2-poäng på liknande sätt som i Session 4. Påminn patienten om att
        aktiviteterna de utför bör värderas högt avseende viktighet eller
        njutning eller bådadera, och att de bör försöka hitta en balans mellan
        dessa. Berätta för dem att det med tiden kan förändras hur viktigt och
        njutbart de anser att ett beteende är eller att en aktivitet kanske inte
        är så viktig och njutbar som de först trodde. Uppmuntra dem att anpassa
        sina aktiviteter enligt hur de värderar dessa och att kontinuerligt
        upprepa och ändra listan över beteenden och deras beteendeplaner baserat
        på deras nuvarande omständigheter.
      </p>
      <p>
        Fråga din patient om hjälpen de fick från de stödjande personer som de
        identifierade för vissa aktiviteter. Diskutera eventuella svårigheter
        och iterera denna del av beteendeplanen därefter.
      </p>
      <p>
        Uppmuntra din patient att planera och utföra 1-2 svårare beteenden under
        den kommande veckan. Om det behövs, ägna ytterligare tid åt att stödja
        patienten i hur de bäst kan göra detta.
      </p>
      <strong>
        <p>Redigera och upprepa livsområden och värderingar</p>
      </strong>
      <p>
        Gå tillbaka till avsnittet Livsområden och värderingar i Zeeds
        Portal-data. Påminn din patient om begreppet värderingar och dess roll i
        BA. Prata om hur de känner nu om de värderingar de identifierade i
        början av behandlingen och deras viktighet och linjering, och uppmuntra
        dem att redigera och upprepa dessa kontinuerligt.
      </p>
      <strong>
        <p>Hemuppgifter efter Session 5:</p>
      </strong>
      <ul>
        <li>Planera ytterligare 1 (svårare) beteende i applikationen</li>
        <li>Utför och registrera de nya och tidigare beteendena</li>
        <li>
          Gå tillbaka till värderingsfasen i applikationen och redigera dessa (i
          innehåll och/eller viktighet och linjering)
        </li>
      </ul>
      <strong>
        <p>Terapeut:</p>
      </strong>
      <ul>
        <li>
          Skriv ner patientens initiala värderingar och deras viktighet och
          linjering direkt efter Session 5 (innan de ändrar det) för att kunna
          jämföra dem med förändringarna inför nästa session
        </li>
        <li>
          Granska ny beteendeplan, beteenderegistreringar och PHQ-2-poäng före
          nästa session
        </li>
      </ul>
    </>
  );
}

export function SessionSix({
  boxRef,
}: {
  boxRef?: React.RefObject<HTMLDivElement>;
}) {
  useEffect(() => {
    boxRef?.current?.scrollTo(0, 0);
  }, [boxRef]);
  return (
    <>
      <h2>Session 6</h2>
      <strong>
        <p>Nyckelelement:</p>
      </strong>
      <ul>
        <li>
          Diskutera beteendeplaner, aktiviteter för veckan och PHQ-2-poäng
          baserat på patientdata
        </li>
        <li>Diskutera förändringar i värderingar</li>
        <li>Eventuellt avslut av behandlingen</li>
      </ul>
      <strong>
        <p>Diskutera beteenden och PHQ-2 poäng</p>
      </strong>
      <p>
        Diskutera nya beteendeplaner, registrerade eller icke-utförde beteenden
        och PHQ-2-poäng på samma sätt som du gjorde under tidigare sessioner.
      </p>
      <strong>
        <p>Diskutera förändringar i värderingar</p>
      </strong>
      <p>
        Jämför din patients nya värderingar och/eller viktighet och linjering
        med det de skattade i början av behandlingen. Fråga dem om orsaken till
        förändringarna. Om du ser en minskning av diskrepans i vissa livsområden
        kan du betona detta som en indikator för behandlingsframsteg. Påminn din
        patient om att de bör ha dessa värderingsförändringar i åtanke när de
        identifierar och planerar framtida beteenden.
      </p>
      <p>
        Uppmuntra din patient att fortsätta att arbeta med både värderingar och
        beteenden för att identifiera och utföra aktiviteter som kan förbättra
        deras livskvalitet.
      </p>
      <p>
        Vid det här laget har du olika alternativ för hur du vill fortsätta
        behandlingen baserat på din patients framsteg.
      </p>
      <ul>
        <li>
          Du kan upprepa session 6 en eller flera gånger och fortsätta att
          planera beteenden, redigera dem tillsammans och följa upp dem under
          sessionen om du tror att din patient behöver ytterligare stöd.
        </li>
        <li>
          Du kan också välja att fokusera på andra komponenter i behandlingen,
          medan din patient frivilligt arbetar med Zeeds, och bara spendera
          några minuter per session på att diskutera patientens erfarenheter av
          de registrerade beteendena.
        </li>
        <li>
          Eller om du och din patient är överens om att ni har gjort
          tillräckligt med framsteg under de senaste veckorna för att avsluta
          behandlingen, kan du också använda Session 6 för att avsluta
          behandlingen.
        </li>
      </ul>

      <strong>
        <p>Avslutande av behandling</p>
      </strong>

      <p>
        När du och din patient bestämmer sig för att det är dags att avsluta
        behandlingen är det viktigt att du förbereder dem för hur de kan
        bibehålla framstegen och förbättringarna utan stöd i sessionerna.
        Sammanfatta vad ni har lärt er under behandlingen och varför de begrepp
        och färdigheter ni pratade om är viktiga för att leva ett hälsosamt liv.
        Betona de framsteg patient gjort under behandlingen genom att jämföra
        deras allra första formulär för Daglig aktivitetsmonitorering med deras
        nuvarande aktiviteter. Uppmuntra dem att fortsätta använda de inlärda
        färdigheterna och strategierna i deras vardag och diskutera idéer om vad
        som kan hjälpa dem att göra det.
      </p>
      <p>
        Det är viktigt att berätta för patienten att de inte kommer att kunna
        använda Zeeds efter att behandlingen har avslutats, eftersom du kommer
        att stänga deras konton. Betona att du gör det för att du tror att de
        inte behöver applikationen längre för att upprätthålla dessa hälsosamma
        aktiviteter och hitta nya.
      </p>
      <p>
        Att radera patientens konto innebär att all data som kan användas för
        att identifiera dem kommer att raderas. Det kommer alltså inte att vara
        möjligt att koppla data till patienten. All personlig information
        raderas därefter från Zeeds, för den specifika patienten.
      </p>
    </>
  );
}

export function ReferenceList() {
  return (
    <>
      {" "}
      <h2>Referenser</h2>
      <ol>
        <li>
          Kanter, J., Puspitasari, A., Santos, M., & Nagy, G. (2012).
          Behavioural activation: History, evidence and promise. British Journal
          of Psychiatry, 200(5), 361-363. doi:10.1192/bjp.bp.111.103390
        </li>

        <li>Öst, L, G. (2013). KBT i Praktiken. Natur och Kultur Läromedel.</li>
        <li>
          American Psychological Association. (2013). Diagnostic and Statistical
          Manual of Mental Disorders Fifth Edition. American Psychiatric
          Publishing.
        </li>
        <li>
          Kroenke K, Spitzer RL, Williams JB. The Patient Health
          Questionnaire-2: validity of a two-item depression screener. Med Care.
          2003 Nov;41(11):1284-92. doi: 10.1097/01.MLR.0000093487.78664.3C .
          PMID: 14583691 .
        </li>
        <li>
          Harris, R. (2019). ACT Made Simple - An easy-to read primer. New
          Harbinger Publications.
        </li>
        <li>
          Carey, T. A., & Mullan, R. J. (2004). What is Socratic questioning?
          Psychotherapy: Theory, Research, Practice, Training, 41(3), 217–226.
          <a href="https://doi.org/10.1037/0033-3204.41.3.217">
            https://doi.org/10.1037/0033-3204.41.3.217
          </a>
        </li>
      </ol>
    </>
  );
}

export function Appendixes() {
  return (
    <>
      <h2>Bilagor</h2>
      <ol>
        <li>Jämförande sessionsstruktur - BATD-R - Zeeds</li>
        <li>Formulär 1. Baslinjemätning.</li>
        <li>Formulär 2. Aktivitetslista.</li>
      </ol>

      <Box
        sx={{
          display: "flex",
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        <LinkButton>
          <Typography
            sx={{
              width: "50px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            BADTR & Zeeds Session mapping{" "}
          </Typography>

          <PictureAsPdfOutlinedIcon sx={{ marginLeft: "1rem" }} />
        </LinkButton>
        <LinkButton>
          Formulär 1 <PictureAsPdfOutlinedIcon sx={{ marginLeft: "1rem" }} />
        </LinkButton>
        <LinkButton>
          Formulär 2 <PictureAsPdfOutlinedIcon sx={{ marginLeft: "1rem" }} />
        </LinkButton>
      </Box>

      <p>Gå till manualsidan i portalen för att ta del av bilagorna..</p>
    </>
  );
}

//terms and conditions
export function TermsAndConditions() {
  return (
    <>
      <h2>Villkor</h2>
      <p>
        Vi hoppas att denna manual är till nytta för dig. Genom att acceptera
        villkoren som anges i denna manual accepterar du härmed att du har:
      </p>
      <ol>
        <li>Läst och förstått manualen.</li>
        <li>
          Kommer att utföra din administration i enlighet med denna manual.
        </li>
      </ol>
    </>
  );
}

export function MinimalSoftwareRequirements() {
  return (
    <>
      <h2>Minimala Mjukvarukrav</h2>
    </>
  );
}

export function ZeedsUserManual() {
  return (
    <>
      <h1>Zeeds Användarmanual</h1>
    </>
  );
}

export function Introduction() {
  return (
    <>
      <h2>Zeeds Användarmanual</h2>
      <Box
        sx={{
          display: "flex",
          columnGap: "29px",
        }}
      >
        <Box>
          <ZeedsUserManualTreeIcon />
        </Box>
        <Box>
          <Typography sx={{ margin: "0" }}>Zeeds App AB</Typography>
          <Typography sx={{ margin: "0" }}>Skriven - December 2023</Typography>
          <Typography sx={{ margin: "0" }}>Upplaga - 1.3</Typography>
        </Box>
      </Box>
      <p>
        Denna manual definierar din administration. Använd menyn i det övre
        vänstra hörnet för att snabbt komma åt ett specifikt kapitel, eller
        klicka på pilarna för att gå igenom varje sida..{" "}
      </p>
      <p
        style={{
          border: "3px solid #C25435",
          borderRadius: "5px",
          padding: "1rem",
          width: "60%",
          marginBottom: "5rem",
        }}
      >
        Du har tillgång till den här manualen i din portal om du vill läsa den
        igen.
      </p>
    </>
  );
}

export function ZeedsPatientApplication() {
  return (
    <>
      <h2>Zeeds Patientapplikation</h2>
      <p>
        Zeeds patientapplikation möjliggör aktivitetsschemaläggning och
        aktivitetsplanering (Kanter et al,.2012) för patienten att genomföra
        mellan sessioner, såväl som Acceptance and Commitment Therapy-övningarna
        Life Compass och The Birthday Party (Harris,.2019). Patienter svarar
        även på Patient Health Questionnaire 2 (Kroenke et al.,2003). Frågorna i
        applikationen formuleras efter sokratisk metod, (Carey et al,.2014) och
        texter och illustrationer är anpassade för en mångsidig patientprofil.
        Användarflödet i applikationen har utvecklats i relation till moderna UX
        principer (Grant, 2018).
      </p>
      <p>
        Den första delen av applikationen innehåller moduler som fokuserar på
        värderingar. Den andra delen av applikationen innehåller moduler som rör
        aktivitetsschemaläggning och aktivitetsplanering. Patienter kan
        möjliggöra att skicka ut påminnelser för specifika beteenden via
        notiser.
      </p>
    </>
  );
}

export function ZeedsPortal() {
  return (
    <>
      <h2>Zeeds Portal</h2>
      <ol>
        <li>
          Portalen innehåller dina patienters profiler. Varje profil innehåller
          sammanfattad information om patientens aktivitet.
        </li>
        <li>
          Du skapar en patientprofil genom att ange namn och e-postadress samt
          svenskt personnummer på aktuell patient. Detta leder till utskick av
          ett e-postmeddelande till patienten, med information om att ladda ner
          Zeeds via Google Play eller App Store. Patienter laddar ner appen och
          verifierar sig via Bank ID för att få tillgång till applikationen.
        </li>
        <li>Om du klickar på en patientprofil ser du dess tillhörande data.</li>
        <li>
          Genom att klicka på en profil öppnas detaljerad live-data i de
          relevanta områdena.
        </li>
        <li>
          På informationssidan i Portalen hittar du svar på vanliga frågor, samt
          kontaktinformation.
        </li>
      </ol>
    </>
  );
}
