import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar, SnackbarCloseReason } from "@mui/material";

export const StandardSnackbar = ({
  msg,
  setSnackBarMessage,
  messageTime = 3000,
}: {
  msg: string;
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>;
  messageTime?: number;
}) => {
  const handleCloseSnackBar = (
    event: Event | React.SyntheticEvent,
    reason: SnackbarCloseReason,
  ) => {
    if (reason !== "clickaway") {
      setSnackBarMessage("");
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      ContentProps={{
        sx: {
          fontWeight: 700,
          backgroundColor: "primary.main",
        },
      }}
      open={msg !== ""}
      autoHideDuration={messageTime}
      onClose={handleCloseSnackBar}
      message={msg}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackBarMessage("")}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};
