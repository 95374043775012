import PurposeOfDeletingUser from "./PurposeOfDeletingUser";
import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import { usePatient } from "@providers/PatientProvider";
import { useSnackbar } from "@providers/SnackbarProvider";

import CustomModal from "@components/CustomModal";

import { globalTextColors } from "@constants/colors";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  styled,
} from "@mui/material";

/**
 * Component that has functionality for the removing a user.
 *
 *
 * @param showRemovePatientDialog - Boolean for setShowRemovePatientDialog, true if it should be shown.
 * @param setShowRemovePatientDialog - The function that sets if the display should be shown or not
 * @param pendingRemoval - Index of the patient that is going to be removed
 * @param setPendingRemoval - Function removing the patient with the given index.
 * @param setSnackBarMessage  - Function that displays the given message
 *
 * @beta
 */

const RemovePatientDialog = ({
  showRemovePatientDialog,
  setShowRemovePatientDialog,
}: {
  showRemovePatientDialog: boolean;
  setShowRemovePatientDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { removePatient, refetch, pendingRemovalState } = usePatient();
  const [userId, setUserId] = useState<string>();
  const [pendingRemoval, setPendingRemoval] = pendingRemovalState;
  const [isReasonModalOpen, setIsReasonModalOpen] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const toggleVisibility = () => setIsReasonModalOpen(!isReasonModalOpen);

  useEffect(() => {
    if (pendingRemoval) {
      setUserId(pendingRemoval.id);
    }
  }, [pendingRemoval]);

  const abortRemoveUser = () => {
    setPendingRemoval(undefined);
    setShowRemovePatientDialog(false);
  };

  const onRemoveHandler = () => {
    if (pendingRemoval) {
      removePatient(pendingRemoval)
        .then(() => {
          setIsReasonModalOpen(true);
          setTimeout(() => {
            snackbar.setSnackbarMessage(t("User deleted!") as string);
          }, 2000);
          refetch();
        })
        .catch((e) => {
          setTimeout(() => {
            snackbar.setSnackbarMessage(`[Upload] Error - ${e}`);
          }, 200);
        });
    }
    setPendingRemoval(undefined);
    setShowRemovePatientDialog(false);
  };

  return (
    <>
      <Modal
        open={showRemovePatientDialog}
        onClose={() => setShowRemovePatientDialog(false)}
      >
        <ModalContent
          elevation={3}
          onKeyDown={(e) => {
            if (e.key === "Enter") onRemoveHandler();
          }}
        >
          <IconButton
            onClick={abortRemoveUser}
            sx={{
              position: "absolute",
              fontSize: "24px",
              right: "20px",
              color: globalTextColors.PRIMARY,
            }}
          >
            <CloseIcon />
          </IconButton>
          <ModalHeading>
            {t("Are you sure you want to remove the user?")}
          </ModalHeading>
          <ButtonRow>
            <CustomButton onClick={onRemoveHandler}>Remove</CustomButton>
            <CustomButton
              sx={{
                backgroundColor: "#fff",
                border: "2px solid #42446A",
                color: "#42446A",
                "&:hover": {
                  opacity: "1",
                  backgroundColor: "lightgray",
                },
              }}
              onClick={abortRemoveUser}
            >
              Cancel
            </CustomButton>
          </ButtonRow>
        </ModalContent>
      </Modal>

      <PurposeOfDeletingUser
        isVisibile={isReasonModalOpen}
        userId={userId}
        toggleVisibility={toggleVisibility}
      />
    </>
  );
};

export const Modal = styled(CustomModal)({
  width: "80%",
  margin: "0 auto",
});

export const ModalContent = styled(Paper, {
  name: "form",
})(({ theme }) => ({
  width: "100%",
  borderRadius: "20px",
  padding: "20px 20px 36px 20px",
  backgroundColor: "#E1E7EC",
}));

export const ModalHeading = styled(Typography)({
  marginTop: "12px",
  fontSize: "22px",
  fontWeight: "800",
  color: globalTextColors.PRIMARY,
  textAlign: "center",
});

export const ButtonRow = styled(Box)(() => ({
  width: "25%",
  marginTop: "36px",
  display: "flex",
  margin: "36px auto 0",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const CustomButton = styled(Button)({
  borderRadius: "5px",
  height: "48px",
  fontSize: "15px",
  fontWeight: "600",
  cursor: "pointer",
  width: "47%",
});

export default RemovePatientDialog;
