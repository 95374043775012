import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import Contact from "@components/Contact";
import Date from "@components/Date";
import Faq from "@components/Faq";

import { globalTextColors } from "@constants/colors";
import { faqData } from "@constants/faqData";

import SearchIcon from "@mui/icons-material/Search";
import { Box, TextField, Typography, styled } from "@mui/material";

export interface FAQ {
  question: string;
  answer: string;
}

const Info = () => {
  const [filter, setFilter] = useState<string>("");
  const [questions, setQuestions] = useState<FAQ[]>(faqData);

  const handleSearch = (e: React.KeyboardEvent) => {
    const text = (e.target as HTMLInputElement).value;
    setFilter(text);
    setQuestions(questions.filter((i) => i.question.includes(text)));
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (!filter) setQuestions(faqData);
  }, [filter]);

  return (
    <Box sx={{ width: "100%", paddingRight: "5%" }}>
      <HeadingsContainer>
        <InputFieldWrapper>
          <InputField
            variant="outlined"
            type="text"
            placeholder={t("Sök bland frågorna") as string}
            onKeyUp={handleSearch}
          />
          <SearchIcon
            sx={{
              position: "absolute",
              right: "14px",
              color: globalTextColors.PRIMARY,
            }}
            fontSize="large"
          />
        </InputFieldWrapper>
        <Date />
      </HeadingsContainer>
      <Title variant="h4">{t("Vanliga frågor")}</Title>
      <SubTitle>
        {/* Here you will find answers to common questions and contact information
        if you wish to get in touch with us. */}
        Här hittar du svar på vanliga frågor och kontaktuppgifter om du vill
        komma i kontakt med oss.
      </SubTitle>

      <Faq questions={questions} />
      <Contact />
    </Box>
  );
};

const HeadingsContainer = styled(Box)({
  display: "flex",
  width: "100%",
  height: "170px",
  alignItems: "center",
  padding: "0px 100px 0px 0px",
  justifyContent: "space-between",
});

const InputField = styled(TextField)({
  border: "none",
  width: "100%",

  fontSize: "15px",
  "& .MuiInputBase-formControl": {
    height: "48px",
    borderRadius: "2px",
  },
  input: {
    "&::placeholder": {
      color: "#000",
    },
  },
});

const InputFieldWrapper = styled("div")({
  width: "424px",
  position: "relative",
  display: "flex",
  alignItems: "center",
});

const Title = styled(Typography)({
  color: globalTextColors.PRIMARY,
  fontWeight: "700",
});

const SubTitle = styled(Typography)({
  maxWidth: "55%",
  fontSize: "15px",
  fontWeight: "400",
  margin: "12px 0px 20px",
  color: globalTextColors.SECONDARY,
});

export default Info;
