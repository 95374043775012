import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "@providers/AuthProvider";

/**
 * Small component used for checking if the psychologist has logged in
 * @param children - children nodes
 *
 * @returns children React components or navigates to Login page
 */
const AuthRoute = React.memo(function AuthRoute(params: {
  children: React.ReactNode;
}) {
  const { psychologist } = useAuth();
  const { children } = params;

  const value = localStorage.getItem("value");

  useEffect(() => {
    if (psychologist) {
      localStorage.setItem("value", value ? `${+value + 1}` : "1");
    }
  }, [psychologist, value]);

  return (
    <>
      {psychologist && psychologist?.emailVerified ? (
        children
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
});

export default AuthRoute;
