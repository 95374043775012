import app from "../firebaseSetup";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getAuth,
  getMultiFactorResolver,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@providers/AuthProvider";

import loginBgImage from "@assets/svgs/login-bg-image.svg";
import mainImage from "@assets/svgs/login-screen-main-image.svg";
import logoIcon from "@assets/svgs/zeeds-portal-icon.svg";

import { StandardSnackbar } from "@components/StandardSnackbar";

import { globalColor, globalTextColors } from "@constants/colors";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

import { gql, useQuery } from "@apollo/client";

const LoginScreen = () => {
  const { logIn } = useAuth();
  const auth = getAuth(app);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [emailField, setEmailField] = useState("");
  const [passwordField, setPasswordField] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const [isOtpStep, setIsOtpStep] = useState<boolean>(false);
  const [otpValue, setOtpValue] = useState<string>("");
  const [verificationId, setVerificationId] = useState<any>("");
  const [resolver, setResolver] = useState<any>("");
  const [isWrongOtp, setIsWrongOtp] = useState<boolean>(false);

  const toggleIsOtpStep = () => setIsOtpStep(!isOtpStep);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { data } = useQuery(
    gql(`query GetAdminUser {
          admin_user {
            is_active
            email
            id
            name
            organization
            phone_number
            private_key
            public_key
          }
        }`),
  );

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      if (!emailField || !passwordField) {
        throw Error;
      }
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth,
      );

      setSnackbarMessage(t("loggingIn") as string);
      logIn(emailField, passwordField)
        .then((res) => {
          if (!res.user.emailVerified) {
            sendEmailVerification(res.user);
            signOut(auth);
            setSnackbarMessage("Email is not verified.");
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          if (error.code === "auth/multi-factor-auth-required") {
            const selectedUser = data?.admin_user?.find(
              (item: any) =>
                item?.email === error?.customData?._serverResponse?.email,
            );

            const localStorageEmail = localStorage.getItem("userEmail");
            if (localStorageEmail !== selectedUser?.email) {
              localStorage.setItem("value", "1");
              localStorage.setItem("userEmail", selectedUser?.email);
            }

            if (selectedUser?.is_active) {
              toggleIsOtpStep();

              const resolver = getMultiFactorResolver(auth, error);

              if (
                resolver.hints[0].factorId ===
                PhoneMultiFactorGenerator.FACTOR_ID
              ) {
                const phoneInfoOptions = {
                  multiFactorHint: resolver.hints[0],
                  session: resolver.session,
                };

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                return phoneAuthProvider
                  .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                  .then((_verificationId) => {
                    setVerificationId(_verificationId);
                    setResolver(resolver);
                    recaptchaVerifier.clear();
                  });
              }
            } else {
              // eslint-disable-next-line no-console
              setSnackbarMessage(
                "Currently our team is busy in verifying your details.",
              );
            }
          } else if (error.code === "auth/wrong-password") {
            setSnackbarMessage(t("loginFailed") as string);
          }
        });
    } catch {
      setSnackbarMessage(t("loginFailed") as string);
    }
  };

  const handleEmailState = (e: React.SyntheticEvent) => {
    setEmailField((e.target as HTMLInputElement).value);
  };

  const handleOtpChange = (otp: string) => {
    setOtpValue(otp);
    setIsWrongOtp(false);
  };

  const handlePasswordState = (e: React.SyntheticEvent) => {
    setPasswordField((e.target as HTMLInputElement).value);
  };

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const toggleRememberMe = () => setIsRememberMe(!isRememberMe);

  const handleOtpSubmit = () => {
    const credentials = PhoneAuthProvider.credential(verificationId, otpValue);
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credentials);
    resolver
      .resolveSignIn(multiFactorAssertion)
      .then((res: any) => {
        if (!res.user.emailVerified) {
          sendEmailVerification(res.user);
          signOut(auth);
          setSnackbarMessage("Email is not verified.");
        } else {
          navigate("/");
        }
        setIsWrongOtp(false);
      })
      .catch((err: any) => {
        setIsWrongOtp(true);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "background.paper",
      }}
    >
      {!isTabletOrMobile && <BackgroundImage src={loginBgImage} />}

      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "background.paper",
          overflow: "hidden",
          padding: isTabletOrMobile ? "2rem 1rem" : "2rem 4rem",
        }}
      >
        <img
          src={logoIcon}
          style={{ width: 130, height: 50 }}
          alt="logo icon"
        />
        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexDirection={isTabletOrMobile ? "column" : "row"}
          height="100%"
          width="100%"
        >
          <img
            src={mainImage}
            style={
              isTabletOrMobile
                ? { width: "100%", height: "100%" }
                : { width: 635, height: "auto", objectFit: "cover" }
            }
            alt="main illustration"
          />

          {isOtpStep ? (
            <Box sx={{ width: isTabletOrMobile ? "100%" : "42%" }}>
              <h1 style={{ margin: 0, color: globalTextColors.PRIMARY }}>
                Check your inbox
              </h1>
              <p style={{ margin: 0, color: globalTextColors.PRIMARY }}>
                We have sent a code to your registered number.
              </p>
              <p
                style={{
                  margin: 0,
                  color: globalTextColors.PRIMARY,
                  marginBottom: "1rem",
                }}
              >
                Enter the 6-digit code.
              </p>

              {isWrongOtp && (
                <Typography color="red" fontSize="1rem">
                  Please enter correct code.
                </Typography>
              )}

              <OtpInput
                value={otpValue}
                onChange={handleOtpChange}
                numInputs={6}
                containerStyle={{
                  position: "relative",
                  background: "transparent",
                }}
                inputStyle={{
                  width: "4.5rem",
                  height: "2.5rem",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
                renderSeparator={() => <span style={{ marginRight: "1rem" }} />}
                renderInput={(props) => <input {...props} type="numeric" />}
              />

              <Button
                size="large"
                sx={{
                  marginTop: "1.44rem",
                  borderRadius: "2px",
                  color: "background.paper",
                }}
                onClick={handleOtpSubmit}
              >
                Continue
              </Button>

              <p style={{ color: "red" }}>
                Note -- You must answer captcha prior to the code being sent out
              </p>
            </Box>
          ) : (
            <form
              style={{
                width: isTabletOrMobile ? "90%" : "35%",
                margin: "0 auto",
              }}
            >
              <Typography
                sx={{ color: "text.primary" }}
                style={{
                  fontWeight: 700,
                  fontSize: "34px",
                  textAlign: isTabletOrMobile ? "center" : "left",
                }}
              >
                Log in to{" "}
                <span style={{ color: globalTextColors.ACCENT_COLOR }}>
                  Portal
                </span>
              </Typography>

              <InputField
                variant="outlined"
                placeholder="Your Email"
                type="text"
                onChange={handleEmailState}
              />

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <InputField
                  variant="outlined"
                  placeholder={t("password") as string}
                  type={isPasswordVisible ? "text" : "password"}
                  onChange={handlePasswordState}
                />
                {!isPasswordVisible ? (
                  <VisibilityOffIcon
                    onClick={togglePasswordVisibility}
                    sx={{
                      position: "absolute",
                      right: "14px",
                      marginTop: "14px",
                      color: globalColor.GRAY,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    onClick={togglePasswordVisibility}
                    sx={{
                      position: "absolute",
                      right: "14px",
                      marginTop: "14px",
                      color: globalColor.GRAY,
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>

              <FormControlLabel
                onClick={toggleRememberMe}
                sx={{
                  margin: "23px 0px 23px -10px",
                  fontSize: "13px",
                  "& .MuiFormControlLabel-label": {
                    color: globalTextColors.SECONDARY,
                  },
                }}
                control={
                  <Checkbox
                    onClick={toggleRememberMe}
                    sx={{ cursor: "pointer" }}
                    checked={isRememberMe}
                    color="default"
                  />
                }
                label="Remember me"
              />

              <Button
                type="submit"
                size="large"
                sx={{
                  borderRadius: "2px",
                  color: "background.paper",
                }}
                onClick={handleLogin}
              >
                Continue
              </Button>
              <p>
                Don{"'"}t have an account?{" "}
                <Link
                  sx={{
                    position: "relative",
                    zIndex: 2,
                  }}
                  href="/signup"
                >
                  Register
                </Link>
              </p>
              <p>
                <Link
                  sx={{
                    position: "relative",
                    zIndex: 2,
                  }}
                  href="/forgot-password"
                >
                  Forgot your password?
                </Link>
              </p>
            </form>
          )}

          <StandardSnackbar
            msg={snackbarMessage}
            setSnackBarMessage={setSnackbarMessage}
          />
        </Stack>
        <Box
          sx={{ position: "relative", zIndex: 2, float: "right" }}
          id="recaptcha-container"
        />
      </Box>
    </Box>
  );
};

export default LoginScreen;

const BackgroundImage = styled("img")({
  width: "50%",
  heigth: "100%",
  position: "absolute",
  objectFit: "contain",
  right: 0,
  zIndex: 0,
});

const InputField = styled(TextField)({
  border: "none",
  width: "100%",
  marginTop: "20px",
  "& .MuiInputBase-formControl": {
    borderRadius: "2px",
  },
  input: {
    "&::placeholder": {
      color: "#000",
    },
  },
});
