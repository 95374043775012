import { gql } from "@apollo/client";

const GET_USERS_BY_PSYCHOLOGIST_QUERY = gql`
  query get_users_by_psychologist($_eq: String) {
    user(where: { psychologist: { _eq: $_eq } }) {
      id
      name
      onboardingStep
      relationshipsStep
      healthStep
      leisureStep
      workStep
      behaviors {
        name
        performed
        weekdays
        time
        location
        resources
        trigger
        strategies
        development_area
        miniVersion
        simpleVersion
        inquiryTime
        obstacle
        days {
          day
          id
          time
        }
        values {
          first_comment_chosen
          development_subareaId
          second_comment_chosen
          third_comment_chosen
          development_subarea {
            first_comment
            second_comment
            third_comment
            typeName
            userId
          }
        }
      }
      moods {
        userId
        mood
        inquiryTime
        id
      }
      facts {
        factValue
        id
        inquiryTime
        userId
      }
      development_subarea {
        first_comment
        second_comment
        third_comment
        typeName
        id
      }
      email
      reflectionDone
      active
      relationships
      relationshipsExpected
      health
      healthExpected
      leisure
      leisureExpected
      work
      workExpected
      therapyType
    }
  }
`;

export default GET_USERS_BY_PSYCHOLOGIST_QUERY;
