import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React from "react";
import { Chart as Chartv2 } from "react-chartjs-2";

import { IMood, usePatient } from "@providers/PatientProvider";

import { Box } from "@mui/material";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

interface Props {
  moodsByWeeks: {
    weekKey: {
      moodsSum: number;
      moods: Array<IMood>;
    };
  };
  frequenciesByWeek: {
    weekKey: {
      frequenciesSum: number;
      frequency: number;
    };
  };
  FACTByWeeks: {
    weekKey: {
      facts: {
        factValue: number;
      }[];
      factsSum: number;
    };
  };
}

const Chart = ({ moodsByWeeks, frequenciesByWeek, FACTByWeeks }: Props) => {
  const { activePatient } = usePatient();
  let labels, barChartValues, lineChartValues;

  if (activePatient?.therapyType === "ba") {
    labels = Object.keys(moodsByWeeks).map(
      (weekKey, index) => `Week ${index + 1}`,
    );

    barChartValues = Object.values(frequenciesByWeek).map(
      ({ frequenciesSum }) => frequenciesSum,
    );

    lineChartValues = Object.values(moodsByWeeks).map(
      ({ moodsSum }) => moodsSum,
    );
  } else {
    labels = Object.keys(FACTByWeeks).map(
      (weekKey, index) => `Week ${index + 1}`,
    );

    barChartValues = Object.values(frequenciesByWeek).map(
      ({ frequenciesSum }) => frequenciesSum,
    );

    lineChartValues = Object.values(FACTByWeeks).map(
      ({ factsSum }) => factsSum,
    );
  }

  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label:
          activePatient?.therapyType === "ba"
            ? "PHQ-2 Poäng"
            : "Symtomskattning",
        borderColor: "#CE765D",
        backgroundColor: "#CE765D",
        borderWidth: 2,
        fill: false,
        data: lineChartValues,
        marginBottom: 20,
      },
      {
        type: "bar" as const,
        label: "Frekvens av beteenden",
        backgroundColor: "#83A179CC",
        data: barChartValues,
        borderColor: "#83A179CC",
        borderWidth: 2,
        borderRadius: 15,
      },
    ],
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "500px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <Chartv2
            style={{
              backgroundColor: "#FFFFFF",
              padding: "2rem",
              borderRadius: "1rem",
            }}
            type="bar"
            options={options}
            data={data}
          />
        </Box>
      </Box>
    </>
  );
};

export default Chart;
