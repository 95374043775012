import BAScreen from "./BAScreen";
import FACTScreen from "./FACTScreen";
import ManualScreen from "./ManualScreen";
import SettingsScreen from "./SettingsScreen";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PatientProvider } from "@providers/PatientProvider";
import SnackbarProvider from "@providers/SnackbarProvider";

import FadeIn from "@components/FadeIn";
import FeedbackModal from "@components/FeedbackModal";
import Info from "@components/Info";
import NavBar from "@components/NavBar";
import Upload from "@components/Upload";
import UserManualModal from "@components/UserManualModal";

import StatScreen from "@screens/StatScreen";

import { Box } from "@mui/material";

const HomeScreen = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.paper",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <NavBar />
          <FeedbackModal />
          <UserManualModal isButtonVisibile={false} type="ba" />

          <PatientProvider>
            <SnackbarProvider>
              <FadeIn duration={100} delay={0}>
                <Routes>
                  <Route path="/" element={<Navigate to="/patients" />} />
                  <Route path="/patients" element={<Upload />} />
                  <Route
                    path={"/patient/:patient/:name"}
                    element={<StatScreen />}
                  />
                  <Route path={"/faq"} element={<Info />} />
                  <Route path={"/manual"} element={<ManualScreen />} />
                  <Route path={"/manual/ba"} element={<BAScreen />} />
                  <Route path={"/manual/fact"} element={<FACTScreen />} />
                  <Route path={"/settings"} element={<SettingsScreen />} />
                </Routes>
              </FadeIn>
            </SnackbarProvider>
          </PatientProvider>
        </Box>
      </Box>
    </>
  );
};

export default HomeScreen;
