import Appendix from "./Appendix";
import CustomModal from "./CustomModal";
import LinkButton from "./LinkButton";
import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CancelIcon } from "@assets/icons/Cancel.svg";
import { ReactComponent as Next } from "@assets/icons/Next.svg";
import { ReactComponent as Previous } from "@assets/icons/Previous.svg";
import { ReactComponent as UserManualAppendixesIcon } from "@assets/icons/UserManualAppendix.svg";

import { globalTextColors } from "@constants/colors";
import {
  menuComponentsBA,
  menuComponentsFact,
} from "@constants/manualSections";

import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Box, Paper, Stack, Typography } from "@mui/material";

const Footer = styled(Box)(() => ({
  background: "#E1E7EC",
  width: "100%",
  height: "40px",
  borderRadius: "0px 0px 5px 5px",
}));

const Header = styled(Box)(() => ({
  background: "#E1E7EC",
  width: "100%",
  height: "40px",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "5px 5px 0px 0px",
}));

const RightsBox = styled(Box)(() => ({
  width: "100%",
  padding: "22px 44px",
  display: "flex",
  justifyContent: "space-between",
}));

const ContentBox = styled(Box)(() => ({
  width: "100%",
  padding: "22px 98px",
  display: "flex",
}));

const Content = styled(Box)(() => ({
  width: "100%",
}));

const PreviousButtonContainer = styled(Box)(() => ({
  width: "160px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "start",
}));

const NextButtonContainer = styled(Box)(() => ({
  width: "160px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "end",
}));

const UserManualAppendixes = styled(Box)(() => ({
  display: "flex",
}));

const Modal = styled(CustomModal)({
  width: "1067px",
  maxWidth: "calc(100% - 40px)",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ModalContent = styled(Paper)(() => ({
  width: "100%",
  height: "calc(100% - 89px - 89px)",
  borderRadius: "5px",
  backgroundColor: "#fff",
  lineHeight: "20px",
  color: globalTextColors.PRIMARY,
  position: "relative",
  justifyContent: "center",

  h2: {
    margin: "3rem 0",
  },
  "ul,ol": {
    paddingLeft: "1rem",
  },
}));

interface IAppendix {
  title: string;
  Component: ({
    toggleModalVisibility,
    boxRef,
  }: {
    toggleModalVisibility?: unknown;
    boxRef?: React.RefObject<HTMLInputElement>;
  }) => React.ReactElement;
}

const UserManualModal = ({
  isButtonVisibile = true,
  className,
  type,
}: {
  isButtonVisibile?: boolean;
  className?: string;
  type: "ba" | "fact";
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showAppendix, setShowAppendix] = useState<boolean>(false);

  console.log("type", type);
  const menuComponents = type === "ba" ? menuComponentsBA : menuComponentsFact;

  const [activeAppendix, setActiveAppendix] = useState<IAppendix>(
    menuComponents.introduktion[0],
  );

  const [nextAppendix, setNextAppendix] = useState<IAppendix>(
    menuComponents.kliniskInformation[0],
  );
  const [previousAppendix, setPreviousAppendix] = useState<IAppendix>();

  const toggleModalVisibility = useCallback(
    (event: unknown, reason: "backdropClick" | "escapeKeyDown") => {
      return setIsModalOpen(false);
    },
    [],
  );
  const openModal = useCallback(() => {
    return setIsModalOpen(true);
  }, []);

  const exitModal = useCallback(() => {
    return setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const handleShowAppendix = useCallback(
    () => setShowAppendix(!showAppendix),
    [showAppendix],
  );

  useEffect(() => {
    let isSetNextAppendix = false;
    let isSetPreviousAppendix = true;

    Object.entries(menuComponents).forEach(([, components]) => {
      components.forEach((component) => {
        if (component === activeAppendix) {
          isSetNextAppendix = true;
          isSetPreviousAppendix = false;
        } else {
          if (isSetPreviousAppendix) setPreviousAppendix(component);
          if (isSetNextAppendix) {
            setNextAppendix(component);
            isSetNextAppendix = false;
          }
        }
      });
    });
  }, [activeAppendix, menuComponents]);

  const moveToPreviousAppendix = useCallback(() => {
    if (previousAppendix) setActiveAppendix(previousAppendix);
  }, [previousAppendix]);

  const moveToNextAppendix = useCallback(() => {
    if (nextAppendix) setActiveAppendix(nextAppendix);
  }, [nextAppendix]);

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      if (isModalOpen)
        switch (event.key) {
          case "ArrowRight":
            moveToNextAppendix();
            break;
          case "ArrowLeft":
            moveToPreviousAppendix();
            break;
          case "Escape":
            exitModal();
            break;
          default:
            break;
        }
    },
    [exitModal, isModalOpen, moveToNextAppendix, moveToPreviousAppendix],
  );

  const boxRef = useRef<HTMLInputElement>(null);

  return (
    <div
      tabIndex={-1}
      onKeyDown={handleKeyDown}
      className="focus-visible:outline-none"
    >
      {isButtonVisibile && (
        <LinkButton onClick={openModal} sx={{ width: "200px" }}>
          {t("Användarmanual")}
          <PictureAsPdfOutlinedIcon sx={{ marginLeft: "1rem" }} />
        </LinkButton>
      )}

      <Modal open={isModalOpen} onClose={toggleModalVisibility}>
        <>
          <ModalContent elevation={3}>
            <Header>
              <UserManualAppendixes>
                <Box
                  sx={{
                    paddingLeft: "30px",
                    paddingRight: "26px",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleShowAppendix}
                >
                  <UserManualAppendixesIcon />
                </Box>
                <Box
                  sx={{
                    alignSelf: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "#1C1C1D",
                    }}
                  >
                    Användarmanual
                  </Typography>
                </Box>
                <Appendix
                  showAppendix={showAppendix}
                  activeAppendix={activeAppendix}
                  setActiveAppendix={setActiveAppendix}
                  setShowAppendix={setShowAppendix}
                  toggleModalVisibility={toggleModalVisibility}
                  type={type}
                />
              </UserManualAppendixes>
              <Box
                sx={{
                  paddingRight: "26px",
                  paddingTop: "6px",
                  alignSelf: "center",
                }}
                onClick={exitModal}
              >
                <CancelIcon />
              </Box>
            </Header>
            <Box
              sx={{
                overflowY: "scroll",
                height: "calc(100% - 40px - 40px)",
                width: "100%",
              }}
              ref={boxRef}
            >
              <RightsBox
                sx={{
                  color: "#555558",
                }}
              >
                <Typography>© 2022 Zeedsapp AB. All Rights Reserved</Typography>
                <Stack flexDirection="row" justifyContent="space-between">
                  <Box>
                    <Typography>Zeedsapp.com</Typography>
                    <Typography>info@zeedsapp.com</Typography>
                  </Box>
                </Stack>
              </RightsBox>

              <ContentBox>
                <PreviousButtonContainer
                  sx={{
                    opacity: previousAppendix ? "1" : "0",
                  }}
                >
                  <Previous
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "calc(50% + 20px)",
                    }}
                    onClick={moveToPreviousAppendix}
                  />
                </PreviousButtonContainer>
                <Content>
                  <activeAppendix.Component
                    toggleModalVisibility={toggleModalVisibility}
                    boxRef={boxRef}
                  />
                </Content>

                <NextButtonContainer
                  sx={{
                    opacity: nextAppendix ? "1" : "0",
                  }}
                >
                  <Next
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "calc(50% + 20px)",
                    }}
                    onClick={moveToNextAppendix}
                  />
                </NextButtonContainer>
              </ContentBox>
            </Box>
            <Footer />
          </ModalContent>
        </>
      </Modal>
    </div>
  );
};

export default UserManualModal;
