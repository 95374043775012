import app from "../firebaseSetup";
import {
  User,
  UserCredential,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";

const auth = getAuth(app);

const logIn = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const logOut = () => {
  return signOut(auth);
};

interface IAuthContext {
  logIn: (email: string, password: string) => Promise<UserCredential>;

  logOut: () => Promise<void>;

  getPsychologist: () => User;

  psychologist: User | null;
}

// Context
const AuthContext = React.createContext<IAuthContext>({
  logIn: (email, password) => {
    throw new Error("[AuthProvider] logIn called too early!");
  },
  logOut: () => {
    throw new Error("[AuthProvider] logOut called too early!");
  },
  getPsychologist: () => {
    throw new Error("[AuthProvider] getPsychologist called too early!");
  },
  psychologist: null,
});

// Provider
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentPyschologist, setCurrentPsychologist] = useState<User | null>(
    null,
  );
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentPsychologist(user);
      setPending(false);
    });
    return unsubscribe;
  }, []);

  if (pending) return <></>;

  return (
    <AuthContext.Provider
      value={{
        psychologist: currentPyschologist,
        getPsychologist: () => {
          if (!currentPyschologist)
            throw new Error(
              "[AuthProvider] Calling getPsychologist when psychologist is undefined!",
            );

          return currentPyschologist;
        },
        logIn,
        logOut: logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Consumer
export const useAuth = () => {
  return useContext(AuthContext);
};
