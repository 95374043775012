import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C25435",
    },
    secondary: {
      main: "#ff6584",
    },
    background: {
      paper: "#fff",
      default: "#f2f7fc",
    },
    text: {
      primary: "#42446A",
      secondary: "#a2a0ad",
    },
    success: {
      main: "#93ca80",
    },
    error: {
      main: "#f44336",
    },
    custom: {
      red: "#f9a292",
      gray: "#e8f3fb",
      green: "#93ca80",
      darkGray: "#e1eaf0",
      dark: "#232041",
      darkBlueGray: "#E1EAF0",
      backgroundColor: "#333333",
    },
  },
  typography: {
    fontFamily: "Lato",
    h4: {
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiFilledInput: {
      defaultProps: {
        fullWidth: true,
        disableUnderline: true,
        margin: "dense",
      },
      styleOverrides: {
        root: {
          fontSize: "20px",
          fontWeight: 400,
          backgroundColor: "white",
          height: 60,
          borderRadius: 20,
          paddingBottom: "20px",
          marginBottom: 30,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          color: "white",
          "&:hover": {
            boxShadow: "none",
            opacity: 0.6,
          },
          transition: "0.3s",
        },
        sizeLarge: {
          fontSize: "20px",
          fontWeight: 700,
          height: 60,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "20",
          width: 40,
          height: 40,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: 2,
          marginTop: 10,
          marginBottom: 30,
        },
      },
    },
  },
});

export default theme;
