import React from "react";
import { useTranslation } from "react-i18next";
import { CgSmileNeutral } from "react-icons/cg";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { NavLink } from "react-router-dom";

import { IMood, IPatient, usePatient } from "@providers/PatientProvider";

import UserInfo from "@components/UserInfo";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Chip, Grid, IconButton, Paper, styled } from "@mui/material";

const User = ({
  patient,
  setShowRemovePatientDialog,
}: {
  patient: IPatient;
  setShowRemovePatientDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { email, name, active, behaviors, id, moods } = patient;
  const { setActivePatient, pendingRemovalState } = usePatient();
  const [, setPendingRemoval] = pendingRemovalState;

  const onClickHandler = () => {
    setPendingRemoval(patient);
    setShowRemovePatientDialog(true);
  };

  const updatePatient = (_patient: IPatient) => () =>
    setActivePatient(_patient);

  const getMoodByWeeks = (moods: Array<IMood>) => {
    const moodsByWeek = moods?.reduce((weeklyMood: any, mood: any) => {
      const weekNumber = getWeekNumber(mood.inquiryTime);

      const weekKey = `week${weekNumber}`;

      if (!weeklyMood?.[weekKey])
        weeklyMood[weekKey] = { moods: [], moodsSum: 0 };

      weeklyMood?.[weekKey]?.moods.push(mood);

      weeklyMood[weekKey].moodsSum = weeklyMood[weekKey].moodsSum + mood.mood;

      return weeklyMood;
    }, {});

    const sortObjectKeys = Object.fromEntries(
      Object.entries(moodsByWeek).sort(),
    );
    return sortObjectKeys;
  };

  const getWeekNumber = (moodDate: any) => {
    const date: any = new Date(moodDate);
    const oneJan: any = new Date(2005, 0, 3);
    const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);

    return weekNumber;
  };
  const getMoodIcon = (moodValue: number) => {
    if (moodValue <= 1) {
      return <HiOutlineEmojiHappy />;
    } else if (moodValue <= 3) {
      return <CgSmileNeutral />;
    } else {
      return <HiOutlineEmojiSad />;
    }
  };
  return (
    <Row>
      <NavLink
        to={`/patient/${id}/${name}`}
        style={{ width: "100%", textDecoration: "none", cursor: "pointer" }}
        onClick={updatePatient(patient)}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={4}>
            <UserInfo name={name} email={email} />
          </Grid>
          <Grid item xs={5}>
            {active &&
              behaviors?.slice(0, 3).map(({ name }: any, index: any) => (
                <Chip
                  sx={{
                    backgroundColor: "#638B55",
                    marginRight: "10px",
                    fontSize: "13px",
                    color: "#fff",
                    pointerEvents: "none",
                    maxWidth: 150,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  label={t(name)}
                  key={index}
                />
              ))}
          </Grid>
          <Grid item xs={3}>
            {active &&
              Object.values(getMoodByWeeks(moods))?.map(
                ({ moodsSum }: any, index: any) => (
                  <Chip
                    sx={{
                      marginRight: "10px",
                      fontSize: "35px",
                      maxHeight: "100%",
                      color: "#000000",
                      backgroundColor: "transparent",
                      pointerEvents: "none",
                      maxWidth: 150,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    icon={getMoodIcon(moodsSum)}
                    key={index}
                  />
                ),
              )}
          </Grid>
        </Grid>
      </NavLink>

      <IconButton sx={{ cursor: "pointer" }} onClick={onClickHandler}>
        <DeleteOutlineIcon />
      </IconButton>
    </Row>
  );
};

const Row = styled(Paper)({
  backgroundColor: "white",
  borderRadius: "10px",
  marginTop: "10px",
  padding: "17px 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #BBBBBE",
  cursor: "pointer",
});

export default User;
