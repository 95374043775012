import React from "react";
import { useTranslation } from "react-i18next";

import { globalTextColors } from "@constants/colors";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

const ValueText = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.dark,
  fontSize: 18,
  fontWeight: 400,
}));

export interface ValueData {
  title: string;
  icon: React.ReactElement;
  linerization: number;
  importance: number;
  content: { headline: string; text: string[] }[];
  background: string;
}

const Value = ({ valueData }: { valueData: ValueData }) => {
  const { title, icon, linerization, importance, content, background } =
    valueData;
  const { t } = useTranslation();
  const theme = useTheme();

  const open = false;

  return (
    <>
      <Accordion
        sx={{
          margin: "0 !important",
          borderRadius: "20px",
          height: "fit-content",
          "&::before": {
            background: "transparent",
          },
        }}
      >
        <AccordionSummary
          sx={{
            transition: "border-radius 0.2s",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              margin: "0 !important",
            },
            background,
            borderRadius: "12px",
            "&.Mui-expanded": {
              borderRadius: "12px 12px 0px 0px !important",
            },
          }}
          expandIcon={
            <ChevronRightIcon
              fontSize="large"
              sx={{
                color: globalTextColors.PRIMARY,
                transform: open ? "rotate(90deg)" : "",
                transition: "0.3s",
                marginRight: "0.5rem",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              padding: "0.5rem 1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack sx={{ width: "70%", flex: "1" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: theme.palette.custom.dark,
                    fontSize: "17px",
                    fontWeight: "700",
                  }}
                >
                  {t(title)}
                </Typography>
              </Box>
              <Stack
                flexDirection="row"
                columnGap="31px"
                sx={{ width: "100%" }}
              >
                <ValueText style={{ fontSize: 14 }}>
                  {t("Linjering")}: <b>{linerization}/10</b>
                </ValueText>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <ValueText style={{ fontSize: 14 }}>
                    {t("Viktighet")}: <b>{importance}/10</b>
                  </ValueText>
                </Box>
              </Stack>
            </Stack>
            {icon}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0 !important",
          }}
        >
          {content.map(
            (
              { headline, text }: { headline: string; text: string[] },
              i: number,
            ) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.5rem 1rem 0.5rem 0rem",
                  position: "relative",
                }}
              >
                <Box sx={{ paddingLeft: "3rem" }} />
                <Typography
                  sx={{
                    color: theme.palette.custom.dark,
                    fontSize: "17px",
                    fontWeight: "600",
                    width: "150px",
                  }}
                >
                  {t(headline)}
                </Typography>
                <Box
                  sx={{
                    color: theme.palette.custom.dark,
                    fontSize: "17px",
                    fontWeight: "400",
                  }}
                >
                  {text?.length === 3 &&
                  !text?.[0] &&
                  !text?.[1] &&
                  !text?.[2] ? (
                    <Typography key={i}>Not done yet</Typography>
                  ) : (
                    text?.map(
                      (t: string) =>
                        t !== '""' && <Typography key={i}>{t}</Typography>,
                    )
                  )}
                </Box>
                <Box
                  key={i}
                  sx={{
                    ...(i === 1 && { background, opacity: 0.2 }),
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Value;
