import styled from "@emotion/styled";

import { Modal, ModalProps } from "@mui/material";

/**
 * Custom modal component that uses customized display, justifyContent and alignItems.
 *
 * @beta
 */

const CustomModal = styled(Modal)<ModalProps>(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default CustomModal;
