import CustomModal from "./CustomModal";
import { format, parseISO } from "date-fns";
import { sv } from "date-fns/locale";
import React from "react";

import { IMatchingRequest, usePatient } from "@providers/PatientProvider";

import { globalTextColors } from "@constants/colors";

import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Paper, Typography, styled } from "@mui/material";

const Modal = styled(CustomModal)({
  width: "80%",
  margin: "0 auto",
});

const ModalHeading = styled(Typography)({
  marginTop: "12px",
  fontSize: "22px",
  fontWeight: "800",
  color: globalTextColors.PRIMARY,
  textAlign: "center",
});

const FieldHeading = styled(Typography)({
  fontWeight: 700,
  fontSize: "17px",
  color: globalTextColors.PRIMARY,
  marginRight: "20px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const FieldAnswer = styled(Typography)({
  fontWeight: 400,
  fontSize: "17px",
  color: globalTextColors.PRIMARY,
  overflow: "hidden",
  whiteSpace: "pre-wrap",
  textOverflow: "wrap",
});

const MatchingRequestDialog = ({
  matchingRequest,
  setMatchingRequest,
}: {
  matchingRequest: IMatchingRequest | null;
  setMatchingRequest: (matchingRequest: IMatchingRequest | null) => void;
}) => {
  // update status
  const { updateMatchingRequestStatus, userIdToNameMap, acceptNewPatient } =
    usePatient();

  const formattedDate = () => {
    if (!matchingRequest) return "";
    const date = parseISO(matchingRequest.createdAt);
    return format(date, "PP", { locale: sv });
  };

  const close = () => {
    if (!matchingRequest) return;
    // Mark as read
    if (matchingRequest.status === null) {
      updateMatchingRequestStatus({
        id: matchingRequest.id,
        status: "READ",
      });
    }
    setMatchingRequest(null);
  };

  const deleteRequest = () => {
    if (!matchingRequest) return;
    if (window.confirm("Är du säker på att du vill ta bort förfrågan?")) {
      updateMatchingRequestStatus({
        id: matchingRequest.id,
        status: "DISMISSED",
      });
      setMatchingRequest(null);
    }
  };

  const accept = async () => {
    if (!matchingRequest) return;
    if (!window.confirm("Är du säker på att du vill acceptera förfrågan?")) {
      return;
    }

    const resp = await acceptNewPatient(matchingRequest.userId);

    if (resp) {
      updateMatchingRequestStatus({
        id: matchingRequest.id,
        status: "ACCEPTED",
      });
      setMatchingRequest(null);
    } else {
      alert("Något gick fel, försök igen senare.");
    }
  };

  return (
    <>
      <Modal open={!!matchingRequest} onClose={close}>
        <Paper<"form">
          sx={{
            width: "80%",
            borderRadius: "30px",
            padding: "20px 20px 36px 20px",
            backgroundColor: "#E1E7EC",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2rem",
          }}
          elevation={3}
          component={"form"}
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <IconButton
            onClick={close}
            sx={{
              position: "absolute",
              fontSize: "24px",
              right: "20px",
              color: globalTextColors.PRIMARY,
            }}
          >
            <CloseIcon />
          </IconButton>
          {matchingRequest && (
            <>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <ModalHeading>
                  Den här användaren vill träffa dig för en första bedöming.
                </ModalHeading>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  alignItems: "left",
                  gap: "1rem",
                  width: "80%",
                  borderRadius: "5px",
                  border: "1px solid #CCCCCC",
                  padding: "20px 36px",
                }}
              >
                <div>
                  <FieldHeading>Namn</FieldHeading>
                  <FieldAnswer>
                    {userIdToNameMap[matchingRequest.userId]}
                  </FieldAnswer>
                </div>

                <div>
                  <FieldHeading>Meddelande</FieldHeading>
                  <FieldAnswer>{matchingRequest.message}</FieldAnswer>
                </div>

                <div>
                  <FieldHeading>Kontaktuppgifter</FieldHeading>
                  <FieldAnswer>
                    Telefon: {matchingRequest.contactPhone}
                  </FieldAnswer>
                  <FieldAnswer>
                    E-post: {matchingRequest.contactEmail}
                  </FieldAnswer>
                </div>

                <FieldAnswer
                  sx={{
                    fontSize: "15px",
                  }}
                >
                  Skapad: {formattedDate()}
                </FieldAnswer>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  alignItems: "center",
                  gap: "1rem",
                  width: "80%",
                  borderRadius: "5px",
                  border: "1px solid #CCCCCC",
                  padding: "20px 36px",
                }}
              >
                <FieldAnswer
                  sx={{
                    fontSize: "15px",
                  }}
                >
                  När du har kommit överens med användaren om en påbörjad
                  behandlingskontakt kan du klicka på acceptera. Du kommer då ta
                  del av användarens kliniska data i Zeeds.
                </FieldAnswer>
                <Button
                  color="primary"
                  sx={{
                    borderRadius: "5px",
                    width: "auto",
                    maxWidth: "50%",
                  }}
                  aria-label="contact"
                  size="medium"
                  onClick={accept}
                >
                  Acceptera
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  gap: "1rem",
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{
                    borderRadius: "5px",
                    width: "auto",
                    color: globalTextColors.ACCENT_COLOR,
                  }}
                  aria-label="contact"
                  size="medium"
                  onClick={deleteRequest}
                >
                  Ta bort
                </Button>

                <Button
                  color="primary"
                  sx={{
                    borderRadius: "5px",
                    width: "auto",
                  }}
                  aria-label="contact"
                  size="medium"
                  href={`mailto:${matchingRequest.contactEmail}`}
                >
                  Kontakta
                </Button>
              </div>
            </>
          )}
        </Paper>
      </Modal>
    </>
  );
};

export default MatchingRequestDialog;
