import CustomModal from "./CustomModal";
import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import React from "react";

import { useAuth } from "@providers/AuthProvider";

import { globalTextColors } from "@constants/colors";

import LockIcon from "@mui/icons-material/Lock";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { gql, useMutation } from "@apollo/client";

function FeedbackModal() {
  const psychologist = useAuth().psychologist;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [satisfaction, setSatisfaction] = useState<number>(0);
  const value = localStorage.getItem("value");

  const ADD_FEEDBACK_QUERY = gql`
    mutation InsertFeedback(
      $userId: String
      $text: String
      $satisfactionLevel: Int
    ) {
      insert_feedback(
        objects: {
          userId: $userId
          text: $text
          satisfactionLevel: $satisfactionLevel
        }
      ) {
        affected_rows
        returning {
          id
          userId
          createdAt
          text
          satisfactionLevel
        }
      }
    }
  `;

  const [addFeedback] = useMutation(ADD_FEEDBACK_QUERY);

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen],
  );

  const handleTextAreaChange = (e: any) => {
    if (textAreaValue.length <= 300) {
      setTextAreaValue(e.target.value);
    }
  };

  const satisfactions = [1, 2, 3, 4, 5, 6, 7];

  const handleChangeSatisfaction = (_satisfaction: number) => () => {
    setSatisfaction(_satisfaction);
  };

  const handleSubmit = () => {
    addFeedback({
      variables: {
        userId: psychologist?.uid,
        text: textAreaValue,
        satisfactionLevel: satisfaction,
      },
    });
    toggleModal();
  };

  useEffect(() => {
    if (!!value && +value % 4 === 0) {
      toggleModal();
    }
  }, [toggleModal, value]);

  return (
    <Modal open={isModalOpen}>
      <ModalContent sx={{ outline: "none" }}>
        <Typography textAlign="center" fontSize="22px" fontWeight="800">
          Questions about your experience
        </Typography>

        <SatisfactionBlock>
          <Typography
            fontSize="15px"
            fontWeight="700"
            color="#42446A"
            textAlign="center"
          >
            Overall, how satisfied are you with your experience at Zeeds?
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem auto",
            }}
          >
            <Typography
              textAlign="center"
              fontSize="12px"
              sx={{ width: "100px" }}
            >
              Very dissatisfied
            </Typography>
            <Typography
              textAlign="center"
              fontSize="12px"
              sx={{ widht: "50px" }}
            >
              Very satisfied
            </Typography>
          </Box>

          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ width: "80%", margin: "0 auto" }}
          >
            {satisfactions.map((s) => (
              <SatisfactionBox key={s} onClick={handleChangeSatisfaction(s)}>
                {satisfaction === s && <SatisfactionBoxInner />}
              </SatisfactionBox>
            ))}
          </Stack>
        </SatisfactionBlock>

        <SatisfactionBlock>
          <Typography
            fontSize="15px"
            fontWeight="700"
            color="#42446A"
            textAlign="center"
          >
            Would you like to add something?
          </Typography>

          <Box sx={{ width: "100%", position: "relative" }}>
            <textarea
              value={textAreaValue}
              onChange={handleTextAreaChange}
              style={{ width: "100%", margin: "1rem auto", resize: "none" }}
              rows={5}
            />
            <p
              style={{
                position: "absolute",
                bottom: "1rem",
                right: "0.5rem",
                width: "50px",
                fontSize: "10px",
                lineHeight: "10px",
                textAlign: "center",
                color: globalTextColors.SECONDARY,
              }}
            >
              {textAreaValue.length}/300 characters
            </p>
          </Box>

          <Typography
            color="#42446A"
            sx={{ display: "flex", alignItems: "center", fontSize: "13px" }}
          >
            <LockIcon sx={{ color: "#42446A", marginRight: "0.5rem" }} />
            Your response is kept safe.
          </Typography>
        </SatisfactionBlock>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <CustomButton sx={{ width: "40%" }} onClick={handleSubmit}>
            Send
          </CustomButton>
          <CustomButton
            onClick={toggleModal}
            sx={{
              width: "40%",
              backgroundColor: "#fff !important",
              border: "2px solid #42446A",
              color: "#42446A",
              fontWeight: "600",
            }}
          >
            Skip
          </CustomButton>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default FeedbackModal;

const Modal = styled(CustomModal)({
  width: "30%",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ModalContent = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderColor: "transparent",
  borderRadius: "2.5rem",
  backgroundColor: "#E1E7EC",
  lineHeight: "20px",
  color: globalTextColors.PRIMARY,
  position: "relative",
  padding: "2rem",
  justifyContent: "center",
}));

const SatisfactionBlock = styled(Box)({
  width: "100%",
  borderRadius: "5px",
  backgroundColor: "#fff",
  padding: "1.2rem",
  margin: "1rem auto",
});

const CustomButton = styled(Button)({
  height: "48px",
  widht: "20% !important",
  borderRadius: "2px",
  backgroundColor: "#C25435",
});

const SatisfactionBox = styled(Box)({
  width: "21px",
  height: "21px",
  border: "1px solid #42446A",
  borderRadius: "100%",
  aspectRatio: "1",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SatisfactionBoxInner = styled(Box)({
  width: "80%",
  height: "80%",
  backgroundColor: "#42446A",
  borderRadius: "100%",
  aspectRatio: "1",
});
