import React from "react";

import { globalTextColors } from "@constants/colors";

import { Typography } from "@mui/material";

const UserInfo = ({ name, email }: { name: string; email: string }) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "17px",
          color: globalTextColors.PRIMARY,
          marginRight: "20px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          marginRight: "20px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontWeight: 400,
          fontSize: "13px",
          color: globalTextColors.PRIMARY,
        }}
      >
        {email}
      </Typography>
    </>
  );
};

export default UserInfo;
