import { gql } from "@apollo/client";

export const UPDATE_USER_PSYCHOLOGIST_ID = gql`
  mutation UpdateUserPsychologistId($id: String!, $psychologistId: String!) {
    update_user(
      where: { id: { _eq: $id } }
      _set: { psychologist: $psychologistId }
    ) {
      returning {
        id
        psychologist
      }
    }
  }
`;

export default UPDATE_USER_PSYCHOLOGIST_ID;
