import React from "react";

import { IMatchingRequest, usePatient } from "@providers/PatientProvider";

import MatchingRequest from "@components/MatchingRequest";

import { Box } from "@mui/material";

const MatchingRequests = ({
  openMatchingRequest,
}: {
  openMatchingRequest: (matchingRequest: IMatchingRequest) => void;
}) => {
  const { matchingRequests, allPatients, updateMatchingRequestStatus } =
    usePatient();

  // Filter out matching requests that are already patients
  const validMatchingRequests = React.useMemo(() => {
    return matchingRequests.filter((matchingRequest) => {
      return !allPatients.some((patient) => {
        return patient.id === matchingRequest.userId;
      });
    });
  }, [matchingRequests, allPatients]);

  const deleteMatchingRequest = async (matchingRequest: IMatchingRequest) => {
    if (
      window.confirm(`Är du säker på att du vill ta bort behandlingsintresset?`)
    ) {
      await updateMatchingRequestStatus({
        id: matchingRequest.id,
        status: "DISMISSED",
      });
    }
  };

  return (
    <Box sx={{ paddingRight: "100px" }}>
      {validMatchingRequests.map((request) => (
        <MatchingRequest
          key={request.id}
          data={request}
          openMatchingRequest={() => openMatchingRequest(request)}
          deleteMatchingRequest={() => deleteMatchingRequest(request)}
        />
      ))}
    </Box>
  );
};

export default MatchingRequests;
