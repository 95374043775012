import Value from "./Value";
import React from "react";

import { Box } from "@mui/material";

const Values = ({
  data,
}: {
  data: {
    title: string;
    icon: React.ReactElement;
    linerization: number;
    importance: number;
    content: { headline: string; text: string[] }[];
    background: string;
  }[];
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto auto",
        gap: "10px",
        transition: "0.3s",
      }}
    >
      {data.map((valueData) => (
        <Value valueData={valueData} key={valueData.title} />
      ))}
    </Box>
  );
};

export default Values;
