import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";

import sessionManual from "@assets/pdfs/Bilaga 1.pdf";
import sessionManual2 from "@assets/pdfs/Bilaga 2.pdf";
import sessionManual3 from "@assets/pdfs/Bilaga 3.pdf";
import sessionManual4 from "@assets/pdfs/Bilaga 4.pdf";

import DateComponent from "@components/Date";
import PdfFormsModal from "@components/PdfFormsModal";
import UserManualModal from "@components/UserManualModal";

import { globalTextColors } from "@constants/colors";

import { Typography } from "@mui/material";

function FACTScreen() {
  const { t } = useTranslation();
  return (
    <div className="py-48">
      <Title variant="h4">PDF-filer</Title>
      <DateComponent />
      <SubTitle>Klicka på en fil för att öppna, ladda ner och printa.</SubTitle>

      <div className="flex ">
        <div>
          <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
            Manual
          </Typography>
          <UserManualModal type="fact" />
        </div>
      </div>

      <Typography
        sx={{ margin: "1.6rem 0px", fontSize: "16px", fontWeight: "700" }}
      >
        {t("Bilagor")}
      </Typography>
      <div className="flex flex-row gap-8">
        <PdfFormsModal pdfPath={sessionManual} title="Bilaga 1" />
        <PdfFormsModal pdfPath={sessionManual2} title="Bilaga 2" />
        <PdfFormsModal pdfPath={sessionManual3} title="Bilaga 3" />
        <PdfFormsModal pdfPath={sessionManual4} title="Bilaga 4" />
      </div>
    </div>
  );
}

const Title = styled(Typography)({
  color: globalTextColors.PRIMARY,
  fontWeight: "700",
});

const SubTitle = styled(Typography)({
  maxWidth: "55%",
  fontSize: "15px",
  fontWeight: "400",
  margin: "12px 0px 20px",
  color: globalTextColors.SECONDARY,
});

export default FACTScreen;
