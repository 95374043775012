import CustomModal from "./CustomModal";
import { t } from "i18next";
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@providers/AuthProvider";
import { IPartialPatient, usePatient } from "@providers/PatientProvider";
import { useSnackbar } from "@providers/SnackbarProvider";

import { globalTextColors } from "@constants/colors";

import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material";

interface Props {
  showAddPatientDialog: boolean;
  setShowAddPatientDialog: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  personalNumber: string;
  setPersonalNumber: React.Dispatch<React.SetStateAction<string>>;
}

const AddPatientDialog = ({
  showAddPatientDialog: showPatientDialog,
  setShowAddPatientDialog: setShowPatientDialog,
  name,
  setName,
  email,
  setEmail,
  personalNumber,
  setPersonalNumber,
}: Props) => {
  const navigate = useNavigate();
  const { allPatients, addPatient, refetch, setActivePatient } = usePatient();
  const psychologist = useAuth().getPsychologist();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [nameError, setNameError] = useState({
    error: false,
    message: "",
  });
  const [personalNumberError, setPersonalNumberError] = useState({
    error: false,
    message: "",
  });
  const [therapy, setTherapy] = useState<string>("ba");

  const snackbar = useSnackbar();

  const newPatienListLengthRef = useRef<number>(0);
  const oldPatienListLengthRef = useRef<number>(0);
  const retriesRef = useRef<number>(0);
  const latestPatientsRef = useRef<any>(allPatients);

  useEffect(() => {
    oldPatienListLengthRef.current = allPatients.length;
    latestPatientsRef.current = allPatients;
  }, [allPatients]);

  const getUpdatedPatients = () => {
    refetch();
    const isLengthEqual =
      oldPatienListLengthRef.current === newPatienListLengthRef.current;
    retriesRef.current += 1;

    if (isLengthEqual) {
      snackbar.setSnackbarMessage("Patient created!");

      const allPatientsByRef = latestPatientsRef.current;
      const lastIndex = newPatienListLengthRef.current - 1;
      const lastPatient = allPatientsByRef[lastIndex];
      const { name, id } = lastPatient;

      setActivePatient(lastPatient);
      navigate(`/patient/${id}/${name}`);
      return;
    } else if (retriesRef.current > 10) {
      snackbar.setSnackbarMessage("Patient creation failed");
      return;
    } else {
      setTimeout(() => {
        getUpdatedPatients();
      }, 2000);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validateEmail() && validatePersonalNumber() && validateName()) {
      console.log("a");
      if (
        allPatients.filter((patient) => patient.email === email).length === 0
      ) {
        const newPartialPatient: IPartialPatient = {
          name,
          email,
          id: personalNumber,
          psychologist: psychologist.uid,
          therapyType: therapy,
        };

        addPatient(newPartialPatient)
          .then(() => {
            snackbar.setSnackbarMessage("Creating patient...");
            newPatienListLengthRef.current = allPatients.length + 1;
            retriesRef.current = 0;
          })
          .then(() => {
            getUpdatedPatients();
          })
          .catch((e) => {
            setTimeout(() => {
              snackbar.setSnackbarMessage(`[Upload] ${e}`);
            }, 200);
          });

        setShowPatientDialog(false);
        setName("");
        setEmail("");
        setPersonalNumber("");
        setTherapy("ba");
      } else {
        setTimeout(() => {
          snackbar.setSnackbarMessage("Email already in use");
        }, 200);
      }
    }
  };

  const hideModal = () => setShowPatientDialog(false);

  const handleEmailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setEmail(e.target.value);

  const handlePersonalNumberChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const number = (e.target as HTMLInputElement).value;
    if (number.length <= 12) {
      setPersonalNumber(number);
    }
  };

  const handleNameChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setName(e.target.value);

  const validateEmail: () => boolean = () => {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailError(true);
      return false;
    } else {
      setEmailError(false);
      return true;
    }
  };

  const validatePersonalNumber: () => boolean = () => {
    if (personalNumber.length !== 12) {
      setPersonalNumberError({
        error: true,
        message: "Du måste ange ett 12-siffrigt personnummer.",
      });
      return false;
    } else {
      const slicedPersonalNumber = personalNumber.replace(/\D/g, "").slice(-10);
      const sanitizedNumber = slicedPersonalNumber.split("").map(Number);
      if (sanitizedNumber.length !== 10) {
        setPersonalNumberError({
          error: true,
          message: "Du måste ange ett 12-siffrigt personnummer.",
        });
        return false;
      }

      console.log("Sanitized number:", sanitizedNumber);
      const multiplicationDigits = [2, 1, 2, 1, 2, 1, 2, 1, 2];
      let sum = 0;
      for (let i = 0; i < multiplicationDigits.length; i++) {
        let product = multiplicationDigits[i] * sanitizedNumber[i];

        // Check if product is two digits
        if (product.toString().length > 1) {
          // Split the product into two digits
          const splitProduct = product.toString().split("").map(Number);
          // Add the two digits together
          product = splitProduct[0] + splitProduct[1];
        }

        sum += product;
      }

      let cheksum = 10 - (sum % 10);
      console.log("Checksum:", cheksum);

      if (cheksum.toString().length > 1) {
        cheksum = cheksum.toString().split("").map(Number)[1];
      }

      if (cheksum !== sanitizedNumber[9]) {
        setPersonalNumberError({
          error: true,
          message: "Du måste ange ett giltigt personnummer.",
        });
        return false;
      }

      setPersonalNumberError({
        error: false,
        message: "",
      });
      return true;
    }
  };

  const validateName: () => boolean = () => {
    if (name.length < 3) {
      setNameError({
        error: true,
        message: "Du måste ange ett namn som är minst 3 tecken långt.",
      });
      return false;
    } else {
      setNameError({
        error: false,
        message: "",
      });
      return true;
    }
  };

  const handleTherapyChange = useCallback(
    (event: SelectChangeEvent<string>, child: ReactNode) => {
      setTherapy(event.target.value);
    },
    [],
  );

  return (
    <>
      <Modal
        open={showPatientDialog}
        onClose={() => setShowPatientDialog(false)}
      >
        <Paper<"form">
          sx={{
            width: "100%",
            borderRadius: "30px",
            padding: "20px 20px 36px 20px",
            backgroundColor: "#E1E7EC",
          }}
          elevation={3}
          component={"form"}
          onSubmit={handleSubmit}
        >
          <IconButton
            onClick={hideModal}
            sx={{
              position: "absolute",
              fontSize: "24px",
              right: "20px",
              color: globalTextColors.PRIMARY,
            }}
          >
            <CloseIcon />
          </IconButton>

          <ModalHeading>
            Fyll i sektionerna för att lägga till en patient
          </ModalHeading>

          <Box
            sx={{
              marginTop: "39px",
              marginBottom: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <InputFieldWrapper>
              <InputLabel sx={{ ...(emailError && { color: "#FF0000" }) }}>
                Patientens mejladress{" "}
                {emailError && <ErrorOutlineIcon fontSize="small" />}
              </InputLabel>
              <InputField
                placeholder="patient@gmail.com"
                onChange={handleEmailChange}
                autoComplete="disabled"
                variant="outlined"
                type="email"
              />

              <ErrorMessage
                sx={{ color: !emailError ? "transparent" : "#FF0000" }}
              >
                Du måste ange en giltig mejladress.
              </ErrorMessage>
            </InputFieldWrapper>

            <InputFieldWrapper>
              <InputLabel
                sx={{ ...(personalNumberError.error && { color: "#FF0000" }) }}
              >
                {/* Patient’s social security number */}
                Patientens personnummer
                {personalNumberError.error && (
                  <ErrorOutlineIcon fontSize="small" />
                )}
              </InputLabel>
              <InputFieldWithError>
                <InputField
                  value={personalNumber}
                  onChange={handlePersonalNumberChange}
                  placeholder="YYYYMMDDNNNN"
                  autoComplete="disabled"
                  variant="outlined"
                  type="text"
                />
                <ErrorMessage
                  sx={{
                    color: !personalNumberError.error
                      ? "transparent"
                      : "#FF0000",
                  }}
                >
                  {personalNumberError.message}
                </ErrorMessage>
              </InputFieldWithError>
            </InputFieldWrapper>

            <InputFieldWrapper>
              <InputLabel sx={{ ...(nameError.error && { color: "#FF0000" }) }}>
                {/* Patient’s name */}
                Patientens namn
                {nameError.error && <ErrorOutlineIcon fontSize="small" />}
              </InputLabel>
              <InputField
                placeholder={t("Namn") as string}
                onChange={handleNameChange}
                autoComplete="disabled"
                variant="outlined"
                type="text"
              />
              <ErrorMessage
                sx={{ color: !nameError.error ? "transparent" : "#FF0000" }}
              >
                {nameError.message}
              </ErrorMessage>
            </InputFieldWrapper>

            <InputFieldWrapper>
              <InputLabel>Behandlingsmetod</InputLabel>
              <Select
                value={therapy}
                onChange={handleTherapyChange}
                sx={{
                  width: "-webkit-fill-available",
                }}
              >
                <MenuItem value={"ba"}>BA</MenuItem>
                <MenuItem value={"fact"}>FACT</MenuItem>
              </Select>
            </InputFieldWrapper>
            <Button
              sx={{
                width: "20%",
                borderRadius: "5px",
                height: "48px",
                marginTop: "2px",
                alignSelf: "flex-end",
              }}
              type="submit"
            >
              {/* Add user */}
              Lägg till
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

const Modal = styled(CustomModal)({
  width: "80%",
  margin: "0 auto",
});

const ModalHeading = styled(Typography)({
  marginTop: "12px",
  fontSize: "22px",
  fontWeight: "800",
  color: globalTextColors.PRIMARY,
  textAlign: "center",
});

const InputField = styled(TextField)({
  border: "none",
  width: "100%",
  backgroundColor: "#FFFFFF",
  fontSize: "15px",
  "& .MuiInputBase-formControl": {
    borderRadius: "2px",
    height: "48px",
  },
  input: {
    "&::placeholder": {
      color: "#000",
    },
  },
});

const InputFieldWrapper = styled(Box)({
  width: "24%",
});

const InputFieldWithError = styled(Box)({
  position: "relative",
});

const InputLabel = styled(Typography)({
  color: "#2A353F",
  fontSize: "17px",
  fontWeight: "500",
  marginBottom: "14px",
  alignItems: "center",
  display: "flex",
});

const ErrorMessage = styled(Typography)({
  fontSize: "13px",
  color: "#FF0000",
  marginTop: "12px",
});

export default AddPatientDialog;
