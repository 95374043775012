import { gql } from "@apollo/client";

export const UPDATE_MATCHING_REQUEST_STATUS = gql`
  mutation UpdateMatchingRequestStatus($id: uuid!, $status: String!) {
    update_matching_request(
      where: { id: { _eq: $id } }
      _set: { status: $status }
    ) {
      returning {
        id
        status
      }
    }
  }
`;

export default UPDATE_MATCHING_REQUEST_STATUS;
