import React from "react";

import logoIcon from "@assets/icons/Logo.svg";

import { Paper, useTheme } from "@mui/material";

/**
 * Custom loading screen component.
 *
 *
 * @beta
 */

const Pending = () => {
  const theme = useTheme();

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          padding: "12px",
          margin: "auto",
          animation:
            "breathing 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite normal",
          background: theme.palette.background.default,
          borderRadius: "10px",
          textAlign: "center",
          width: "200px",
          "@keyframes breathing": {
            "0%": {
              transform: "scale(0.9)",
            },
            "25%": {
              transform: "scale(1)",
            },
            "60%": {
              transform: "scale(0.9)",
            },
            "100%": {
              transform: "scale(0.9)",
            },
          },
        }}
      >
        <img src={logoIcon} alt="Team logo of Zeeds" />
      </Paper>
    </>
  );
};

export default Pending;
