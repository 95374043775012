import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Params } from "react-router-dom";

import { useAuth } from "@providers/AuthProvider";

import Pending from "@components/Pending";

import GET_MATCHING_REQUESTS_BY_PSYCHOLOGIST_QUERY from "@queries/get_matching_request_by_psychologist";
import GET_USERS_BY_PSYCHOLOGIST_QUERY from "@queries/get_users_by_psychologist";
import UPDATE_MATCHING_REQUEST_STATUS_QUERY from "@queries/update_matching_request_status";
import UPDATE_USER_PSYCHOLOGIST_ID_QUERY from "@queries/update_user_psychologist_id";

import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";

export interface IBehavior {
  development_area: string;

  location: string;

  miniVersion: string;

  name: string;

  performed: number;

  resources: string;

  simpleVersion: string;

  strategies: string;

  time: string;

  trigger: string;

  values: IValue[];

  weekdays: string;

  obstacle: string;

  days: any[];

  inquiryTime: string;
}

export interface IValue {
  first_comment_chosen: boolean;
  development_subareaId: number;
  second_comment_chosen: boolean;
  third_comment_chosen: boolean;
}

export interface IPartialPatient {
  email: string;

  name: string;

  psychologist: string;

  id: string;

  therapyType: string;
}

export interface IMood {
  id: number;

  inquiryTime: string;

  mood: number;

  userId: string;
}

export interface IFact {
  id: number;

  inquiryTime: string;

  factValue: number;

  userId: string;
}

export interface IDevelopmentSubarea {
  first_comment: string;

  id: number;

  second_comment: string;

  third_comment: string;

  typeName: string;
}

export interface IPatient {
  active: boolean;

  behaviors: IBehavior[];

  development_subarea: IDevelopmentSubarea[];

  email: string;

  health: number;

  healthExpected: number;

  healthStep: number;

  id: string;

  leisure: number;

  leisureExpected: number;

  leisureStep: number;

  moods: IMood[];

  name: string;

  onboardingStep: number;

  psychologist: string;

  reflectionDone: boolean;

  relationships: number;

  relationshipsExpected: number;

  relationshipsStep: number;

  work: number;

  workExpected: number;

  workStep: number;

  therapyType: string;

  facts: IFact[];
}

export interface IMatchingRequest {
  id: string;

  userId: string;

  psychologistId: string;

  message: string;

  contactEmail: string;

  contactPhone: string;

  status?: string | null;

  createdAt: string;

  user: {
    id: string;
    name: string;
    email: string; // Needed for the decryptPatientInfo function
  }[];
}

interface IPatientContext {
  activePatient: IPatient | undefined;

  addPatient: (newPatient: IPartialPatient) => Promise<unknown>;

  allPatients: IPatient[];

  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      user: IPatient[];
    }>
  >;

  removePatient: (patient: IPatient) => Promise<unknown>;

  setActivePatient: (patient: IPatient) => void;

  pendingRemovalState: [
    IPatient | undefined,
    React.Dispatch<React.SetStateAction<IPatient | undefined>>,
  ];

  getActivePatientFromURL: (params: Readonly<Params<string>>) => {
    patient: IPatient;
    needsReload: boolean;
  };

  matchingRequests: IMatchingRequest[];

  updateMatchingRequestStatus: (
    matchingRequest: Pick<IMatchingRequest, "id" | "status">,
  ) => void;

  userIdToNameMap: Record<string, string>;

  acceptNewPatient: (userId: string) => Promise<boolean>;
}

// Context
const PatientContext = React.createContext<IPatientContext>({
  allPatients: [],
  addPatient: (_newPatient: IPartialPatient) => {
    throw new Error("[PatientProvider] Method not implemented.");
  },
  removePatient: (_patient: IPatient) => {
    throw new Error("[PatientProvider] Method not implemented.");
  },
  activePatient: undefined,
  setActivePatient: (_patient: IPatient) => {
    throw new Error("[PatientProvider] Method not implemented.");
  },
  refetch: () => {
    throw new Error("[PatientProvider] Method not implemented");
  },
  pendingRemovalState: [
    undefined,
    () => {
      throw new Error("[PatientProvider] Method not implemented");
    },
  ],
  getActivePatientFromURL: () => {
    throw new Error("[PatientProvider] Method not implemented");
  },
  matchingRequests: [],
  updateMatchingRequestStatus: (_matchingRequest) => {
    throw new Error("[PatientProvider] Method not implemented");
  },
  userIdToNameMap: {},
  acceptNewPatient: (userId: string) => {
    throw new Error("[PatientProvider] Method not implemented");
  },
});

const functionsInstance = getFunctions(getApp(), "europe-central2");

// Provider
export const PatientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const activePatientState = useState<IPatient>();
  const pendingRemovalState = useState<IPatient>();

  const psychologist = useAuth()?.psychologist;
  if (!psychologist)
    throw new Error("[PatientProvider] Psychologist is undefined!");

  const { loading, error, data, refetch } = useQuery<{
    user: IPatient[];
  }>(GET_USERS_BY_PSYCHOLOGIST_QUERY, {
    variables: {
      _eq: psychologist.uid,
    },
  });

  const matchingRequestsResponse = useQuery<{
    matching_request: IMatchingRequest[];
  }>(GET_MATCHING_REQUESTS_BY_PSYCHOLOGIST_QUERY, {
    variables: {
      _eq: psychologist.uid,
    },
  });
  const [decryptedData, setDecryptedData] = useState<IPatient[] | undefined>(
    undefined,
  );
  const [userIdToNameMap, setUserIdToNameMap] = useState<
    Record<string, string>
  >({});
  const [matchingRequests, setMatchingRequests] = useState<IMatchingRequest[]>(
    [],
  );

  const [updateMatchingRequestStatusMutation] = useMutation(
    UPDATE_MATCHING_REQUEST_STATUS_QUERY,
  );

  const [updateUserPsychologistIdMutation] = useMutation(
    UPDATE_USER_PSYCHOLOGIST_ID_QUERY,
  );

  useEffect(() => {
    const decryptPatients = async () => {
      if (data) {
        console.log(`Decrypting patients...`);
        console.log(data.user);
        const httpsCallableResult = await httpsCallable<IPatient[], IPatient[]>(
          functionsInstance,
          "decryptPatientInfo",
        )(data.user);

        return httpsCallableResult.data;
      }
    };

    decryptPatients().then((data) => {
      if (data) {
        data = data.map((patient) => {
          if (!patient) return patient;

          let name = patient.name;
          if (!name || !name.length) {
            name = patient.email;
          }
          if (!name || !name.length) {
            name = "Anonym";
          }

          return {
            ...patient,
            name: name,
          };
        });
      }
      setDecryptedData(data);
    });
  }, [data]);

  useEffect(() => {
    if (matchingRequestsResponse.data?.matching_request) {
      setMatchingRequests(matchingRequestsResponse.data.matching_request);
    }
  }, [matchingRequestsResponse.data]);

  useEffect(() => {
    // We try to save the decrypted names in `userIdToNameMap` so that we can use them.
    // This way, we don't need to re-fetch them whenever we change for example
    // the status of a request.

    // 1.: Make a list of all patients who are not in the `userIdToNameMap` map
    const patientsWithoutName = matchingRequestsResponse.data?.matching_request
      .map(({ user }) => user[0])
      .filter(({ id }) => !userIdToNameMap[id]);

    if (!patientsWithoutName?.length) return;

    // 2.: Fetch the names of the patients who are not in the map
    const fetchNames = async () => {
      const httpsCallableResult = await httpsCallable<
        Pick<IPatient, "id" | "name">[],
        Pick<IPatient, "id" | "name">[]
      >(
        functionsInstance,
        "decryptPatientInfo",
      )(patientsWithoutName);

      return httpsCallableResult.data;
    };

    // 3.: Update the map with the new names
    fetchNames().then((data) => {
      setUserIdToNameMap((prev) => ({
        ...patientsWithoutName.reduce(
          (acc, { id, name }) => ({ ...acc, [id]: name ?? "Anonym" }),
          {},
        ),
        ...prev,
        ...data.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {}),
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingRequestsResponse.data]);

  if (loading || matchingRequestsResponse.loading) return <Pending />;
  if (error || matchingRequestsResponse.error)
    return (
      <pre>
        Error in GET_USERS_BY_PSYCHOLOGIST_QUERY
        {JSON.stringify(
          {
            patients: error,
            matchingRequests: matchingRequestsResponse.error,
          },
          null,
          2,
        )}
      </pre>
    );

  const addPatient = async (newPatient: IPartialPatient) => {
    return httpsCallable(
      functionsInstance,
      "createUser",
    )({
      user: newPatient,
    });
  };

  const removePatient = async (patient: IPatient) => {
    return httpsCallable(
      functionsInstance,
      "deleteUser",
    )({
      user: patient,
    });
  };

  const acceptNewPatient = async (userId: string) => {
    try {
      const { data } = await updateUserPsychologistIdMutation({
        variables: { id: userId, psychologistId: psychologist.uid },
      });

      console.log(
        "User psychologist id updated:",
        data.updateUserPsychologistId,
      );

      await refetch();

      return true;
    } catch (error) {
      console.error("Error updating user psychologist id:", error);
      return false;
    }
  };

  const updateMatchingRequestStatus = async (
    matchingRequest: Pick<IMatchingRequest, "id" | "status">,
  ) => {
    const { id, status } = matchingRequest;

    try {
      const { data } = await updateMatchingRequestStatusMutation({
        variables: { id, status },
      });

      // Avoid refetching the matching requests because this can lead to a feedback loop
      setMatchingRequests((prev) =>
        prev.map((request) =>
          request.id === id ? { ...request, status } : request,
        ),
      );

      console.log(
        "Matching request status updated:",
        data.updateMatchingRequestStatus,
      );
    } catch (error) {
      console.error("Error updating matching request status:", error);
    }
  };

  /**
   * Sets the active patient. Important to note that activePatient is a list
   * containing the useState read and write variables. 0th index for read state
   * and 1th index for write state.
   *
   * @param patient - Active patient that will have more information displayed
   * about them.
   */
  const setActivePatient = (patient: IPatient) => {
    activePatientState[1](patient);
  };

  /**
   *If this function is called in an environemnt with the
   * active patient already set then it will return that instead.
   * This function should be used in instances where the full state cannot be
   * saved across refreshes.
   *
   * @param params - Passed from child components of the Router component
   * that inherit the passed parameters on the URL e.g :user parameter in
   * the StatScreen.
   *
   * @returns IPatient object paired with a boolean dictating whether the
   * component calling this function should exit early or not.
   * If the boolean is `true` then this means the component has to exit early
   * in order to set the state of activePatient in order so that all components
   * can call it.
   */

  const getActivePatientFromURL = (params: Readonly<Params<string>>) => {
    if (activePatientState[0])
      return {
        patient: activePatientState[0],
        needsReload: false,
      };

    const activePatientName = params["patient"];

    if (!activePatientName)
      throw new Error(
        "[PatientProvider] It was not possible to retrieve the active patient!",
      );

    const activePatientMatches: any = data?.user.find(
      (user: any) => user.id === activePatientName,
    );

    const activePatient: any = { ...activePatientMatches };

    activePatient["name"] = params["name"] || "";

    if (!activePatientMatches)
      throw new Error(
        "[PatientProvider] It was not possible to retrieve the active patient!",
      );

    return {
      patient: activePatient,
      needsReload: true,
    };
  };

  // Should never run
  if (!data) throw new Error("[PatientProvider] Could not fetch patients!");

  return (
    <PatientContext.Provider
      value={{
        allPatients: decryptedData ?? [],
        addPatient,
        removePatient: removePatient,
        activePatient: activePatientState[0],
        getActivePatientFromURL,
        setActivePatient,
        refetch,
        pendingRemovalState,
        matchingRequests: matchingRequests.filter(
          (request) =>
            request.status !== "DISMISSED" && request.status !== "ACCEPTED",
        ),
        updateMatchingRequestStatus,
        userIdToNameMap,
        acceptNewPatient,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

// Consumer
export const usePatient = () => {
  return React.useContext(PatientContext);
};
