import React from "react";
import { useTranslation } from "react-i18next";

import { IMatchingRequest } from "@providers/PatientProvider";

import { globalTextColors } from "@constants/colors";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  styled,
} from "@mui/material";

const MatchingRequest = ({
  data,
  openMatchingRequest,
  deleteMatchingRequest,
}: {
  data: IMatchingRequest;
  openMatchingRequest: () => void;
  deleteMatchingRequest: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Row style={{ backgroundColor: "#ECECEC", cursor: "default" }}>
      <div
        style={{
          width: "100%",
          textDecoration: "none",
          cursor: "default",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "17px",
                color: globalTextColors.PRIMARY,
                marginRight: "20px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {t("Behandlingsintresse")}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!data.status && (
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: "green",
                    marginRight: "5px",
                  }}
                ></div>
              )}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "13px",
                  color: globalTextColors.PRIMARY,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {!data.status && t("Ny")}
                {data.status === "READ" && t("Läst")}
              </Typography>
            </div>
          </Grid>
          <Button
            onClick={openMatchingRequest}
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "5px",
              width: "auto",
              color: globalTextColors.PRIMARY,
            }}
            aria-label="read_more"
            size="medium"
          >
            {t("Läs mer")}
          </Button>
        </Grid>
      </div>

      <IconButton sx={{ cursor: "pointer" }} onClick={deleteMatchingRequest}>
        <DeleteOutlineIcon />
      </IconButton>
    </Row>
  );
};

const Row = styled(Paper)({
  backgroundColor: "white",
  borderRadius: "10px",
  marginTop: "10px",
  padding: "17px 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #BBBBBE",
  cursor: "pointer",
});

export default MatchingRequest;
