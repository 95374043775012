import React from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@components/CustomModal";

import { globalTextColors } from "@constants/colors";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  styled,
} from "@mui/material";

interface Props {
  isModalOpen: boolean;
  toggleModalVisibility: () => void;
  confirmLogout: () => void;
}

const ThankYouModal = ({
  isModalOpen,
  toggleModalVisibility,
  confirmLogout,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal open={isModalOpen}>
        <ModalContent
          elevation={3}
          sx={{
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <IconButton
            onClick={toggleModalVisibility}
            sx={{
              position: "absolute",
              fontSize: "24px",
              right: "20px",
              color: globalTextColors.PRIMARY,
            }}
          >
            <CloseIcon />
          </IconButton>
          <ModalHeading>{t("Thank you for registering!")}</ModalHeading>
          <Typography textAlign="center">{t("signupVerification")}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
            }}
          >
            <CustomButton name="logout" onClick={toggleModalVisibility}>
              Ok!
            </CustomButton>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

const Modal = styled(CustomModal)({
  width: "80%",
  margin: "0 auto",
});

const ModalContent = styled(Paper, {
  name: "form",
})(({ theme }) => ({
  width: "100%",
  borderRadius: "20px",
  padding: "20px 20px 36px 20px",
  backgroundColor: "#E1E7EC",
}));

const ModalHeading = styled(Typography)({
  marginTop: "12px",
  fontSize: "22px",
  fontWeight: "800",
  color: globalTextColors.PRIMARY,
  textAlign: "center",
});

const CustomButton = styled(Button)({
  borderRadius: "5px",
  height: "48px",
  fontSize: "15px",
  fontWeight: "600",
  width: "47%",
});

export default ThankYouModal;
