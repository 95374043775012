import {
  ButtonRow,
  CustomButton,
  Modal,
  ModalContent,
  ModalHeading,
} from "./RemovePatientDialog";
import React from "react";

import { gql, useMutation } from "@apollo/client";

const ADD_REASON_FOR_DELETION = gql`
  mutation InsertUserDeleteReason($userId: String, $reason: String) {
    insert_user_delete_reason(objects: { userId: $userId, reason: $reason }) {
      affected_rows
      returning {
        id
        userId
        reason
      }
    }
  }
`;

function PurposeOfDeletingUser({
  isVisibile,
  userId,
  toggleVisibility,
}: {
  isVisibile: boolean;
  toggleVisibility: () => void;
  userId?: string;
}) {
  const [addReason] = useMutation(ADD_REASON_FOR_DELETION);

  const handleSubmit = (value: string) => () => {
    addReason({
      variables: {
        userId,
        reason: value,
      },
    });
    toggleVisibility();
  };

  return (
    <Modal open={isVisibile}>
      <ModalContent sx={{ outline: "none" }}>
        <ModalHeading>
          What was the purpose of removing this account?
        </ModalHeading>

        <ButtonRow sx={{ width: "70%" }}>
          <CustomButton
            sx={{
              backgroundColor: "#fff",
              border: "2px solid #42446A",
              color: "#42446A",
              "&:hover": {
                opacity: "1",
                backgroundColor: "lightgray",
              },
            }}
            onClick={handleSubmit("Clinically significant change")}
          >
            Clinically significant change
          </CustomButton>
          <CustomButton
            sx={{
              backgroundColor: "#fff",
              border: "2px solid #42446A",
              color: "#42446A",
              "&:hover": {
                opacity: "1",
                backgroundColor: "lightgray",
              },
            }}
            onClick={handleSubmit("other")}
          >
            Other
          </CustomButton>
        </ButtonRow>
      </ModalContent>
    </Modal>
  );
}

export default PurposeOfDeletingUser;
