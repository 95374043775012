import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import DateComponent from "@components/Date";

import { globalTextColors } from "@constants/colors";

import { Typography } from "@mui/material";

const LnkButton = ({
  children,
  to,
}: {
  children: React.ReactNode;
  to: string;
}) => (
  <Link
    to={to}
    className="inline-flex items-center px-8 py-2 border-2 border-primary rounded-md text-primary font-semibold text-base transition duration-300 ease-in-out hover:bg-gray-300"
    style={{
      border: "2px solid #42446A",
    }}
  >
    {children}
  </Link>
);

function ManualScreen() {
  const { t } = useTranslation();
  return (
    <div className="py-48">
      <HeadingsContainer>
        <Title variant="h4">Manualer</Title>
      </HeadingsContainer>

      <SubTitle>
        Här hittar du manualer med evidensbaserade tillämpningsområden för
        Zeeds. Klicka på en manual för att läsa mer.
      </SubTitle>

      <div className="flex ">
        <div>
          <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
            Beteendeaktivering.
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
            (BA)
          </Typography>
          <LnkButton to="/manual/ba">{t("Användarmanual")}</LnkButton>
          <div>
            <b>Passar för:</b>
            <ul>
              <li>Depressionssyndrom utan komorbiditet</li>
            </ul>
            <b>Struktur:</b>
            <ul>
              <li>6 eller fler sessioner.</li>
              <li>Strukturerat behandlingsflöde.</li>
            </ul>
          </div>
        </div>

        <div>
          <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
            Focused Acceptance and Commitment Therapy.
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
            (FACT)
          </Typography>
          <LnkButton to="/manual/fact">{t("Användarmanual")}</LnkButton>
          <div>
            <b>Passar för:</b>
            <ul>
              <li>Ångest- eller depressionssyndrom med komorbiditet.</li>
              <li>Subkliniska besvär.</li>
            </ul>
            <b>Struktur:</b>
            <ul>
              <li>1 - 4 sessioner.</li>
              <li>Variabelt behandligsflöde.</li>
            </ul>
          </div>
        </div>
      </div>
      <DateComponent />
    </div>
  );
}

const HeadingsContainer = styled("div")({
  position: "relative",
});

const Title = styled(Typography)({
  color: globalTextColors.PRIMARY,
  fontWeight: "700",
});

const SubTitle = styled(Typography)({
  maxWidth: "55%",
  fontSize: "15px",
  fontWeight: "400",
  margin: "12px 0px 20px",
  color: globalTextColors.SECONDARY,
});

export default ManualScreen;
