import React from "react";

import contactUsIcon from "@assets/svgs/contact-us-image.svg";

import { globalTextColors } from "@constants/colors";

import { Box, Link, Paper, Typography, styled } from "@mui/material";

const Contact = () => {
  return (
    <Container>
      <Box>
        <Heading>Contact Zeeds</Heading>
        <ContactInfo>info@zeedsapp.com</ContactInfo>
        <ContactInfo>076-552 41 91</ContactInfo>
        <ContactInfo>
          <Link
            sx={{ color: globalTextColors.PRIMARY }}
            href="https://www.zeedsapp.com"
            target="_blank"
          >
            Zeeds
          </Link>
        </ContactInfo>
      </Box>
      <Box>
        <Image alt="contact us illustration" src={contactUsIcon} />
      </Box>
    </Container>
  );
};

const Container = styled(Paper)({
  backgroundColor: "#E7BBAE",
  borderRadius: "10px",
  width: "100%",
  padding: "2rem",
  justifyContent: "space-between",
  marginBottom: "10%",
  display: "flex",
  gap: "5x",
});

const Image = styled("img")({
  width: "325px",
  height: "auto",
  objectFit: "contain",
  marginBottom: "16px",
});

const Heading = styled(Typography)({
  fontSize: "22px",
  fontWeight: 800,
  color: globalTextColors.PRIMARY,
});

const ContactInfo = styled(Typography)({
  fontSize: "17px",
  fontWeight: 500,
  margintTop: "16px",
  color: globalTextColors.PRIMARY,
});

export default Contact;
