import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import { ReactComponent as AccordionExpand } from "@assets/icons/AccordionExpand.svg";

import {
  menuComponentsBA,
  menuComponentsFact,
} from "@constants/manualSections";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ClickAwayListener,
  Typography,
} from "@mui/material";

const AccordionSummaryStyled = styled(AccordionSummary)({
  transition: "border-radius 0.2s",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "&.Mui-expanded": {
    minHeight: "54px ",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0 !important",
  },
  background: "#E1E7EC",
  // borderRadius: "12px",
});

const AccordionStyled = styled(Accordion)({
  margin: "0 !important",
  height: "fit-content",
  background: "#E1E7EC",
  "&::before": {
    background: "transparent",
  },
});

const AccordionDetailsStyled = styled(AccordionDetails)({
  padding: "0 !important",
  background: "#E1E7EC",
});

interface AppendixProps {
  showAppendix: boolean;
  setShowAppendix: any;
  activeAppendix: any;
  setActiveAppendix: any;
  toggleModalVisibility: any;
  type: "fact" | "ba";
}

const Appendix = ({
  showAppendix,
  setShowAppendix,
  setActiveAppendix,
  activeAppendix,
  type,
}: AppendixProps) => {
  const clickedRef = React.useRef(false);
  const [expandedAccordions, setExpandedAccordions] = useState<any>([]);
  const convertCamelCaseToSpaceSeparated = (inputString: string) =>
    inputString
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());

  const menuComponents =
    type === "fact" ? menuComponentsFact : menuComponentsBA;

  useEffect(() => {
    if (clickedRef.current) {
      clickedRef.current = false;
      return;
    }
    if (activeAppendix) {
      const expandedAccordion = Object.values(menuComponents).find(
        (appendixArray: any) => {
          return appendixArray.some(
            (appendix: any) => appendix === activeAppendix,
          );
        },
      );
      setExpandedAccordions([expandedAccordion]);
    }
  }, [activeAppendix, menuComponents]);

  const handleAccordionClick = (components: any) => () => {
    if (expandedAccordions.indexOf(components) !== -1) {
      setExpandedAccordions(
        expandedAccordions.filter(
          (expandedAccordion: any) => expandedAccordion !== components,
        ),
      );
      return;
    }
    setExpandedAccordions([...expandedAccordions, components]);
  };

  return showAppendix ? (
    <ClickAwayListener
      onClickAway={() => {
        setShowAppendix(false);
      }}
    >
      <Box
        className="appendix"
        sx={{
          background: "#E1E7EC",
          position: "absolute",
          top: "40px",
          boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.25)",
          borderRadius: "0px 0px 10px 0px",
          zIndex: 1000,
        }}
      >
        {Object.entries(menuComponents).map(([title, components]: any) => {
          if (components?.[0]?.showWithoutAccordion) {
            const isComponentActive = components?.[0] === activeAppendix;
            return (
              <Box
                className="appendix-sigle-component"
                key={title}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px 16px 0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  clickedRef.current = true;
                  setActiveAppendix(components?.[0]);
                }}
              >
                <Typography
                  sx={{
                    fontWeight: isComponentActive ? "bold" : "normal",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#000000",
                    marginBottom: "8px",
                  }}
                >
                  {convertCamelCaseToSpaceSeparated(title)}
                </Typography>
                {components.map((component: any) => {
                  return component?.component;
                })}
              </Box>
            );
          }
          return (
            <>
              <AccordionStyled
                expanded={expandedAccordions.indexOf(components) !== -1}
              >
                <AccordionSummaryStyled
                  expandIcon={<AccordionExpand />}
                  onClick={handleAccordionClick(components)}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#000000",
                    }}
                  >
                    {convertCamelCaseToSpaceSeparated(title)}
                  </Typography>
                </AccordionSummaryStyled>
                <AccordionDetailsStyled>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 30px",
                    }}
                  >
                    {components.map((component: any) => {
                      const isComponentActive = component === activeAppendix;
                      const isLastComponent =
                        components.indexOf(component) === components.length - 1;
                      const isFirstComponent =
                        components.indexOf(component) === 0;

                      return (
                        <Typography
                          sx={{
                            fontWeight: isComponentActive ? "bold" : "normal",
                            fontSize: "16px",
                            lineHeight: "19px",
                            color: "#000000",
                            marginTop: isFirstComponent ? "5px" : "0px",
                            marginBottom: isLastComponent ? "10px" : "24px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            clickedRef.current = true;
                            setActiveAppendix(component);
                          }}
                          key={component?.title}
                        >
                          {component?.title}
                        </Typography>
                      );
                    })}
                  </Box>
                </AccordionDetailsStyled>
              </AccordionStyled>
            </>
          );
        })}
      </Box>
    </ClickAwayListener>
  ) : (
    <></>
  );
};

export default Appendix;
