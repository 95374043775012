import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, TextField, Typography } from "@mui/material";

interface DataUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentEmail: string;
  newEmail: string;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdate: () => void;
}

const DataUpdateModal: React.FC<DataUpdateModalProps> = ({
  isOpen,
  onClose,
  currentEmail,
  newEmail,
  onEmailChange,
  onUpdate,
}) => (
  <div
    className="modal"
    style={{
      display: isOpen ? "block" : "none",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
    }}
  >
    <div
      className="modal-content"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "450px",
        padding: "20px",
        background: "white",
        borderRadius: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={onClose} style={{ marginLeft: "auto" }}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="h6" gutterBottom>
        Uppdatera uppgifter
      </Typography>
      <div style={{ marginBottom: "16px" }}>
        <TextField
          label="Nuvarande mejl"
          margin="normal"
          value={currentEmail}
          sx={{ width: "100%", height: "40px" }}
        />
      </div>
      <div style={{ marginBottom: "16px" }}>
        <TextField
          label="Ny mejl"
          margin="normal"
          value={newEmail}
          onChange={onEmailChange}
          sx={{ width: "100%", height: "40px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onUpdate}
          sx={{
            width: "70%",
            height: "40px",
            fontSize: "12px",
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  </div>
);

export default DataUpdateModal;
