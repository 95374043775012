import React from "react";
import { useTranslation } from "react-i18next";

import { IBehavior } from "@providers/PatientProvider";

import { globalTextColors } from "@constants/colors";

import theme from "@themes/theme";

import { Box, Paper, Typography, styled } from "@mui/material";

/**
 * Behavior Ladder for any given behavior. Displays a ladder with the name, simple version and mini version of the behavior.
 *
 *
 * @param data - Behavioral data, see PatientProvider.tsx.
 *
 * @beta
 */

const BehaviorLadder = ({ data }: { data: IBehavior }) => {
  const { name, simpleVersion, miniVersion } = data;
  const { t } = useTranslation();

  return (
    <Box sx={{ position: "relative", zIndex: 0 }}>
      <Box sx={{ display: "flex", gap: "100px" }}>
        <LadderLeg />
        <LadderLeg />
      </Box>

      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          left: -55,
          top: 50,
          bottom: 50,
          width: "250px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Behavior elevation={0}>
          <BehaviorHeadline>{t("Desired Behavior")}</BehaviorHeadline>
          <BehaviorDesc>{name}</BehaviorDesc>
        </Behavior>
        <Behavior elevation={0}>
          <BehaviorHeadline>{t("Simple Behavior")}</BehaviorHeadline>
          <BehaviorDesc>{simpleVersion}</BehaviorDesc>
        </Behavior>
        <Behavior elevation={0}>
          <BehaviorHeadline>{t("Mini Version")}</BehaviorHeadline>
          <BehaviorDesc>{miniVersion}</BehaviorDesc>
        </Behavior>
      </Box>
    </Box>
  );
};

const LadderLeg = styled(Box)(({ theme }) => ({
  width: "25px",
  height: "21rem",
  background: "#D9D9D9",
  borderRadius: "10px",
}));

const Behavior = styled(Paper)(() => ({
  background: "white",
  height: "63px",
  border: "2px solid " + theme.palette.custom.darkBlueGray,
  borderRadius: "10px",
  padding: "5px 10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const BehaviorHeadline = styled(Typography)(() => ({
  fontSize: "17px",
  fontWeight: 700,
  color: globalTextColors.PRIMARY,
}));

const BehaviorDesc = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "400",
  color: globalTextColors.PRIMARY,
}));

export default BehaviorLadder;
