import * as i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

type Translation = {
  users: string;
  info: string;
  email: string;
  insights: string;
  password: string;
  login: string;
  loggingIn: string;
  loginFailed: string;
  logout: string;
  settings: string;
  createUserHeadline: string;
  createUsers: string;
  usersCreated: string;
  sendInUsers: string;
  readingFile: string;
  fileMustContain: string;
  fileEmpty: string;
  mustBeCsv: string;
  loggingOut: string;
  somethingWentWrongLogout: string;
  signupVerification: string;
  desiredBehavior: string;
  simpleBehavior: string;
  patients: string;
  cancel: string;
  wantLogOut: string;
};

const resources: {
  en: {
    translation: Translation;
  };
  sv: {
    translation: Translation;
  };
} = {
  en: {
    translation: {
      users: "Users",
      info: "Info",
      email: "Email",
      insights: "Insights",
      password: "Password",
      login: "Login",
      loggingIn: "Logging in...",
      loginFailed: "Login failed",
      logout: "Logout",
      settings: "Settings",
      createUserHeadline: "Create users",
      createUsers: "Creating users...",
      usersCreated: "Users created!",
      sendInUsers: "Send in users",
      readingFile: "Reading file ...",
      fileMustContain:
        "The file must contain the columns email, name and birthday",
      fileEmpty: "The file is empty",
      mustBeCsv: "The file must be a csv-file",
      loggingOut: "Logging out...",
      somethingWentWrongLogout: "Something went wrong while logging out",
      signupVerification:
        "Thank you for registering! An administrator will review and verify your account in the next 24 hours.\nYou will be notified via email.",
      desiredBehavior: "Desired Behavior",
      simpleBehavior: "Simple Behavior",
      patients: "Patients",
      cancel: "Cancel",
      wantLogOut: "Do you want to log out?",
    },
  },
  sv: {
    translation: {
      users: "Användare",
      info: "Info",
      insights: "Insights",
      email: "Email",
      login: "Logga in",
      loginFailed: "Inloggning misslyckades",
      logout: "Logga ut",
      loggingOut: "Loggar ut dig...",
      settings: "Inställningar",
      createUsers: "Skapar användare...",
      usersCreated: "Användare skapde!",
      sendInUsers: "Skicka in användare",
      readingFile: "Läser in fil...",
      fileMustContain:
        "Filen måste innehålla kolumnerna email, name och birthday",
      fileEmpty: "Filen är tom",
      mustBeCsv: "Måste vara en csv-fil",
      createUserHeadline: "Skapa användare",
      somethingWentWrongLogout: "Något gick fel under utloggning",
      desiredBehavior: "Förväntad Beteende",
      simpleBehavior: "Enkel Beteende",
      patients: "Patienter",
      loggingIn: "Loggar in",
      password: "Lösenord",
      signupVerification:
        "Tack för registreringen med Zeeds! Vi återkommer så fort vi verifierar ditt konto. Du kommer att få ett mejl när verifieringsprocessen utförts.",
      cancel: "Avbryt",
      wantLogOut: "Vill du logga ut?",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    lng: "sv",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
