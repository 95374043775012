import React from "react";

import { Box, styled } from "@mui/material";

/**
 * Fade in animation for screens
 *
 *
 * @beta
 */

const Wrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  "@media (prefers-reduced-motion: no-preference)": {
    animationName: "fadeIn",
    animationFillMode: "backwards",
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));

const FadeIn = ({
  duration = 300,
  delay = 0,
  children,
}: {
  duration: number;
  delay: number;
  children: React.ReactNode;
}) => {
  return (
    <Wrapper
      style={{
        animationDuration: duration + "ms",
        animationDelay: delay + "ms",
      }}
    >
      {children}
    </Wrapper>
  );
};

export default FadeIn;
