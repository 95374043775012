import { BehaviorHeadline, BehaviorInfo } from "./Behaviors";
import styled from "@emotion/styled";
import React, { useState } from "react";

import { IBehavior } from "@providers/PatientProvider";

import { globalTextColors } from "@constants/colors";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Stack, Typography } from "@mui/material";

const relationshipTypes = ["FAMILY", "LOVE", "FRIENDS"];
const healthTypes = ["NUTRITION", "SLEEP", "EXERCISE"];
const leisureTypes = ["INTERESTS", "TIME_WITH_OTHER", "TIME_ALONE"];
const workTypes = ["WORK", "EDUCATION", "FINANCIAL_SECURITY"];

const domainSelector = (subareaType: string) => {
  switch (subareaType) {
    case "FAMILY":
    case "LOVE":
    case "FRIENDS":
      return "Relationships";

    case "NUTRITION":
    case "SLEEP":
    case "EXERCISE":
      return "Health";

    case "INTERESTS":
    case "TIME_WITH_OTHER":
    case "TIME_ALONE":
      return "Leisure";

    case "EDUCATION":
    case "FINANCIAL_SECURITY":
    case "WORK":
      return "Work";

    default:
      return "Relationships";
  }
};

const backgroundSelector = (subareaType: string) => {
  const domain = domainSelector(subareaType);

  switch (domain) {
    case "Relationships":
      return "linear-gradient(94.22deg, rgba(254, 161, 180, 0.8) 0%, rgba(253, 192, 204, 0.8) 99.98%)";
    case "Health":
      return "linear-gradient(94.22deg, rgba(217, 150, 131, 0.8) 0%, rgba(250, 199, 178, 0.8) 99.98%)";
    case "Leisure":
      return "linear-gradient(94.22deg, rgba(131, 161, 121, 0.8) 0%, rgba(171, 191, 164, 0.8) 99.98%)";
    case "Work":
      return "linear-gradient(94.22deg, rgba(165, 181, 196, 0.8) 0%, rgba(194, 204, 215, 0.8) 99.98%)";
    default:
      return "linear-gradient(94.22deg, rgba(254, 161, 180, 0.8) 0%, rgba(253, 192, 204, 0.8) 99.98%)";
  }
};

function AssociatedBehaviorValues({ behavior }: { behavior: IBehavior }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const capitalizeFirstLetter = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1);
  };

  const mapValueTypes = (types: string[], currentBehavior: string) => {
    return types.map((_behavior: string) => (
      <Typography
        key={_behavior}
        sx={{
          opacity: currentBehavior !== _behavior ? "0.5" : "1",
        }}
        fontWeight="500"
        fontSize="17px"
      >
        {capitalizeFirstLetter(_behavior)}
      </Typography>
    ));
  };

  return (
    <>
      {!!behavior?.values &&
        behavior?.values?.map((value: any) => (
          <InnerBehaviourCard key={value} onClick={toggleIsOpen}>
            <BehaviorInfo
              sx={{
                background: backgroundSelector(
                  value?.development_subarea?.typeName,
                ),
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "80%" }}
              >
                <Stack flexDirection="row" alignItems="center">
                  <ChevronRightIcon
                    sx={{
                      color: globalTextColors.PRIMARY,
                      transform: isOpen ? "rotate(90deg)" : "",
                      transition: "0.3s",
                      marginRight: "0.5rem",
                    }}
                  />

                  <BehaviorHeadline>
                    Values associated with behavior
                  </BehaviorHeadline>
                </Stack>
                <Stack
                  sx={{ color: globalTextColors.PRIMARY, width: "30%" }}
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  {relationshipTypes.includes(
                    value?.development_subarea?.typeName,
                  ) && (
                    <>
                      {mapValueTypes(
                        relationshipTypes,
                        value?.development_subarea?.typeName,
                      )}
                    </>
                  )}
                  {healthTypes.includes(
                    value?.development_subarea?.typeName,
                  ) && (
                    <>
                      {mapValueTypes(
                        healthTypes,
                        value?.development_subarea?.typeName,
                      )}
                    </>
                  )}
                  {leisureTypes.includes(
                    value?.development_subarea?.typeName,
                  ) && (
                    <>
                      {mapValueTypes(
                        leisureTypes,
                        value?.development_subarea?.typeName,
                      )}
                    </>
                  )}
                  {workTypes.includes(value?.development_subarea?.typeName) && (
                    <>
                      {mapValueTypes(
                        workTypes,
                        value?.development_subarea?.typeName,
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
            </BehaviorInfo>

            {isOpen && (
              <Box
                sx={{
                  display: "flex",
                  background: "rgba(255,255,255,0.5)",
                  fontSize: "1em",
                  padding: "1rem 2rem",
                  color: globalTextColors.PRIMARY,
                }}
              >
                <Typography
                  sx={{ width: "20%", textAlign: "center", fontWeight: "600" }}
                >
                  {capitalizeFirstLetter(value?.development_subarea?.typeName)}
                </Typography>
                <Stack sx={{ width: "80%" }}>
                  <Typography sx={{ marginBottom: "1rem" }}>
                    {value?.development_subarea?.first_comment}
                  </Typography>
                  <Typography sx={{ marginBottom: "1rem" }}>
                    {value?.development_subarea?.second_comment}
                  </Typography>
                  <Typography sx={{ marginBottom: "1rem" }}>
                    {value?.development_subarea?.third_comment}
                  </Typography>
                </Stack>
              </Box>
            )}
          </InnerBehaviourCard>
        ))}
    </>
  );
}

const InnerBehaviourCard = styled(Box)({
  margin: "1rem 2rem 2rem",
  borderRadius: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "pointer",
  background: "transparent",
  overflow: "hidden",
});

export default AssociatedBehaviorValues;
