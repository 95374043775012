import React from "react";

import {
  IBehavior,
  IFact,
  IMood,
  IPatient,
  usePatient,
} from "@providers/PatientProvider";

import { ReactComponent as FreetimeIcon } from "@assets/icons/Freetime.svg";
import { ReactComponent as HealthIcon } from "@assets/icons/Health.svg";
import { ReactComponent as RelationIcon } from "@assets/icons/Relations.svg";
import { ReactComponent as WorkIcon } from "@assets/icons/Work.svg";

import Behaviors from "@components/Behaviors";
import Chart from "@components/Chart";
import DateComponent from "@components/Date";
import Values from "@components/Values";

import { globalTextColors } from "@constants/colors";

import { Box, Typography, styled, useTheme } from "@mui/material";

const findValues = (activePatient: IPatient, value: string): number => {
  return activePatient?.development_subarea.findIndex(
    (subarea) => subarea?.typeName === value,
  );
  // Todo: fix this function so it becomes readable and can be properly tested
  // throw new Error("[StatScreen] check this function, no idea what it does");
};

const Overview = () => {
  const theme = useTheme();
  const { activePatient } = usePatient();

  if (!activePatient)
    throw new Error("[Overview] Active patient is not defined!");

  const sortObjectKeys = (obj: any) =>
    Object.fromEntries(Object.entries(obj).sort());

  const getWeekNumber = (moodDate: any) => {
    const date: any = new Date(moodDate);
    const oneJan: any = new Date(2005, 0, 3);
    const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);

    return weekNumber;
  };

  const getMoodByWeeks = (moods: Array<IMood>) => {
    const moodsByWeek = moods?.reduce((weeklyMood: any, mood: any) => {
      const weekNumber = getWeekNumber(mood.inquiryTime);

      const weekKey = `week${weekNumber}`;

      if (!weeklyMood?.[weekKey])
        weeklyMood[weekKey] = { moods: [], moodsSum: 0 };

      weeklyMood?.[weekKey]?.moods.push(mood);

      weeklyMood[weekKey].moodsSum = weeklyMood[weekKey].moodsSum + mood.mood;

      return weeklyMood;
    }, {});

    return moodsByWeek;
  };

  const getFACTByWeeks = (facts: Array<IFact>) => {
    const factsByWeek = facts?.reduce((weeklyFACT: any, fact: any) => {
      const weekNumber = getWeekNumber(fact.inquiryTime);

      const weekKey = `week${weekNumber}`;

      if (!weeklyFACT?.[weekKey])
        weeklyFACT[weekKey] = { facts: [], factsSum: 0 };

      weeklyFACT?.[weekKey]?.facts.push(fact);

      weeklyFACT[weekKey].factsSum =
        weeklyFACT[weekKey].factsSum + fact.factValue;

      return weeklyFACT;
    }, {});

    return factsByWeek;
  };

  const getFrequenciesByWeek = (frequncies: Array<IBehavior>) => {
    const frequenciesByWeek = frequncies?.reduce(
      (weeklyFrequency: any, frequency: any) => {
        const weekNumber = getWeekNumber(frequency.inquiryTime);

        const weekKey = `week${weekNumber}`;

        if (!weeklyFrequency?.[weekKey])
          weeklyFrequency[weekKey] = { frequencies: [], frequenciesSum: 0 };

        weeklyFrequency?.[weekKey]?.frequencies.push(frequency);
        weeklyFrequency[weekKey].frequenciesSum =
          weeklyFrequency[weekKey].frequenciesSum + frequency.performed;

        return weeklyFrequency;
      },
      {},
    );

    return frequenciesByWeek;
  };

  const frequenciesByWeek: any = sortObjectKeys(
    getFrequenciesByWeek(activePatient?.behaviors),
  );
  const moodsByWeeks: any = sortObjectKeys(
    getMoodByWeeks(activePatient?.moods),
  );
  const FACTByWeeks: any = sortObjectKeys(getFACTByWeeks(activePatient?.facts));

  const loveNumber = findValues(activePatient, "LOVE");
  const friendNumber = findValues(activePatient, "FRIENDS");
  const familyNumber = findValues(activePatient, "FAMILY");

  const nutritionNumber = findValues(activePatient, "NUTRITION");
  const sleepNumber = findValues(activePatient, "SLEEP");
  const exerciseNumber = findValues(activePatient, "EXERCISE");

  const interestsNumber = findValues(activePatient, "INTERESTS");
  const timeWithOtherNumber = findValues(activePatient, "TIME_WITH_OTHERS");
  const timeAloneNumber = findValues(activePatient, "TIME_ALONE");

  const workNumber = findValues(activePatient, "WORK");
  const educationNumber = findValues(activePatient, "EDUCATION");
  const securityNumber = findValues(activePatient, "FINANCIAL_SECURITY");

  const getTotalBehaviorFrequencies = activePatient?.behaviors?.length
    ? activePatient?.behaviors
        ?.map((b) => b?.performed)
        ?.reduce((a, b) => a + b)
    : 0;

  return (
    <>
      <DateComponent />
      <Box
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
          right: "130px",
        }}
      >
        <ContentContainer>
          <Box
            sx={{
              widht: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ContentContainerHeading>Beteenden</ContentContainerHeading>
            <Typography
              fontSize="17px"
              fontWeight="700"
              color={globalTextColors.PRIMARY}
            >
              {/* Frequency this week: {getTotalBehaviorFrequencies} */}
              Frekvens denna vecka: {getTotalBehaviorFrequencies}
            </Typography>
          </Box>
          <Behaviors />
        </ContentContainer>

        <ContentContainer>
          <ContentContainerHeading sx={{ marginBottom: "1rem" }}>
            Värderingar
          </ContentContainerHeading>

          <Values
            data={[
              {
                title: "Relationer",
                icon: (
                  <RelationIcon
                    fill={theme.palette.custom.dark}
                    height={60}
                    width={60}
                  />
                ),
                background:
                  "linear-gradient(94.22deg, rgba(254, 161, 180, 0.8) 0%, rgba(253, 192, 204, 0.8) 99.98%)",
                linerization: activePatient.relationships,
                importance: activePatient.relationshipsExpected,
                content: [
                  {
                    headline: "Kärlek",
                    text: [
                      activePatient?.development_subarea[loveNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[loveNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[loveNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Vänner",
                    text: [
                      activePatient?.development_subarea[friendNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[friendNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[friendNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Familj",
                    text: [
                      activePatient?.development_subarea[familyNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[familyNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[familyNumber]
                        ?.third_comment,
                    ],
                  },
                ],
              },
              {
                title: "Hälsa",
                icon: (
                  <HealthIcon
                    fill={theme.palette.custom.dark}
                    height={60}
                    width={60}
                  />
                ),
                linerization: activePatient.health,
                importance: activePatient.healthExpected,
                background:
                  "linear-gradient(94.22deg, rgba(217, 150, 131, 0.8) 0%, rgba(250, 199, 178, 0.8) 99.98%)",
                content: [
                  {
                    headline: "Kost",
                    text: [
                      activePatient?.development_subarea[nutritionNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[nutritionNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[nutritionNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Somn",
                    text: [
                      activePatient?.development_subarea[sleepNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[sleepNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[sleepNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Träning",
                    text: [
                      activePatient?.development_subarea[exerciseNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[exerciseNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[exerciseNumber]
                        ?.third_comment,
                    ],
                  },
                ],
              },
              {
                title: "Fritid",
                icon: (
                  <FreetimeIcon
                    fill={theme.palette.custom.dark}
                    height={60}
                    width={60}
                  />
                ),
                linerization: activePatient.leisure,
                importance: activePatient.leisureExpected,
                background:
                  "linear-gradient(94.22deg, rgba(131, 161, 121, 0.8) 0%, rgba(171, 191, 164, 0.8) 99.98%)",
                content: [
                  {
                    headline: "Intressen",
                    text: [
                      activePatient?.development_subarea[interestsNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[interestsNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[interestsNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Tid med andra",
                    text: [
                      activePatient?.development_subarea[timeWithOtherNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[timeWithOtherNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[timeWithOtherNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Egen tid",
                    text: [
                      activePatient?.development_subarea[timeAloneNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[timeAloneNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[timeAloneNumber]
                        ?.third_comment,
                    ],
                  },
                ],
              },
              {
                title: "Arbete",
                icon: (
                  <WorkIcon
                    fill={theme.palette.custom.dark}
                    height={60}
                    width={60}
                  />
                ),
                linerization: activePatient.work,
                importance: activePatient.workExpected,
                background:
                  "linear-gradient(94.22deg, rgba(165, 181, 196, 0.8) 0%, rgba(194, 204, 215, 0.8) 99.98%)",
                content: [
                  {
                    headline: "Arbete",
                    text: [
                      activePatient?.development_subarea[workNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[workNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[workNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Utbildning",
                    text: [
                      activePatient?.development_subarea[educationNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[educationNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[educationNumber]
                        ?.third_comment,
                    ],
                  },
                  {
                    headline: "Economy",
                    text: [
                      activePatient?.development_subarea[securityNumber]
                        ?.first_comment,
                      activePatient?.development_subarea[securityNumber]
                        ?.second_comment,
                      activePatient?.development_subarea[securityNumber]
                        ?.third_comment,
                    ],
                  },
                ],
              },
            ]}
          />
        </ContentContainer>

        <ContentContainer>
          {activePatient.therapyType === "ba" ? (
            <>
              <ContentContainerHeading>
                {/* Patient Health Questionnaire-2 */}
                Patient Health Questionnaire-2
              </ContentContainerHeading>

              <ul
                style={{ paddingLeft: "1rem", color: globalTextColors.PRIMARY }}
              >
                <li>
                  En PHQ-2-poäng varierar från 0-6. En poäng på 3 är den
                  optimala cutpointen när du använder PHQ-2 för att screena för
                  depression.
                </li>
                <li>Om poängen är 3 eller högre är depression sannolikt.</li>
                <li>
                  Patienter som screenar positivt bör utvärderas ytterligare med
                  PHQ-9, andra diagnostiska instrument eller direktintervju för
                  att avgöra om de uppfyller kriterierna för depression..{" "}
                </li>
              </ul>
            </>
          ) : (
            <>
              {/* FACT */}
              <ContentContainerHeading>
                Allvarlighetsgrad av symtom
              </ContentContainerHeading>

              <ul
                style={{ paddingLeft: "1rem", color: globalTextColors.PRIMARY }}
              >
                <li>
                  Grafen nedan visar hur patientens skattning av
                  allvarlighetsgrad av symtom, samvarierar med patientens
                  aktivitet.
                </li>
                <li>
                  En poäng på 10 indikerar fullständig begränsning, och 0
                  indikerar ingen begränsning.
                </li>
              </ul>
            </>
          )}

          <Chart
            moodsByWeeks={moodsByWeeks}
            FACTByWeeks={FACTByWeeks}
            frequenciesByWeek={frequenciesByWeek}
          />

          {activePatient.therapyType === "ba" && (
            <Typography
              fontSize="13px"
              color={globalTextColors.PRIMARY}
              textAlign="center"
              sx={{ marginTop: "2rem" }}
            >
              Kroenke K, Spitzer RL, Williams JB. The Patient Health
              Questionnaire-2: Validity of a Two-Item Depression Screener.
              Medical Care. 2003;41:1284-92.
            </Typography>
          )}
        </ContentContainer>
      </Box>
    </>
  );
};

const ContentContainer = styled(Box)({
  backgroundColor: "#F0F3F5",
  width: "100%",
  marginTop: "2rem",
  padding: "1.25rem 2.5rem",
  borderRadius: "0.75rem",
});

const ContentContainerHeading = styled(Typography)({
  fontSize: "20px",
  fontWeight: "800",
  color: globalTextColors.PRIMARY,
});

export default Overview;
