import { gql } from "@apollo/client";

export const GET_MATCHING_REQUESTS_BY_PSYCHOLOGIST = gql`
  query GetMatchingRequestsByPsychologist($_eq: String!) {
    matching_request(
      where: {
        _and: [
          { psychologistId: { _eq: $_eq } }
          {
            _or: [
              { status: { _neq: "DISMISSED" } }
              { status: { _is_null: true } }
            ]
          }
          { user: { psychologist: { _is_null: true } } }
        ]
      }
      order_by: { createdAt: desc }
    ) {
      id
      userId
      psychologistId
      message
      contactEmail
      contactPhone
      status
      createdAt
      user {
        id
        name
        email
      }
    }
  }
`;

export default GET_MATCHING_REQUESTS_BY_PSYCHOLOGIST;
