import AssociatedBehaviorValues from "./AssociatedBehaviorValues";
import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IBehavior, usePatient } from "@providers/PatientProvider";

import BehaviorLadder from "@components/BehaviorLadder";
import BehaviorText from "@components/BehaviorText";

import { globalTextColors } from "@constants/colors";

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Paper, Stack, Typography, styled } from "@mui/material";

const Behaviors = () => {
  const activePatientBehaviors = usePatient().activePatient?.behaviors;
  if (!activePatientBehaviors) {
    throw new Error("[Behaviors] behaviors is undefined!");
  }

  return (
    <>
      {activePatientBehaviors?.map((behavior, i) => (
        <Behavior data={behavior} idx={i} key={i} />
      ))}
    </>
  );
};

const getDaysBackground = (day: number) => {
  switch (day) {
    case 1:
      return "linear-gradient(91.38deg, #FCDD77 0.23%, #FAE296 100%)";
    case 2:
      return "linear-gradient(91.38deg, #C2CCD7 0.23%, #DFE4E9 100%)";
    case 3:
      return "linear-gradient(91.38deg, #DFA6A6 0.23%, #E8CCCC 100%)";
    case 4:
      return "linear-gradient(91.38deg, #ABBFA4 0.23%, #D4DDD0 100%)";
    case 5:
      return "linear-gradient(91.38deg, #B2B2C2 0.23%, #D7D7DF 100%)";
    case 6:
      return "linear-gradient(91.38deg, #C2CCD7 0.23%, #DFE4E9 100%)";
    case 7:
      return "linear-gradient(91.38deg, #FDC0CC 0.23%, #FDDEE4 100%)";
    default:
      return "linear-gradient(91.38deg, #FCDD77 0.23%, #FAE296 100%)";
  }
};

const Behavior = ({ data, idx }: { data: IBehavior; idx: number }) => {
  const { name, performed, days } = data;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  return (
    <BehaviorCard open={open}>
      <BehaviorInfo onClick={toggle}>
        <Stack flexDirection="row" alignItems="center">
          <ChevronRightIcon
            sx={{
              color: globalTextColors.PRIMARY,
              transform: open ? "rotate(90deg)" : "",
              transition: "0.3s",
              marginRight: "0.5rem",
            }}
          />
          <BehaviorHeadline>{t(name)}</BehaviorHeadline>
        </Stack>
        <BehaviorHeadline>
          {t("Frequency")}: {performed}
        </BehaviorHeadline>
      </BehaviorInfo>

      {open && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "2rem 0px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <BehaviorText
                data={{
                  location: data.location,
                  resources: data.resources,
                  trigger: data.trigger,
                  strategies: data.obstacle,
                  supportive: data.strategies,
                }}
              />
            </div>
            <BehaviorLadder data={data} />
            <BehaviorTime>
              <Typography fontSize="17px" fontWeight="700">
                Time for behavior
              </Typography>

              {days?.map((day) => (
                <TimeBox
                  sx={{
                    background: getDaysBackground(day.day),
                  }}
                  key={day.day}
                >
                  <Typography fontWeight="700">
                    {daysArr[day.day - 1]}
                  </Typography>
                  <AccessTimeOutlinedIcon fontSize="small" />
                  <Typography fontWeight="700">{day?.time}</Typography>
                </TimeBox>
              ))}
            </BehaviorTime>
          </div>
          {data?.values?.length ? (
            <AssociatedBehaviorValues behavior={data} />
          ) : (
            <></>
          )}
        </>
      )}
    </BehaviorCard>
  );
};

const BehaviorTime = styled(Box)({
  width: "20%",
  backgroundColor: "#fff",
  borderRadius: "0.75rem",
  color: globalTextColors.PRIMARY,
  padding: "1rem",
  maxHeight: "13rem",
});

const TimeBox = styled(Box)({
  marginTop: "0.75rem",
  width: "100%",
  borderRadius: "0.75rem",
  padding: "1rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const BehaviorHeadline = styled(Typography)(({ theme }) => ({
  fontSize: "17px",
  fontWeight: "500",
  color: globalTextColors.PRIMARY,
}));

export const BehaviorInfo = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.5rem 2rem",
  background:
    "linear-gradient(94.22deg, rgba(165, 181, 196, 0.8) 0%, rgba(194, 204, 215, 0.8) 99.98%)",
}));

export const BehaviorCard = styled(Paper, {
  shouldForwardProp: (props) => props !== "open",
})<{
  open: boolean;
}>(({ open, theme }) => ({
  width: "100%",
  borderRadius: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "pointer",
  border: "1px solid #A6B6C5",
  background: "transparent",
  overflow: "hidden",
  marginTop: "1rem",
}));

const daysArr = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default Behaviors;
