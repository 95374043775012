import { ReactElement } from "react";
import React from "react";

import { usePatient } from "@providers/PatientProvider";

import User from "@components/User";

import { Box } from "@mui/material";

const Users = ({
  filter,
  setShowRemovePatientDialog,
}: {
  filter: string;
  setShowRemovePatientDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const allPatients = usePatient().allPatients;

  const userComponents = allPatients.reduce<ReactElement[]>(
    (arr, patient, index) => {
      const { email, name } = patient;

      if (filter === "") {
        arr.push(
          <User
            patient={patient}
            key={index}
            setShowRemovePatientDialog={setShowRemovePatientDialog}
          />,
        );
      } else if (
        email.toLowerCase().startsWith(filter.toLowerCase()) ||
        name.toLowerCase().startsWith(filter.toLowerCase())
      ) {
        arr.push(
          <User
            patient={patient}
            key={index}
            setShowRemovePatientDialog={setShowRemovePatientDialog}
          />,
        );
      }
      return arr;
    },
    [],
  );

  return <Box sx={{ paddingRight: "100px" }}>{userComponents}</Box>;
};

export default Users;
