import React, { useState } from "react";

import { Typography } from "@mui/material";

function DateComponent() {
  const [currentDate] = useState<string>(getCurrentDate());

  function getCurrentDate() {
    return new Date().toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  return (
    <Typography
      variant="body1"
      sx={{
        fontSize: "20px",
        fontWeight: "bold",
        color: "#CE766D",
        position: "absolute",
        marginTop: "10px",
        marginLeft: "300px",
        top: "60px",
        right: "130px",
      }}
    >
      {currentDate}
    </Typography>
  );
}

export default DateComponent;
