import AddPatientDialog from "./AddPatientDialog";
import AddPhoneNumberDialog from "./AddPhoneNumberDialog";
import MatchingRequestDialog from "./MatchingRequestDialog";
import MatchingRequests from "./MatchingRequests";
import RemovePatientDialog from "./RemovePatientDialog";
import { multiFactor } from "firebase/auth";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@providers/AuthProvider";
import { IMatchingRequest } from "@providers/PatientProvider";
import SnackbarProvider, { useSnackbar } from "@providers/SnackbarProvider";

import DateComponent from "@components/Date";
import Users from "@components/Users";

import { globalTextColors } from "@constants/colors";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, TextField, Typography, styled } from "@mui/material";

const Upload = () => {
  const psychologist = useAuth()?.psychologist;

  if (!psychologist)
    throw new Error("[Upload] Auth provider is not initialized!");

  const [filter, setFilter] = useState("");
  const [showAddPatientDialog, setShowAddPatientDialog] = useState(false);
  const [showRemovePatientDialog, setShowRemovePatientDialog] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [personalNumber, setPersonalNumber] = useState("");
  const [selectedMatchingRequest, setSelectedMatchingRequest] =
    useState<IMatchingRequest | null>(null);
  const { t } = useTranslation();
  const [isAddPhoneNumberDialogVisible, setAddPhoneNumberDialogVisiblity] =
    useState(false);
  const snackbar = useSnackbar();

  const handleShowAddPatientDialog = useCallback(async () => {
    const mfSession = await multiFactor(psychologist).enrolledFactors;
    if (mfSession.length === 0) {
      snackbar.setSnackbarMessage(
        "Du måste först lägga till ditt telefonnummer i din profil",
      );
      setAddPhoneNumberDialogVisiblity(true);
    } else {
      setShowAddPatientDialog(true);
    }
  }, [psychologist, snackbar]);

  const handleSearch = (e: React.KeyboardEvent) => {
    setFilter((e.target as HTMLInputElement).value);
  };
  return (
    <>
      <DateComponent />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "170px",
          alignItems: "center",
          padding: "0px 100px 0px 0px",
          justifyContent: "space-between",
        }}
      >
        <InputFieldWrapper>
          <InputField
            variant="outlined"
            type="text"
            placeholder={t("Sök efter namn eller mejl") as string}
            onKeyUp={handleSearch}
          />
          <SearchIcon
            sx={{
              position: "absolute",
              right: "14px",
              color: globalTextColors.PRIMARY,
            }}
            fontSize="large"
          />
        </InputFieldWrapper>
        <Box />
        <Button
          size="large"
          sx={{
            width: "202px",
            height: "48px",
            borderRadius: "2px",
            fontSize: "15px",
            fontWeight: "600",
            color: "background.paper",
            marginTop: "200px",
          }}
          onClick={handleShowAddPatientDialog}
        >
          <AddIcon sx={{ marginRight: "15px" }} /> Lägg till patient
        </Button>
      </Box>

      <Typography
        variant="h4"
        sx={{ color: globalTextColors.PRIMARY, fontWeight: "700" }}
      >
        Patienter
      </Typography>

      <Typography
        sx={{
          maxWidth: "55%",
          fontSize: "15px",
          fontWeight: "400",
          margin: "12px 0px 20px",
          color: globalTextColors.SECONDARY,
        }}
      >
        Klicka Lägg till patient för att lägga till din första patient.
      </Typography>

      <Box
        sx={{
          marginLeft: "30px",
          display: "grid",
          gridTemplateColumns: "1fr 1.5fr 100px 450px",
        }}
      >
        <ListItemText>{t("Namn")}</ListItemText>
        <ListItemText>{t("Aktiva Beteenden")}</ListItemText>
        <ListItemText>{t("Mående")}</ListItemText>
      </Box>

      <Box sx={{ width: "100%", height: "50%", overflow: "scroll" }}>
        <Users
          filter={filter}
          setShowRemovePatientDialog={setShowRemovePatientDialog}
        />
        {filter.length === 0 && (
          <MatchingRequests openMatchingRequest={setSelectedMatchingRequest} />
        )}
      </Box>
      <SnackbarProvider snackbarDuration={5000}>
        <AddPhoneNumberDialog
          isModalVisible={isAddPhoneNumberDialogVisible}
          setModalVisibility={setAddPhoneNumberDialogVisiblity}
        />
        <AddPatientDialog
          email={email}
          name={name}
          personalNumber={personalNumber}
          setEmail={setEmail}
          setName={setName}
          setPersonalNumber={setPersonalNumber}
          setShowAddPatientDialog={setShowAddPatientDialog}
          showAddPatientDialog={showAddPatientDialog}
        />
        <RemovePatientDialog
          setShowRemovePatientDialog={setShowRemovePatientDialog}
          showRemovePatientDialog={showRemovePatientDialog}
        />
        <MatchingRequestDialog
          matchingRequest={selectedMatchingRequest}
          setMatchingRequest={setSelectedMatchingRequest}
        />
      </SnackbarProvider>
    </>
  );
};

const InputField = styled(TextField)({
  border: "none",
  width: "100%",

  fontSize: "15px",
  "& .MuiInputBase-formControl": {
    height: "48px",
    borderRadius: "2px",
  },
  input: {
    "&::placeholder": {
      color: "#000",
    },
  },
});

const InputFieldWrapper = styled("div")({
  width: "424px",
  position: "relative",
  display: "flex",
  alignItems: "center",
});

const ListItemText = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  fontSize: "15px",
  color: "#000",
  marginRight: "16px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export default Upload;
