import { Link, styled } from "@mui/material";

const LinkButton = styled(Link)({
  color: "#42446A",
  border: "2px solid #42446A",
  display: "flex",
  alightItems: "center",
  padding: "10px 30px",
  cursor: "pointer",
  fontSize: "15px",
  textDecorationColor: "#42446A",
  borderRadius: "5px",
  borderColor: "#42446A",
  fontWeight: "600",

  "&:hover": {
    backgroundColor: "lightgray",
  },
});
export default LinkButton;
