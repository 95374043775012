import * as React from "react";
import DropdownList from "react-widgets/DropdownList";
import "react-widgets/styles.css";

// icon:factory | Lucide https://lucide.dev/ | Lucide
function IconFactory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M2 20a2 2 0 002 2h16a2 2 0 002-2V8l-7 5V8l-7 5V4a2 2 0 00-2-2H4a2 2 0 00-2 2zM17 18h1M12 18h1M7 18h1" />
    </svg>
  );
}

const baseUrl = `/api/searchBusiness`;
const init = { method: "GET", accept: "application/json", headers: [] };

interface Results {
  query: string;
  hits: {
    regCode: string;
    name: string;
  }[];
}

const SpecializedDropdownList = ({
  setOrganization,
}: {
  setOrganization: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [busy, setBusy] = React.useState(false);
  const [data, setData] = React.useState<Results["hits"]>([]);

  return (
    <DropdownList
      data={data}
      placeholder="Organisation"
      busy={busy}
      onSearch={(searchTerm) => {
        if (searchTerm.length < 3) {
          return Promise.resolve([]);
        }

        setBusy(true);
        return fetch(`${baseUrl}?query=${searchTerm}`, init)
          .then((response) => response.json() as Promise<Results>)
          .then((json) => {
            setBusy(false);
            setData(json.hits);
          });
      }}
      onSelect={(item) => {
        setOrganization(item.regCode);
      }}
      renderListItem={({ item }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <IconFactory />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {item.name}
              <div style={{ fontSize: "0.8em", color: "gray" }}>
                Org.nr {item.regCode}
              </div>
            </div>
          </div>
        );
      }}
      filter={(item, searchTerm) => {
        return (
          item.regCode.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      }}
      renderValue={(item) => {
        return (
          <span>
            <strong>{item.item.name}</strong>
          </span>
        );
      }}
    />
  );
};

export default SpecializedDropdownList;
