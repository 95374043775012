export const faqData = [
  {
    question: "Hur ger jag en patient ett Zeeds-konto?",
    answer:
      "Du kan enkelt skapa ett patientkonto genom att först introducera Zeeds kort som en hemuppgift under en session. Och därefter skapa ett konto till patientens mejl, genom att trycka på knappen “Create-Patient” och lägga in den nödvändiga informationen. Patienten får då ett mejl med instruktioner för att ladda ner appen på App Store eller Google Play",
  },
  {
    question: "Jag vill avsluta min patients konto. Vad händer då?",
    answer:
      "När du tar bort ett patientkonto, upphör patientens tillgång till applikationen. Patientkontot tas då bort från din portal, samt all tillhörande data i portalen.",
  },
  {
    question: "Hur länge är ett patientkonto aktivt?",
    answer:
      "Du skapar själv dina konton till patienter, och använder dessa så länge ni behöver. När du bedömer att patient ej längre är behövd av Zeeds, meddelar du det till patient och  stänger ner kontot via X-knappen vid patientens profil.",
  },
  {
    question: "Hur introducerar jag Zeeds under pågående behandling?",
    answer:
      "Zeeds lämpar sig bäst som ett verktyg som introduceras efter en initial bedömning. När du registrerar dig på vår tjänst, får du en kostnadsfri användarmanual som förklarar hur Zeeds kan introduceras och följas upp på ett strukturerat sätt, under en behandling. Den kan du se under fliken “Manual”",
  },
  {
    question: "Hur lång tid tar det att gå igenom Zeeds för en patient?",
    answer:
      "Vår manual förordar att använda Zeeds i drygt 6 sessioner, i linje med ett beteendeaktiveringsprotokoll. I slutändan leds dock tiden av vårdgivarens kliniska fallkonceptualisering. De initiala värderingsövningarna tar drygt 20 minuter per område att utföra för patienten. Att skapa en beteendeplan i Zeeds tar ungefär 10 minuter för en patient, den första gången. Att registrera ett utfört beteende tar dock inte mer än några sekunder - det krävs endast ett klick.",
  },
  {
    question: "Vilken vetenskaplig evidens har Zeeds?",
    answer:
      "Zeeds grundar sig teoretiskt efter kognitiv beteendeterapi, och mer specifikt efter beteendeaktivering med en värderingskomponent inspirerad efter Acceptance and Commitment Therapy. Administrationen guidas efter manualen Brief Behavioral Activation Treatment of Depression-Revised (BATD-R). Denna inormation finns under fliken “Manual” i portalen. Olika analyser på Zeeds kliniska effekt har gjorts. Mejla gärna mamduh@zeedsapp.com för att ta del av mer information om det",
  },
  {
    question: "Hur hanteras mina patienters data?",
    answer:
      "Dina patienters data hanteras säkert enligt hälso- och sjukvårdslagar samt GDPR. Du kan läsa mer om Zeeds datahantering under https://www.zeedsapp.com/privacy-policy. Som vårdgivare ansvarar du för att föra journal och driva behandlingen, under den tid som Zeeds hanterar din patients data för att leverera tjänsten. Du är välkommen att kontakta oss vid frågor.",
  },
  {
    question:
      "Behöver jag informera mina patienter om hur deras data behandlas, när jag ger dom ett konto?",
    answer:
      "Ja. Du är ansvarig för att informera dina patienter om varför du administrerar Zeeds, och i vilket syfte du ser data om beteenden, måendenivå och värderingar. Du behöver också få samtycke från patient, att du kan se data om beteenden, värderingar och måendenivå i enlighet med ditt arbete. Patient har rätt att vägra att dela med sig av sin data. Mer om introduktion och rational till patient kan du se under fliken “Manual” i portalen.",
  },
  {
    question:
      "Hur vet jag att min patient faktiskt utför de beteenden som registreras?",
    answer:
      "Det är omöjligt för oss att veta huruvida en individ faktiskt utför det beteende som registreras, men det enklaste sättet att undersöka det, är att fråga under nästkommande session.",
  },
  {
    question:
      "Min patient upplever ett problem eller en bugg med sitt konto. Vad gör jag?",
    answer:
      "Om en användare upplever ett problem med sitt konto, hänvisar vi til info@zeedsapp.com. Vi svarar alltid inom 24 timmar. Inkludera inte patients direkta personuppgifter i din kommunikation med oss. Patienten kan också kontakta oss direkt genom en kontakt-knapp i applikationens meny.",
  },
  {
    question: "Jag kan inte skapa konton på Zeeds. Vad kan jag göra?",
    answer:
      "Kontrollera att du inte har skapat dubbla konton till samma person. Kontrollera att du har tillgång till internet. I vissa fall kan det även hjälpa att ladda om din webbsida då hastigheten för kontoskapande kan ta mellan 5-10 sekunder. Om en d fortsatt upplever problem, kontakta info@zeedsapp.com. Vi svarar alltid inom 24 timmar.",
  },
  {
    question:
      "Kan jag låta andra psykologer i min mottagning använda mitt Zeeds-konto?",
    answer:
      "Endast du har tillgång till din portal. Det går dock utmärkt att bjuda in kollegor, genom att låta dessa registrera sig via portalen.",
  },
  {
    question:
      "Varför behöver jag logga in med en telefonkod när jag ska administrera ett konto?",
    answer:
      "Denna process är nödvändig för att säkra att dina uppgifter i portalen endast kan nås av dig, och inte andra användare. I och med att portalen innehåller känsliga uppgifter om patient, är vi måna om att endast du har tillgång till informationen. Om du är utloggad, kommer du därför att behöva skriva in en sexsiffrig kod i samband med din inloggning. Koden skickas till din telefon.",
  },
];
