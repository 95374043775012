import { useEffect } from "react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { usePatient } from "@providers/PatientProvider";

import Overview from "@components/Overview";

import { globalTextColors } from "@constants/colors";

import WestIcon from "@mui/icons-material/West";
import { Box, Stack, Typography } from "@mui/material";

const StatScreen = () => {
  const navigate = useNavigate();
  const { setActivePatient } = usePatient();
  const { patient, needsReload } = usePatient().getActivePatientFromURL(
    useParams(),
  );

  useEffect(() => {
    if (needsReload) {
      setActivePatient(patient);
    }
  }, [needsReload, patient, setActivePatient]);

  if (needsReload) return null;

  const navigateBack = () => navigate(-1);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          color: globalTextColors.PRIMARY,
          paddingTop: "8rem",
        }}
      >
        <Stack
          onClick={navigateBack}
          flexDirection="row"
          alignItems="center"
          sx={{ width: "100%", cursor: "pointer", marginLeft: "-0.25rem" }}
        >
          <WestIcon sx={{ widht: "21px", height: "21px" }} />
          <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>
            Back
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: "34px",
            fontWeight: "700",
            color: globalTextColors.PRIMARY,
            marginTop: "2rem",
          }}
        >
          {patient?.name}
        </Typography>
      </Box>
      <Overview />
    </>
  );
};

export default StatScreen;
