import { TermsAndConditions } from "./UserManualModalSections";
import styled from "@emotion/styled";
import React from "react";

import { Box, Button } from "@mui/material";

const ConfirmationButtons = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  bottom: "0",
  width: "100%",
  height: "20%",
  position: "relative",
  alignItems: "center",

  Button: {
    borderRadius: "2px",
    width: "49%",
    height: "3rem",
    fontSize: "15px",
    fontWeight: "600",

    "&:hover": {
      opacity: "1",
    },
  },
});

const TermsAndConditionsExtend = ({ toggleModalVisibility }: any) => {
  return (
    <>
      <TermsAndConditions />
      <ConfirmationButtons>
        <Box
          sx={{
            margin: "0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem",
            position: "relative",
          }}
        >
          <Button onClick={toggleModalVisibility}>
            Jag har läst och förstått manualen
          </Button>
        </Box>
      </ConfirmationButtons>
    </>
  );
};

export default TermsAndConditionsExtend;
