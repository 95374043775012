import LogoutConfirmation from "./LogoutConfirmation";
import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

import { useAuth } from "@providers/AuthProvider";

import mainIcon from "@assets/svgs/home-screen-panel-main-image.svg";
import logoIcon from "@assets/svgs/zeeds-portal-icon.svg";

import { StandardSnackbar } from "@components/StandardSnackbar";

import { globalColor } from "@constants/colors";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PersonIcon from "@mui/icons-material/Person";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Button, Drawer, Stack, Typography, styled } from "@mui/material";

const links = [
  {
    title: "Patienter",
    link: "patients",
    svg: <PersonIcon />,
  },
  {
    title: "Information",
    link: "faq",
    svg: <HelpOutlineOutlinedIcon />,
  },
  {
    title: "Manual",
    link: "manual",
    svg: <InsertDriveFileOutlinedIcon />,
  },
  {
    title: "Inställningar",
    link: "settings", // Replace with the actual route for settings
    svg: <SettingsOutlinedIcon />, // Import the SettingsOutlinedIcon from @mui/icons-material
  },
];

const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logOut = useAuth()?.logOut;
  if (!logOut) throw new Error("[NavBar] LogOut functions is undefined!!");

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const open = true;

  const [hasLogoutConfirmed, setHasLogoutConfirmed] = useState<boolean>(false);

  const toggleLogoutConfirmationModal = () =>
    setHasLogoutConfirmed(!hasLogoutConfirmed);

  const handleLogout = async () => {
    try {
      setSnackBarMessage(t("loggingOut") as string);
      await logOut();
    } catch (e) {
      throw new Error("[NavBar] Something wen't wrong with logOut");
    }
  };

  const onLogoClick = () => navigate("/patients");

  return (
    <>
      <Sidebar variant="persistent" anchor="left" open={open}>
        <LogoWrapper onClick={onLogoClick}>
          <Logo src={logoIcon} />
        </LogoWrapper>

        <SidebarLinkWrapper>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", width: "100%" }}>
              <MainIcon src={mainIcon} />
            </Box>
            <Typography
              textAlign="center"
              sx={{ fontSize: "17px", fontWeight: "700" }}
            >
              Välkommen!
            </Typography>
            <Typography
              textAlign="center"
              sx={{ fontSize: "11px", fontWeight: "700" }}
            >
              Ha en bra dag..
            </Typography>
          </Box>

          <Stack sx={{ alignItems: "flex-end" }}>
            {links.map(({ title, svg, link }) => (
              <Link to={link} key={title}>
                {svg}
                <Typography>{title}</Typography>
              </Link>
            ))}
          </Stack>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Button
              sx={{
                borderRadius: "5px",
                width: "90%",
                margin: "0 auto",
              }}
              aria-label="logout"
              size="large"
              onClick={toggleLogoutConfirmationModal}
            >
              {t("Logga ut")}
            </Button>
          </Box>
        </SidebarLinkWrapper>
      </Sidebar>

      <LogoutConfirmation
        isModalOpen={hasLogoutConfirmed}
        toggleModalVisibility={toggleLogoutConfirmationModal}
        confirmLogout={handleLogout}
      />
      <StandardSnackbar
        msg={snackBarMessage}
        setSnackBarMessage={setSnackBarMessage}
      />
    </>
  );
};

export default NavBar;

const Link = styled(NavLink)(({ theme }) => ({
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  height: 48,
  width: 170,
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "#1C1C1D",
  margin: "7px 0",
  svg: {
    width: "20px",
    height: "20px",
    marginRight: 15,
    marginLeft: "20px",
  },
  p: {
    fontWeight: 400,
    color: "inherit",
    fontSize: 15,
  },
  "&.active": {
    backgroundColor: "#F4F4F4",
  },
  "&:hover:not(.active)": {
    backgroundColor: "#F4F4F4",
  },
}));

const Sidebar = styled(Drawer)(({ theme }) => ({
  width: 247,
  height: "100vh",
  "& .MuiDrawer-paper": {
    width: 200,
    backgroundColor: "background.paper",
    border: "none",
  },
}));

const LogoWrapper = styled("div")({
  cursor: "pointer",
  width: "100%",
  height: "200px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Logo = styled("img")(({ theme }) => ({
  width: 130,
  height: 50,
}));

const MainIcon = styled("img")({
  width: "155px",
  height: "135px",
  margin: "auto auto 16px auto",
});

const SidebarLinkWrapper = styled("div")({
  width: "100%",
  height: "100%",
  backgroundColor: globalColor.SECONDARY,
  borderTopRightRadius: "30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "36px 0 36px 0",
});
