import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import loginBgImage from "@assets/svgs/login-bg-image.svg";
import mainImage from "@assets/svgs/login-screen-main-image.svg";
import logoIcon from "@assets/svgs/zeeds-portal-icon.svg";

import DropdownList from "@components/DropdownList";
import { StandardSnackbar } from "@components/StandardSnackbar";
import ThankYouModal from "@components/ThankYouModal";

import { globalColor } from "@constants/colors";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";

const functionsInstance = getFunctions(getApp(), "europe-central2");

const SignupScreen = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");

  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false);

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const [isSamePassword, setIsSamePassword] = useState<boolean>(true);

  const [isThankYouModalOpen, setThankYouModalOpen] = useState<boolean>(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const handleEmailChange = (e: React.SyntheticEvent) => {
    setEmail((e.target as HTMLInputElement).value);
  };

  const handlePasswordChange = (e: React.SyntheticEvent) => {
    setPassword((e.target as HTMLInputElement).value);
  };

  const handleConfirmPasswordChange = (e: React.SyntheticEvent) => {
    setIsSamePassword(true);
    setConfirmPassword((e.target as HTMLInputElement).value);
  };

  const handleNameChange = (e: React.SyntheticEvent) => {
    setName((e.target as HTMLInputElement).value);
  };

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const toggleConfirmPasswordVisibilty = () =>
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);

  const signup = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (password === confirmPassword) {
      setIsSamePassword(true);
      setSnackbarMessage("Registering...");

      httpsCallable(
        functionsInstance,
        "registerUser",
      )({
        user: {
          email,
          password,
          displayName: name,
          organization,
        },
      })
        .then(() => {
          setSnackbarMessage("Registered Successfully.");
          setThankYouModalOpen(true);
        })
        .catch((error) => {
          setSnackbarMessage(error.message);
        });
    } else {
      setIsSamePassword(false);
    }
  };

  const handleThankYouModalClose = useCallback(() => {
    setThankYouModalOpen(false);
    navigate("/login");
  }, [navigate]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "background.paper",
      }}
    >
      {!isTabletOrMobile && <BackgroundImage src={loginBgImage} />}
      <ThankYouModal
        isModalOpen={isThankYouModalOpen}
        confirmLogout={() => null}
        toggleModalVisibility={handleThankYouModalClose}
      />

      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "background.paper",
          overflow: "hidden",
          padding: isTabletOrMobile ? "2rem" : "1rem 0 80px 80px",
        }}
      >
        <img
          src={logoIcon}
          style={{ width: 130, height: 50 }}
          alt="logo icon"
        />
        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexDirection={isTabletOrMobile ? "column" : "row"}
          height="100%"
          width="100%"
        >
          <img
            src={mainImage}
            style={
              isTabletOrMobile
                ? {
                    width: "50%",
                  }
                : { width: "45%", height: "auto", objectFit: "contain" }
            }
            alt="main illustration"
          />

          <form
            method="post"
            style={
              isTabletOrMobile
                ? {
                    width: "100%",
                  }
                : { width: "30%", margin: "0 auto" }
            }
          >
            <Typography
              sx={{ color: "text.primary" }}
              style={{ fontWeight: 700, fontSize: "34px" }}
            >
              Register Account
            </Typography>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "1rem",
              }}
            >
              <InputField
                variant="outlined"
                placeholder="Name"
                type="text"
                required
                onChange={handleNameChange}
                value={name}
              />

              <InputField
                variant="outlined"
                placeholder="Your Email"
                type="email"
                required
                onChange={handleEmailChange}
                value={email}
              />

              <DropdownList setOrganization={setOrganization} />

              <InputField
                variant="outlined"
                value={confirmPassword}
                required
                placeholder={t("password") as string}
                type={isConfirmPasswordVisible ? "text" : "password"}
                onChange={handleConfirmPasswordChange}
                InputProps={{
                  endAdornment: !isConfirmPasswordVisible ? (
                    <VisibilityOffIcon
                      onClick={toggleConfirmPasswordVisibilty}
                      sx={{
                        color: globalColor.GRAY,
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={toggleConfirmPasswordVisibilty}
                      sx={{
                        color: globalColor.GRAY,
                        cursor: "pointer",
                      }}
                    />
                  ),
                }}
              />

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputField
                  variant="outlined"
                  value={password}
                  required
                  placeholder={"Confirm password"}
                  type={isPasswordVisible ? "text" : "password"}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: !isPasswordVisible ? (
                      <VisibilityOffIcon
                        onClick={togglePasswordVisibility}
                        sx={{
                          color: globalColor.GRAY,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={togglePasswordVisibility}
                        sx={{
                          color: globalColor.GRAY,
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                />
              </Box>
            </div>

            <Typography
              fontSize={"0.75rem"}
              color={!isSamePassword ? "red" : "transparent"}
            >
              Password and confirm password doesn{"'"}t match
            </Typography>

            <Button
              type="submit"
              size="large"
              sx={{
                marginTop: "1rem",
                borderRadius: "2px",
                color: "background.paper",
              }}
              onClick={signup}
            >
              Sign Up
            </Button>

            <Typography
              textAlign="center"
              fontSize="0.8rem"
              sx={{ padding: "0.75rem 1rem", zIndex: 1, position: "relative" }}
            >
              By clicking “Sign Up” above, you acknowledge that you have read
              and understood, and agree to Zeeds{"'"}s{" "}
              <Link href="https://www.zeedsapp.com/terms-and-conditions">
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link href="https://www.zeedsapp.com/privacy-policy">
                Privacy Policy
              </Link>
            </Typography>

            <p style={{ textAlign: "center" }}>
              Already have an account?{" "}
              <Link
                sx={{
                  position: "relative",
                  zIndex: 2,
                }}
                href="/login"
              >
                Login
              </Link>
            </p>
          </form>

          <StandardSnackbar
            msg={snackbarMessage}
            setSnackBarMessage={setSnackbarMessage}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default SignupScreen;

const BackgroundImage = styled("img")({
  width: "50%",
  heigth: "100%",
  position: "absolute",
  objectFit: "contain",
  right: 0,
  zIndex: 0,
});

const InputField = styled(TextField)({
  border: "none",
  width: "100%",
  "& .MuiInputBase-formControl": {
    borderRadius: "2px",
  },
  input: {
    "&::placeholder": {
      color: "#000",
    },
  },
});
