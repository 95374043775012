import { FAQ } from "./Info";
import { useState } from "react";
import React from "react";

import { globalTextColors } from "@constants/colors";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Paper, Typography, styled } from "@mui/material";

interface Props {
  questions: FAQ[];
}
const Faq = ({ questions }: Props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          padding: "20px",
          borderRadius: "10px",
          background: "#C3CED8",
        }}
      >
        {questions?.map(({ question, answer }, index) => (
          <QA question={question} answer={answer} key={index} />
        ))}
      </Box>
    </>
  );
};

const QA = ({ question, answer }: { question: string; answer: string }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevOpen) => !prevOpen);

  return (
    <Paper
      sx={{
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "transparent",
        cursor: "pointer",
      }}
      onClick={toggle}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ChevronRightIcon
          fontSize="small"
          style={{
            color: globalTextColors.PRIMARY,
            transform: open ? "rotate(90deg)" : "",
            transition: "0.2s",
          }}
        />
        <Typography
          sx={{
            fontSize: "17px",
            fontWeight: "500",
            marginLeft: "1rem",
            color: globalTextColors.PRIMARY,
          }}
        >
          {question}
        </Typography>
      </Box>
      {open && <Answer>{answer}</Answer>}
    </Paper>
  );
};

const Answer = styled(Typography)({
  fontSize: "15px",
  fontWeight: "400",
  color: globalTextColors.PRIMARY,
  transition: "1s",
  background: "#E1E7EC",
  borderRadius: "10px",
  width: "90%",
  marginLeft: "2rem",
  padding: "30px 20px",
  margin: "1.25rem 0px 0px 2rem",
});

export default Faq;
