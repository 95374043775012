export const globalColor = {
  PRIMARY: "#C25435",
  SECONDARY: "#A6B6C5",
  GRAY: "#A5A5A9",
};

export const globalTextColors = {
  PRIMARY: "#1C1C1D",
  SECONDARY: "#727276",
  ACCENT_COLOR: "#C25435",
};
