import React, { useState } from "react";

import { StandardSnackbar } from "@components/StandardSnackbar";

const SnackbarContext = React.createContext<{
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  snackbarDuration?: number;
}>({
  setSnackbarMessage: () => {
    throw new Error(`[SnackbarProvider] setSnackbarMessage not implemented!`);
  },
});

const SnackbarProvider = ({
  snackbarDuration = 3000,
  children,
}: {
  snackbarDuration?: number;
  children: React.ReactNode;
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");

  return (
    <SnackbarContext.Provider
      value={{
        setSnackbarMessage,
        snackbarDuration,
      }}
    >
      {children}
      <StandardSnackbar
        msg={snackbarMessage}
        setSnackBarMessage={setSnackbarMessage}
        messageTime={snackbarDuration}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return React.useContext(SnackbarContext);
};

export default SnackbarProvider;
