import AuthActions from "./AuthActions";
import AuthRoute from "./AuthRoute";
import AuthUserActions from "./AuthUserActions";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { AuthProvider } from "@providers/AuthProvider";

import ForgotPassword from "@screens/ForgotPasswordScreen";
import HomeScreen from "@screens/HomeScreen";
import LoginScreen from "@screens/LoginScreen";
import PasswordReset from "@screens/PasswordResetScreen";
import SignupScreen from "@screens/SignupScreen";

import theme from "@themes/theme";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

/**
 * Startup of the App. Starts apollo-client and defines the first routes.
 *
 *
 *
 * @beta
 */

// Development environment check
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.REACT_APP_HASURA_URI,
    headers: {
      "content-type": "application/json",
      "x-hasura-admin-secret": process.env.REACT_APP_X_HASURA_ADMIN_SECRET,
    },
  }),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <Routes>
              <Route
                path="/*"
                element={
                  <AuthRoute>
                    <HomeScreen />
                  </AuthRoute>
                }
              />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/signup" element={<SignupScreen />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route
                path="auth/action"
                element={
                  <AuthActions>
                    <AuthUserActions />
                  </AuthActions>
                }
              />
            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
}

export default App;
