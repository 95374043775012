import React from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@components/CustomModal";

import { globalTextColors } from "@constants/colors";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  styled,
} from "@mui/material";

interface Props {
  isModalOpen: boolean;
  toggleModalVisibility: () => void;
  confirmLogout: () => void;
}

const LogoutConfirmation = ({
  isModalOpen,
  toggleModalVisibility,
  confirmLogout,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal open={isModalOpen} onClose={toggleModalVisibility}>
        <ModalContent elevation={3}>
          <IconButton
            onClick={toggleModalVisibility}
            sx={{
              position: "absolute",
              fontSize: "24px",
              right: "20px",
              color: globalTextColors.PRIMARY,
            }}
          >
            <CloseIcon />
          </IconButton>
          <ModalHeading>{t("wantLogOut")}</ModalHeading>
          <ButtonRow>
            <CustomButton name="logout" onClick={confirmLogout}>
              {t("logout")}
            </CustomButton>
            <CustomButton
              sx={{
                backgroundColor: "#fff",
                border: "2px solid #42446A",
                color: "#42446A",
              }}
              onClick={toggleModalVisibility}
            >
              {t("cancel")}
            </CustomButton>
          </ButtonRow>
        </ModalContent>
      </Modal>
    </>
  );
};

const Modal = styled(CustomModal)({
  width: "80%",
  margin: "0 auto",
});

const ModalContent = styled(Paper, {
  name: "form",
})(({ theme }) => ({
  width: "100%",
  borderRadius: "20px",
  padding: "20px 20px 36px 20px",
  backgroundColor: "#E1E7EC",
}));

const ModalHeading = styled(Typography)({
  marginTop: "12px",
  fontSize: "22px",
  fontWeight: "800",
  color: globalTextColors.PRIMARY,
  textAlign: "center",
});

const ButtonRow = styled(Box)(() => ({
  width: "25%",
  marginTop: "36px",
  display: "flex",
  margin: "36px auto 0",
  alignItems: "center",
  justifyContent: "space-between",
}));

const CustomButton = styled(Button)({
  borderRadius: "5px",
  height: "48px",
  fontSize: "15px",
  fontWeight: "600",
  width: "47%",
});

export default LogoutConfirmation;
