import app from "../firebaseSetup";
import CustomModal from "./CustomModal";
import { FirebaseError } from "firebase/app";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getAuth,
  multiFactor,
} from "firebase/auth";
import React, { useCallback, useState } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@providers/AuthProvider";
import { useSnackbar } from "@providers/SnackbarProvider";

import { globalTextColors } from "@constants/colors";

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  IconButton,
  Input,
  Link,
  Paper,
  Typography,
  styled,
} from "@mui/material";

const Modal = styled(CustomModal)({
  width: "80%",
  margin: "0 auto",
});

const ModalHeading = styled(Typography)({
  marginTop: "12px",
  fontSize: "22px",
  fontWeight: "800",
  color: globalTextColors.PRIMARY,
  textAlign: "center",
});

const AddPhoneNumberDialog = ({
  isModalVisible,
  setModalVisibility,
}: {
  isModalVisible: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const auth = getAuth(app);

  const psychologist = useAuth()?.psychologist;
  if (!psychologist)
    throw new Error("[Upload] Auth provider is not initialized!");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const navigate = useNavigate();

  const snackbar = useSnackbar();

  const handlePhoneNumber = useCallback(async () => {
    const multiFactorSession = await multiFactor(psychologist).getSession();

    // Specify the phone number and pass the MFA session.
    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multiFactorSession,
    };
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth,
    );

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    await phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => {
        snackbar.setSnackbarMessage("Koden har skickats till din telefon!");
        setVerificationId(verificationId);
      })
      .catch((error: FirebaseError) => {
        switch (error.code) {
          case "auth/missing-phone-number":
            console.log("Missing phone number!");
            break;
          case "auth/invalid-phone-number":
            console.log("Invalid phone number!");
            console.log(error.stack);
            snackbar.setSnackbarMessage(
              `${phoneInfoOptions.phoneNumber} är ett ogiltigt telefonnummer`,
            );
            break;
          case "auth/quota-exceeded":
            console.log("SMS quota exceeded!");
            break;
          default:
            console.log("Error: ", error);
        }
      });
  }, [auth, phoneNumber, psychologist, snackbar]);

  const handleVerifyCode = useCallback(async () => {
    // Obtain verification code from user.
    const phoneAuthCredential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
    const multiFactorUser = multiFactor(psychologist);
    multiFactorUser
      .enroll(multiFactorAssertion)
      .then(() => {
        snackbar.setSnackbarMessage("Telefonnummer tillagt!");
        setModalVisibility(false);
      })
      .catch((error: FirebaseError) => {
        switch (error.code) {
          case "auth/invalid-verification-code":
            snackbar.setSnackbarMessage("Ogiltig kod!");
            break;
          default:
            snackbar.setSnackbarMessage(
              `Något gick fel! Rapportera detta till supporten. Error: ${error.code}`,
            );
        }
      });
  }, [
    psychologist,
    setModalVisibility,
    snackbar,
    verificationCode,
    verificationId,
  ]);

  const handlePhoneNumberChange = useCallback(
    (
      value: string,
      data: CountryData,
      event: React.ChangeEvent<HTMLInputElement>,
      formattedValue: string,
    ) => {
      setPhoneNumber("+" + value);
    },
    [],
  );
  const closeModal = useCallback(() => {
    setModalVisibility(false);
  }, [setModalVisibility]);

  const handleReadMore = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  return (
    <>
      <Modal open={isModalVisible} onClose={closeModal}>
        <Paper<"form">
          sx={{
            width: "100%",
            borderRadius: "30px",
            padding: "20px 20px 36px 20px",
            backgroundColor: "#E1E7EC",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2rem",
          }}
          elevation={3}
          component={"form"}
          onSubmit={(event) => {
            event.preventDefault();
            handleVerifyCode();
          }}
        >
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              fontSize: "24px",
              right: "20px",
              color: globalTextColors.PRIMARY,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <ModalHeading>
              Innan du administrerar Zeeds till patienter, behöver du aktivera
              en tvåfaktorsverifiering. Detta för att öka säkerheten av ditt
              konto.
            </ModalHeading>
            I framtiden får du därför logga in med både en telefonkod och ditt
            lösenord. <Link onClick={handleReadMore}>Läs mer.</Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              width: "50%",
            }}
          >
            <PhoneInput
              enableSearch={false}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              specialLabel=""
              country={"se"}
              inputProps={{
                type: "tel",
                required: true,
              }}
              inputStyle={{
                border: "1px solid #00000040",
                padding: "0.75rem 0.75rem 0.75rem 3rem",
                borderRadius: "2px",
                fontSize: "1rem",
                color: "#42446A",
                height: "48px",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={verificationId.length !== 0}
              onEnterKeyPress={handlePhoneNumber}
            />

            <Button
              sx={{
                borderRadius: "5px",
                height: "48px",
                marginTop: "2px",
                alignSelf: "flex-end",
              }}
              onClick={handlePhoneNumber}
              disabled={verificationId.length !== 0}
            >
              Lägg till ditt telefonnnummer
            </Button>
            <Input
              placeholder="Kod"
              onChange={(event) => setVerificationCode(event.target.value)}
              style={{
                opacity: verificationId.length > 0 ? 1 : 0,
              }}
              disabled={verificationId.length === 0}
            />
            <Button
              sx={{
                borderRadius: "5px",
                height: "48px",
                marginTop: "2px",
                alignSelf: "flex-end",
                opacity: verificationId.length > 0 ? 1 : 0,
              }}
              onClick={handleVerifyCode}
              disabled={verificationId.length === 0}
              type="submit"
            >
              {/* Add user */}
              Verifiera kod
            </Button>
          </div>
        </Paper>
      </Modal>
      <div id="recaptcha-container" />
    </>
  );
};

export default AddPhoneNumberDialog;
