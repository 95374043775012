import CustomModal from "./CustomModal";
import LinkButton from "./LinkButton";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { globalTextColors } from "@constants/colors";

import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfFormsModal({ title, pdfPath }: { title: string; pdfPath: string }) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const toggleModalVisibility = () => setIsModalOpen(!isModalOpen);

  const [numberOfPages, setNumberOfPages] = useState<null | number>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number | null }) =>
    setNumberOfPages(numPages);

  return (
    <>
      <LinkButton onClick={toggleModalVisibility}>
        {title} <PictureAsPdfOutlinedIcon sx={{ marginLeft: "1rem" }} />
      </LinkButton>

      <Modal open={isModalOpen} onClose={toggleModalVisibility}>
        <ModalContent>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: globalTextColors.PRIMARY,
              width: "100%",
              padding: "0.5rem 2rem",
              backgroundColor: "#E1E7EC",
            }}
          >
            <Typography fontSize="15px" fontWeight="500">
              Preview <span style={{ fontWeight: "900" }}>{title}</span>
            </Typography>
            <IconButton onClick={toggleModalVisibility}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Stack>

          <Box
            sx={{
              height: "80%",
              overflow: "scroll",
              width: "80%",
              margin: "0 auto",
              paddingTop: "2rem",
              paddingBottom: "3rem",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              "&:-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Document file={pdfPath} onLoadSuccess={onDocumentLoadSuccess}>
              {numberOfPages
                ? (function () {
                    const elementArray = [];
                    for (let i = 1; i <= numberOfPages; i++) {
                      elementArray.push(
                        <>
                          <Box sx={{ marginTop: "2rem" }} />
                          <Page
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            width={680}
                            pageNumber={i}
                          />
                        </>,
                      );
                    }
                    return elementArray;
                  })()
                : null}
            </Document>
          </Box>

          <ModalFooter>
            <IconButton
              sx={{
                color: globalTextColors.PRIMARY,
                float: "right",
                cursor: "pointer",
              }}
            >
              <Link
                sx={{
                  textDecoration: "none",
                  color: globalTextColors.PRIMARY,
                  display: "flex",
                  alignItems: "center",
                }}
                href={pdfPath}
                download
              >
                <Typography sx={{ marginRight: "0.5rem" }}>
                  Click to download
                </Typography>
                <FileDownloadOutlinedIcon fontSize="medium" />
              </Link>
            </IconButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const Modal = styled(CustomModal)({
  width: "65%",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ModalContent = styled(Box)({
  width: "90%",
  height: "80%",
  borderRadius: "0.5rem",
  backgroundColor: "#F0F3F5",
  overflow: "hidden",
  position: "relative",
});

const ModalFooter = styled(Box)({
  width: "100%",
  padding: "0.5rem 2rem",
  position: "absolute",
  bottom: 0,
  backgroundColor: "#E1E7EC",
});

export default PdfFormsModal;
