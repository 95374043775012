// DeleteAccountModal.tsx
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Typography } from "@mui/material";

interface DeleteAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => (
  <div
    className="modal"
    style={{
      display: isOpen ? "block" : "none",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
    }}
  >
    <div
      className="modal-content"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "20px",
        background: "white",
        borderRadius: "18px",
        height: "40%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <IconButton onClick={onClose} style={{ alignItems: "end" }}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="body1" gutterBottom style={{ margin: "50px 0" }}>
        Är du säker på att du vill ta bort ditt konto? Du kommer då inte längre
        ha tillgång till Zeeds och all data kommer raderas. Om du har pågående
        patienter med Zeeds kommer du inte kunna se deras data, men de kommer
        kunna fortsätta använda appen. Se till att informera patienterna på
        förhand.
      </Typography>
      <div
        style={{
          marginTop: "56px",
          display: "flex",
          width: "50%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          style={{
            marginRight: "5px",
          }}
        >
          Ja
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          style={{
            marginRight: "5px",
          }}
        >
          Nej
        </Button>
      </div>
    </div>
  </div>
);

export default DeleteAccountModal;
