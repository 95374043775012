import TermsAndConditionsExtend from "@components/TermsAndConditionsExtend";
import {
  Appendixes,
  IntendedUse,
  Introduction,
  MinimalSoftwareRequirements,
  PatientProfile,
  PriorToTreatment,
  ReferenceList,
  SessionFive,
  SessionFour,
  SessionOne,
  SessionSix,
  SessionThree,
  SessionTwo,
  ZeedsPatientApplication,
  ZeedsPortal,
} from "@components/UserManualModalSections";
import {
  Appendixes as Appendixes_fact,
  BeforeSession,
  FirstSession,
  TreatmentPrinciples,
} from "@components/UserManualModalSections_fact";

export const menuComponentsBA = {
  introduktion: [
    {
      title: "Introduktion",
      Component: Introduction,
    },
  ],
  kliniskInformation: [
    {
      title: "Avsedd användning",
      Component: IntendedUse,
    },
    {
      title: "Patientprofil",
      Component: PatientProfile,
    },
    {
      title: "Innan behandling",
      Component: PriorToTreatment,
    },
  ],
  tekniskInformation: [
    {
      title: "Mjukvarukrav",
      Component: MinimalSoftwareRequirements,
    },
    {
      title: "Zeeds patientapplikation",
      Component: ZeedsPatientApplication,
    },
    {
      title: "Zeeds Portal",
      Component: ZeedsPortal,
    },
  ],
  administration: [
    {
      title: "Session 1",
      Component: SessionOne,
    },
    {
      title: "Session 2",
      Component: SessionTwo,
    },
    {
      title: "Session 3",
      Component: SessionThree,
    },
    {
      title: "Session 4",
      Component: SessionFour,
    },
    {
      title: "Session 5",
      Component: SessionFive,
    },
    {
      title: "Session 6",
      Component: SessionSix,
    },
  ],
  andra: [
    {
      title: "Referenslista",
      Component: ReferenceList,
    },
    {
      title: "Bilagor",
      Component: Appendixes,
    },
    {
      title: "Allmänna villkor",
      Component: TermsAndConditionsExtend,
    },
  ],
};

export const menuComponentsFact = {
  introduktion: [
    {
      title: "Introduktion",
      Component: Introduction,
    },
  ],
  kliniskInformation: [
    {
      title: "Avsedd användning",
      Component: IntendedUse,
    },
    {
      title: "Patientprofil",
      Component: PatientProfile,
    },
    {
      title: "Behandlingsprinciper",
      Component: TreatmentPrinciples,
    },
  ],
  tekniskInformation: [
    {
      title: "Mjukvarukrav",
      Component: MinimalSoftwareRequirements,
    },
    {
      title: "Zeeds patientapplikation",
      Component: ZeedsPatientApplication,
    },
    {
      title: "Zeeds Portal",
      Component: ZeedsPortal,
    },
  ],
  administration: [
    {
      title: "Den första sessionen",
      Component: FirstSession,
    },
    {
      title: "Uppföljande sessioner",
      Component: BeforeSession,
    },
  ],
  andra: [
    {
      title: "Referenslista",
      Component: ReferenceList,
    },
    {
      title: "Bilagor",
      Component: Appendixes_fact,
    },
    {
      title: "Allmänna villkor",
      Component: TermsAndConditionsExtend,
    },
  ],
};
