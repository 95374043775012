import { FirebaseOptions, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const isDev = process.env.REACT_APP_DEV === "1";

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Firebase init
const app = initializeApp(firebaseConfig);

console.log("Firebase initialized", "isDev:", isDev);
if (isDev) {
  connectAuthEmulator(getAuth(), "http://localhost:9099", {
    disableWarnings: true,
  });
  connectFunctionsEmulator(
    getFunctions(app, "europe-central2"),
    "localhost",
    5001,
  );
}

export default app;
